import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const WIDTH = 910;
const HEIGHT = 100;
const MAP_PLOT_COLOR = '#ffffff'
const LABEL_PLOT_COLOR = '#1B305C'
const ZOOM_OUT_FACTOR = (x) => { return x * 10/100 } // ZOOM_OUT_FACTOR is to zoom out for full runway view - 10% of the view

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const Testd3 = ({ airportcode, AirportUTM, coordinatesArray, runwayNumber, RunwayCorners, midpoint1, midpoint2, parentRef }) => {
    const svgRef = useRef();
    

    useEffect(() => {
        if (coordinatesArray.length === 0 && parentRef) {
            return;
        }

        const responsiveComponent = (svg) => {
            /** Calculations */ 

            // top left corner of the image aka 0,0
            const [pointAlphaX, pointAlphaY] = AirportUTM;
            
            // Get the midpoint of the runway
            const [pointMidRunwayX, pointMidRunwayY] = [((midpoint1[0] + midpoint2[0]) / 2) - pointAlphaX, pointAlphaY - ((midpoint1[1] + midpoint2[1]) / 2)]


            const image = new Image()
            image.src = `/images/${airportcode.toLowerCase()}.jpg`

            image.onload = () => {
                const imgWidth = image.width;  // Set desired SVG width
                const imgHeight = image.height;  // Set desired SVG height
                

                svg.selectAll("*").remove();
                const g = svg.append('g')

                // Set the SVG width and height - height is calculated based on the aspect ratio of the parentRef width
                svg.attr('viewBox', `0 0 ${parentRef.current?.clientWidth} ${parentRef.current?.clientWidth * HEIGHT/WIDTH}`)
                
                // g.attr('transform', `translate(250, 0)`)

                // show the image
                var img = g.selectAll("image").data([0]);
                img.enter()
                    .append("image")
                    .attr('xlink:href', `/images/${airportcode.toLowerCase()}.jpg`)
                    .attr("x", "0")
                    .attr("y", "0")
                    .attr("width", imgWidth)
                    .attr("height", imgHeight)

                // filter turn around data
                const filteredLanding = [];
                const sortedLanding = coordinatesArray.sort((a, b) => { return parseInt(a.idx) - parseInt(b.idx) })

                let tempDistance = -10000;
                sortedLanding.forEach((item, index) => {
                    const dist = parseInt(item.distance)
                    if ( dist >= tempDistance ) {
                        filteredLanding.push(item);
                        tempDistance = dist;
                    }
                }) 

                console.log("coordinatesArray", coordinatesArray, filteredLanding)

                // plot circle plot of the runway
                const circle = g.selectAll("circle").data(filteredLanding);
                circle.enter()
                    .append("circle")
                    .attr("cx", (d) => { return d.utm.coordinates[0] - pointAlphaX })
                    .attr("cy", (d) => { return pointAlphaY - d.utm.coordinates[1]  })
                    .attr("r", 8)
                    .attr("fill", MAP_PLOT_COLOR)
                    .attr("stroke", "black")
                    .attr("stroke-width", 2)

                // frame width and height
                const svgWidth = svg.node().getBoundingClientRect().width;
                const svgHeight = svg.node().getBoundingClientRect().height;
                

                // Snap the map to runway center
                // Calulate the center of the runway along with csv frame center
                const [pointFrameCenterX, pointFrameCenterY] = [pointMidRunwayX - svgWidth/2, pointMidRunwayY - svgHeight/2]

                const [runwayEastSide, runwayWestSide] = [RunwayCorners[1], RunwayCorners[3]]

                // Calculate Aspect Ratio - Euclidean distance formula
                const runwayDistance = Math.sqrt(Math.pow(runwayEastSide[0] - runwayWestSide[0], 2) + Math.pow(runwayEastSide[1] - runwayWestSide[1], 2))
                const aspectRatio = (svgWidth / runwayDistance) - ZOOM_OUT_FACTOR(svgWidth / runwayDistance) // ZOOM_OUT_FACTOR is to zoom out for full runway view

                // Caclulate the rotation angle
                let runwayAngle = Math.atan2(runwayEastSide[1] - runwayWestSide[1], runwayEastSide[0] - runwayWestSide[0]) * 180 / Math.PI
                console.log({runwayAngle})
                runwayAngle = (Math.abs(runwayAngle) > 90 ? 180 : 0)+ runwayAngle + Math.PI/3 // not sure why I need to add PI/3

                g.attr('transform-origin', `${pointMidRunwayX}px ${pointMidRunwayY}px`)
                g.attr('style', `transform: translate(-${pointFrameCenterX}px, -${pointFrameCenterY}px) scale(${aspectRatio}) rotate(${runwayAngle}deg)`)
                

                // Add the runway number on either side of the runway
                const [runwayEastLable, runwayWestLable] = runwayNumber.split('/')

                const lableLayer = svg.append('g')
                
                // Add the runway number on the east side of the runway
                lableLayer.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('fill', LABEL_PLOT_COLOR)
                    .attr('width', 30)
                    .attr('height', svgHeight)
                lableLayer.append('text')
                    .attr('x', 17)
                    .attr('y', svgHeight/2 + 10)
                    .attr('fill', 'white')
                    .attr('font-size', '15px')
                    .attr('font-weight', 'bold')
                    .attr('text-anchor', 'middle')
                    .attr('height', svgHeight)
                    .text(runwayEastLable)

                // Add the runway number on the west side of the runway
                lableLayer.append('rect')
                    .attr('x', svgWidth - 30)
                    .attr('y', 0)
                    .attr('fill', LABEL_PLOT_COLOR)
                    .attr('width', 30)
                    .attr('height', svgHeight)
                lableLayer.append('text')
                    .attr('x', svgWidth - 17)
                    .attr('y', svgHeight/2 + 10)
                    .attr('fill', 'white')
                    .attr('font-size', '15px')
                    .attr('font-weight', 'bold')
                    .attr('text-anchor', 'middle')
                    .attr('height', svgHeight)
                    .text(runwayWestLable)
            }
        }

        // Render the chart
        const RenderChart = () => {
            const svg = d3.select(svgRef.current)

            responsiveComponent(svg);
        }

        // Debounce the resize event
        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [airportcode, AirportUTM, coordinatesArray, RunwayCorners, midpoint1, midpoint2, runwayNumber, parentRef]);

    return <svg ref={svgRef}></svg>;
};

export default Testd3;