import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import jwt from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import { setRoot } from "../redux/rootSlice";
import { setAuthenticated } from "../redux/authenticatedSlice";
import { setAuthorityId } from "../redux/authoritySlice";
import { setFirstName } from "../redux/firstNameSlice";
import { setAirportCode } from "../redux/airportCodeSlice";
import { setRole } from "../redux/roleSlice";

const TEAM_EAGLE = process.env.REACT_APP_TEAM_EAGLE_AUTHORITY_NAME;


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function AppBar2() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const landingsCount = useSelector((state) => state.landingsCount.value)
  const firstName = useSelector((state) => state.firstName.value)
  // const root = useSelector((state) => state.root.value)
  // const authenticated = useSelector((state) => state.authenticated.value)
  // const role = useSelector((state) => state.role.value)

  useEffect(() => {
    // console.log('Processing Token')

    const token = localStorage.getItem('token')
    if (token != null) {
      const decodedToken = jwt(token)
      // console.log(decodedToken)

      dispatch(setRoot(decodedToken.authorityName === TEAM_EAGLE))
      const role = decodedToken.airportRoles.length > 0 ? decodedToken.airportRoles[0].role : ''
      dispatch(setRole(role))
      dispatch(setAuthenticated(true))
      dispatch(setAuthorityId(decodedToken.authorityId))
      dispatch(setFirstName(decodedToken.fname))
      const airportCode = decodedToken.airportRoles.length === 1 && role !== 'none' ? decodedToken.airportRoles[0].airport.code : ''
      dispatch(setAirportCode(airportCode))

    }
  });


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };





  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} disabled={true}>Logged in as {firstName}</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
        <Badge badgeContent={landingsCount} color="secondary">
            <FlightLandIcon />
          </Badge>
        </IconButton>
        <p>Landings</p>
      </MenuItem>

      <MenuItem>
        <IconButton color="inherit">
            <SearchIcon />
        </IconButton>
        <p>Search</p>
      </MenuItem>
      
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
          
      <Toolbar variant="dense">
          <Tooltip title="Aircraft Deceleration Early Warning"> 
            <Typography className={classes.title} variant="h6" noWrap>
                ADEW
            </Typography>
          </Tooltip>

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            <MenuItem onClick={() => history.push('/landings')}>
                <IconButton aria-label="show landings" color="inherit">
                <Badge badgeContent={landingsCount} color="secondary">
                    <FlightLandIcon />
                </Badge>
                </IconButton>
                <p>Landings</p>
            </MenuItem>

            <MenuItem onClick={() => history.push('/search')} >
                <IconButton color="inherit">
                    <SearchIcon />
                </IconButton>
                <p>Search</p>
            </MenuItem>

            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton 
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                >
                <AccountCircle />
                </IconButton>
                <p>User</p>
            </MenuItem>

            <MenuItem>
                <IconButton color="inherit">
                    <SettingsIcon />
                </IconButton>
                <p>Admin</p>
            </MenuItem>

          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}