import React, { useState, useEffect } from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as axios from 'axios'
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from '@material-ui/core/FormHelperText';

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));

const validationSchema = yup.object({
  pingstationid: yup
  .string('Enter the Ping Station Id')
  .required('PingStation Id is required'),
  airportid: yup
    .string('Select Airport')
    .required('Airport is required'),
});



export default function PingStation() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams()
  const [pingStationId, setPingStationId] = useState('')
  const [airportId, setAirportId] = useState('')
  const [airports, setAirports] = useState([]);
  // const [airportCode, setAirportCode] = useState('')


  useEffect(() => {
    axios.get(`${WEBAPI}/airport`)
    .then((res) => {
      // console.log(res.data)
      setAirports(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);


  useEffect(() => {
    console.log('id', id)

    if (id !== undefined) {
      axios.get(`${WEBAPI}/pingstation/${id}`)
      .then((res) => {
        console.log(res.data)
          setPingStationId(res.data.pingstationid)
          setAirportId(res.data.airport.id)
          // setCode(res.data.code);
          // setName(res.data.airportname);
          // setElevation(res.data.elevation);
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }, [id]);


  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        pingstationid: pingStationId,
        airportid: airportId,
      },
      validationSchema: validationSchema,
      onSubmit: (values, {setSubmitting, resetForm} ) => {
        console.log('Submitting Form', values)

        if (id === undefined) {
          axios.post(`${WEBAPI}/pingstation`, { pingstationid: values.pingstationid,airportid: values.airportid })
              .then((res) => {
                setSubmitting(false)
                history.push('/pingstations')
                })
              .catch((err) => {
                console.log(err);
                setSubmitting(false)
              })
        } else {
          axios.put(`${WEBAPI}/pingstation`, { id: id, pingstationid: values.pingstationid,airportid: values.airportid })
              .then((res) => {
                setSubmitting(false)
                history.push('/pingstations')
                })
              .catch((err) => {
                  console.log(err);
                  setSubmitting(false)
              })
        }
      },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={ formik.handleSubmit }>
        <Grid container direction="column" alignItems="stretch" className={classes.grid} spacing={1}> 
          <Grid item xs={12}>
            <Typography component="h1" align="left" variant="h5" noWrap>
              PingStation
            </Typography>
          </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.pingstationid}
                onChange={formik.handleChange}
                error={formik.touched.pingstationid && Boolean(formik.errors.pingstationid)}
                helperText={formik.touched.pingstationid && formik.errors.pingstationid}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="pingstationid"
                label="PingStationId"
                name="pingstationid"
                autoComplete=""
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
                size={"small"}
                required
                error={formik.touched.airportid && Boolean(formik.errors.airportid)}
                >
                <InputLabel>Airport</InputLabel>
                <Select
                  value={formik.values.airportid}
                  onChange={formik.handleChange}
                  id="airportid"
                  label="Airport"
                  name="airportid"
                >
                  {airports?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.code} - {item.airportname}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{display: (formik.touched.airportid && Boolean(formik.errors.airportid) ? 'block' : 'none' ) }} >Airport is required</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.submit}
              >
                Save
              </Button>
            </Grid>


        </Grid>
        </form>
      </Paper>
    </div>

  )
}