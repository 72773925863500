import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
// import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
// import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
// import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from 'react-router-dom';
import * as axios from 'axios'
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux'
import { setLandingDate } from '../redux/landingDateSlice'
import { setAirportCode } from '../redux/airportCodeSlice'
import { setLandingsCount } from '../redux/landingsCountSlice'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import Weather from './weather';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import io from 'socket.io-client'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import LandingsList from './LandingsList';
import CircularProgress from '@material-ui/core/CircularProgress';


const WEBAPI = process.env.REACT_APP_WEB_API
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'createdTime', numeric: false, disablePadding: false, label: 'Time' },
  { id: 'landingrunway', numeric: false, disablePadding: false, label: 'Runway' },
  { id: 'icaoaddress', numeric: false, disablePadding: false, label: 'ICAO' },
  { id: 'callsign', numeric: false, disablePadding: false, label: 'CallSign' },
  { id: 'class', numeric: false, disablePadding: false, label: 'Class' },
  { id: 'map', numeric: false, disablePadding: false, label: 'Map' },
  // { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
  // { id: 'rawdata', numeric: false, disablePadding: false, label: 'Data (RAW)' },
  { id: 'analysis', numeric: false, disablePadding: false, label: 'Analysis' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Alert Status' },
];

const headCellsAdmin = [
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'createdTime', numeric: false, disablePadding: false, label: 'Time' },
  { id: 'landingrunway', numeric: false, disablePadding: false, label: 'Runway' },
  { id: 'icaoaddress', numeric: false, disablePadding: false, label: 'ICAO' },
  { id: 'callsign', numeric: false, disablePadding: false, label: 'CallSign' },
  { id: 'class', numeric: false, disablePadding: false, label: 'Class' },
  { id: 'map', numeric: false, disablePadding: false, label: 'Map' },
  { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
  { id: 'rawdata', numeric: false, disablePadding: false, label: 'Data (RAW)' },
  { id: 'analysis', numeric: false, disablePadding: false, label: 'Analysis' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Alert Status' },
];


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, root } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox className={classes.headerCheckbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all airports' }}
          />
        </TableCell> */}
        {
          root ? 
          headCellsAdmin.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
            :
        headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, id, handleDelete } = props;
  const history = useHistory();

  // const handleDelete = (id) => {
  //   console.log('handleDelete', id);
  // }

  // const handleMapClick = (id) => {
  //   //console.log('handleEdit', id);
  //   history.push(`/landing/${id}`)
  // }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Landings
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <>
          <IconButton 
            title="Map"
            className={classes.iconButton}
            onClick={() => handleMapClick(id)}>
            <MapOutlinedIcon />
          </IconButton>

      </>
    ) : null} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.info.main
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  weather: {
    padding: theme.spacing(1),
    marginLeft: 'auto',
  },

}));

const socket = io(SOCKET_URL, { autoConnect: false } )

export default function Landings() {
  const classes = useStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [airports, setAirports] = useState([]);
  const airportCode = useSelector((state) => state.airportCode.value)
  const landingDate = useSelector((state) => state.landingDate.value)
  const authorityId = useSelector((state) => state.authorityId.value)
  const firstName = useSelector((state) => state.firstName.value)
  const root = useSelector((state) => state.root.value)
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme();
  const small_screens = useMediaQuery(theme.breakpoints.down('xs'));
  const landingDateRef = useRef()
  const landingsDataRef = useRef()
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    socket.emit('leave', airportCode)
    socket.disconnect()
    // console.log(airportCode, landingDate)
    // console.log(new Date(landingDate), new Date())
    const diff = differenceInCalendarDays(new Date(landingDate), new Date())
    // console.log('date diff', diff)

    if (airportCode != '' && diff === 0) {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('landing_done');
      
      console.log('Connecting to Socket...')
      socket.connect()

      socket.on('connect', () => {
        console.log('Socket Connected, sending request to join room', airportCode);
        socket.emit('join', airportCode)
      })
  
      socket.on('disconnect', () => {
        console.log('Socket Disconnected');
      })
  
      socket.on('connect_error', (error) => {
        console.log('Socket Error', error);
      })

      socket.on('landing', (landing) => {
        const diff = differenceInCalendarDays(new Date(landing.created), new Date(landingDateRef.current))
        // console.log('date diff', diff)

        if (diff === 0) {
          console.log('Refreshing data...')
          // getLandings()

          const data = landingsDataRef.current
          data.push(landing)
          setRows([])
          setRows(data)

          dispatch(setLandingsCount(data.length))

        } else {
          console.log('NOT Refreshing data.')
        }
      })
    } else {
      if (socket) {
        console.log('Closing the socket')
        socket.disconnect()
      } 
    }

    return () => {
      if (socket) {
        socket.disconnect()
      } 
    }
  }, [airportCode, landingDate]);

  const getLandings = () => {
    if (airportCode !== '') {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      
      axios.get(`${WEBAPI}/landing?airport=${airportCode}&date=${landingDate}&timezone=${timezone}`)
      .then((res) => {
        // console.log('Data: ', res.data)
        if (res.data.length === 0) {
          setRows([])
        } else {
          console.table(res.data)
          setRows(res.data)
        }
        setLoading(false)

        dispatch(setLandingsCount(res.data.length))

        // console.log('fetched landings', res.data, rows)
        landingsDataRef.current = res.data
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  useEffect(() => {
    // const channel = new BroadcastChannel('landing');
    // channel.addEventListener('message', event => {
    //   getLandings()
    // });
  
    axios
    .get(`${WEBAPI}/airport`)
    .then((res) => {
      setAirports(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }, [authorityId, small_screens]);

  useEffect(() => {
    setLoading(true)
    landingDateRef.current = landingDate
    getLandings()
  }, [airportCode, landingDate]);

  const handleDelete = (id) => {
    const payload = {data: {id: id}}
    axios.delete(`${WEBAPI}/runway`, payload)
    .then((res) => {
        // setRows(res.data)
        console.log('delete successful', res.status);
        const newRows = rows.filter((item) => {
          return item.id !== id
        }) 

        setSelected([])
        setRows(newRows)
    })
    .catch((err) => {
        // console.log(err);
    })

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleMapClick = (id) => {
    history.push(`/landing/${id}`)
  }

  const handleDataClick = (id) => {
    history.push(`/landingdata/${id}`)
  }

  const handleDataRawClick = (id) => {
    history.push(`/landingdataraw/${id}`)
  }

  const handleAnalysisClick = (id) => {
    history.push(`/analysis/${id}`)
  }

  const handleAlertClick = (id) => {
    history.push(`/landingalerts/${id}`)
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(id)
    }

    setSelected(newSelected);
  };

  // const handleLandingDateChange = (date) => {
  //   console.log( date.toDateString() )
  //   dispatch(setLandingDate( date.toDateString() ))
  // };

  // const handleChangePage = (event, newPage) => {
  //   // setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
          <Box display='flex' flexWrap='wrap' alignItems='flex-end'>
            <FormControl className={classes.formControl} size={"small"} style={{ width: 150 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="date-picker-inline"
                  label="Date"
                  value={landingDate}
                  autoOk={true}
                  onChange={(date) => dispatch(setLandingDate( date.toDateString())) }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl} size={"small"}>
              <InputLabel>Airport</InputLabel>
              <Select
                value={airportCode}
                onChange={(e) => dispatch(setAirportCode(e.target.value))}
                id="airport"
                label="Airport"
                name="airport"
              >
                {airports?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.code}>
                      {small_screens ?
                        item.code
                      :  
                        item.airportname
                      }
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Weather code={airportCode} className={classes.weather}  />
          </Box>
      </Paper>
      {
        loading ?
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress color="secondary" />
        </Box>
        :
        <LandingsList data={rows} pageName='landings'></LandingsList>
      }
    </div>
  );
}
