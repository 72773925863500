import { createSlice } from '@reduxjs/toolkit'

export const authorityIdSlice = createSlice({
  name: 'authorityId',
  initialState: {
    value: '',
  },
  reducers: {
    setAuthorityId: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setAuthorityId } = authorityIdSlice.actions
export default authorityIdSlice.reducer
