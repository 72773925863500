import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from '../src/redux/store'
import { Provider } from 'react-redux'
import swConfig from './swConfig';


ReactDOM.render(
  // I had to remove StrictMode due to errors in the browser.
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const isMobile = () => {
  const device = navigator.userAgent
  console.log('device', device)

  if (device.includes("Android") || 
      device.includes("BlackBerry") || 
      device.includes("iPhone") || 
      device.includes("iPad") || 
      device.includes("iPod") || 
      device.includes("Opera Mini") || 
      device.includes("IEMobile") || 
      device.includes("WPDesktop")
    ) {
    return true
  }

  return false
}

if (isMobile()) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register(swConfig);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


