import { Box, CircularProgress,  Grid, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


import DashboardNotam from "./dashboard/statistics/DashboardNotam";
import DashboardWeather from "./dashboard/statistics/DashboardWeather";
import LandingSummary from "./dashboard/statistics/LandingSummary";
import RunwayTiming from "./dashboard/statistics/RunwayTiming";
import RunwaySelect from "./dashboard/RunwaySelect";
import DecelerationTrend from "./dashboard/trends/DecelerationTrend";
import DirectionalCompliance from "./dashboard/trends/DirectionCompliance";
import DashboardLanding from "./dashboard/statistics/DashboardLanding";
import { io } from "socket.io-client";
import DashboardInfoBar from "./dashboard/DashboardInfoBar";

// placeholders
var colors  =   ['#5275BF','#440381', '#7AA095', '#7F0B00'];

const WEBAPI = process.env.REACT_APP_WEB_API;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const PUBLIC_URL = process.env.PUBLIC_URL;

// theme styles
const useStyles =    makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
        padding: window.innerWidth < 600 ? 0: theme.spacing(2)
    },
    grid: {
        marginTop: theme.spacing(3)
    },
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    recentLanding: {
        width: '100%'
    }
}));

const socket = io(SOCKET_URL, {autoConnect: false});

export default function Dashboard() {
    const classes       =   useStyles();
    const airportCode   =   useSelector((state) => state.airportCode.value);
    const [airport, setAirport]         =   useState(false)
    const [recentLanding, setLanding]   =   useState(false)
    const [runways, setRunways]         =   useState(false) // All runways
    const [runway, setRunway]           =   useState(false) // Current runway view
    const [averages, setAverages]       =   useState(false)
    const [deceleration, setDeceleration]       =   useState(false)
    const [weather, setWeather]         =   useState(false)
    const [vaisala, setVaisala]         =   useState(false)
    const [notam, setNotam]             =   useState(false)
    const [loading, setLoading]         =   useState(true)

    function setupSocket() {
        socket.connect();
        socket.on('connect', () => {
            console.log('Connected to socket.io room', airportCode)
            socket.emit('join', 'dashboard-'+airportCode)
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from socket.io room', airportCode)
        });

        socket.on('connect_error', (err) => {
            console.log('Connection error', err)
        });

        socket.on('landing_done', (data) => {
            setLanding(data.landings)
            setAirport(data.airport)    
            setWeather(data.weather)
            setNotam(data.notam)
            setRunways(data.runways)
            setDeceleration(data.deceleration)
            setAverages(data.averages)
            if ( 'vaisala' in data ) {
                setVaisala(data.vaisala)
            }
        })
    }

    useEffect(() => {
        if ( airportCode !== '' && typeof airportCode !== 'undefined' ) {
            // Disconnect from socket.io room
            socket.disconnect();
            socket.emit('leave', 'dashboard-'+airportCode)
            
            axios.get(`${WEBAPI}/dashboard/`, {
                params: {
                    code: airportCode
                }
            }).then((res)=> {
                if ( res.status === 200 ) {
                    setLanding(res.data.landings)
                    setAirport(res.data.airport)    
                    setWeather(res.data.weather)
                    if ( 'vaisala' in res.data ) {
                        setVaisala(res.data.vaisala)
                    }
                    setNotam(res.data.notam)
                    setRunways(res.data.runways)
                    setDeceleration(res.data.deceleration)
                    setAverages(res.data.averages)
                    if ( res.data.runways.length > 0 ) {
                        setRunway(0)
                    }
                    setLoading(false)    
                    
                    setupSocket();
                }
            })
        }
    }, [airportCode]);

    if ( loading ) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress color="secondary" />
        </Box>;
    }

    const currentRunwayName = runways[runway]['runwayname'];

    return <div className={classes.root}>
        <div className={classes.paper}>
            <Typography variant="h4">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        Dashboard |  <Typography variant="h5" display="inline">{airport.airportname}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <RunwaySelect runways={runways} setRunway={setRunway} runway={runway} colors={colors}/>
                    </Grid>
                </Grid>
            </Typography>

            <DashboardInfoBar runway={currentRunwayName} weather={weather} />

            <Grid container className={classes.grid} spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h5">
                        Statistics
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {/* Notam Widget */}
                            <DashboardNotam notam={notam} runways={runways} runway={runway} colors={colors} />

                            {/* Adew Summary Widget */}
                            <LandingSummary recentLanding={recentLanding} runway={runway} />

                            {/* Flight Radar Link */}
                            <Box textAlign="center" mt={3}>
                                <Link href={`http://vrs.team-eagle.ca:8081/VirtualRadar/desktop.html`} target="_blank" rel="noopener">
                                    <img src={PUBLIC_URL+"/icons/virtual-radar.png"} alt="Flight Radar" />
                                    <br />
                                    <Typography variant="body2" display="inline">Virtual Radar</Typography>
                                </Link>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {/* Recent Landings Widget */}
                            <DashboardLanding recentLanding={recentLanding} runway={runway} />

                            {/* Current Weather Widget */}
                            <DashboardWeather weather={weather} code={airportCode} setWeather={setWeather} vaisala={vaisala} />

                            {/* Time on runway Widget */}
                            <RunwayTiming recentLanding={recentLanding} runway={runway} averages={averages} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h5">
                        Trends
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <DecelerationTrend deceleration={deceleration} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <DirectionalCompliance recentLanding={recentLanding} runway={runway}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>;
}