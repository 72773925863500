import React, { useState, useEffect } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
// import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import { useSelector, useDispatch } from "react-redux";
// import { setAirportCode } from "../redux/airportCodeSlice";
import Paper from "@material-ui/core/Paper";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  activetext: {
    margin: theme.spacing(1),
  }
}));

const validationSchema = yup.object({
  period: yup.string("Enter Hours after Precipitation").required("Hours after Precipitation"),
  windspeed: yup.string("Enter Wind Speed").required("Wind Speed is required"),
  updateAlertHistory: yup.boolean("Update Alert History").required("Update Alert History is required"),
});

export default function AirportAlert() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [airportId, setAirportId] = useState('')
  const [period, setPeriod] = useState('')
  const [windspeed, setWindSpeed] = useState('')
  const [updateAlertHistory, setUpdateAlertHistory] = useState(false)

  useEffect(() => {
    if (id) {
      setAirportId(id)

      axios.get(`${WEBAPI}/airport/${id}`)
      .then((res) => {
        console.log(res.data)
        setPeriod(res.data.alert_precip_period ?? '')
        setWindSpeed(res.data.alert_windspeed ?? '')
        setUpdateAlertHistory(res.data.update_alert_history ?? false)

      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [id]);


  const formik = useFormik({
    // validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      period: period,
      windspeed: windspeed,
      updateAlertHistory: updateAlertHistory,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values);

      axios
        .put(`${WEBAPI}/airport/alerts`, {
          id: airportId,
          period: values.period,
          windspeed: values.windspeed,
          updateAlertHistory: values.updateAlertHistory,
        })
        .then((res) => {
          history.push("/airportalerts");
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
        });
     
    },
  });


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>

          <Grid container spacing={2}>

              <Grid container item xs={12} md={4}>
                <Typography component="h1" variant="h5">Aiport Alert Conditions</Typography>

                <FormControl 
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  required
                  error={formik.touched.period && Boolean(formik.errors.period)}
                  >
                  <InputLabel htmlFor="period">Hours after Precipitation</InputLabel>
                  <OutlinedInput
                    id="period"
                    name="period"
                    value={formik.values.period}
                    onChange={formik.handleChange}
                    endAdornment={<InputAdornment position="end">Hours</InputAdornment>}
                    labelWidth={190}
                  />
                  <FormHelperText style={{display: (formik.touched.period && Boolean(formik.errors.period) ? 'block' : 'none' ) }} >Hours after Precipitation is required</FormHelperText>
                </FormControl>

                <FormControl 
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  required
                  error={formik.touched.windspeed && Boolean(formik.errors.windspeed)}
                  >
                  <InputLabel htmlFor="windspeed">Wind Speed</InputLabel>
                  <OutlinedInput
                    id="windspeed"
                    // label="Amount"
                    name="windspeed"
                    value={formik.values.windspeed}
                    onChange={formik.handleChange}
                    endAdornment={<InputAdornment position="end">Knots</InputAdornment>}
                    labelWidth={100}
                  />
                  <FormHelperText style={{display: (formik.touched.windspeed && Boolean(formik.errors.windspeed) ? 'block' : 'none' ) }} >Wind Speed is required</FormHelperText>
                </FormControl>

                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={formik.values.updateAlertHistory}
                      onChange={formik.handleChange}
                      name="updateAlertHistory"
                      color="primary"
                    />
                  }
                  label="Update Alert History"
                />

              </Grid>

              <Grid container item xs={12} md={8} spacing={1}>
              </Grid>

              <Grid container item xs={12} md={4}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                    className={classes.submit}
                  >
                    Save
                  </Button>
              </Grid>
          </Grid>

        </form>
      </Paper>
    </div>
  );
}
