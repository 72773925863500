import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max } from 'd3';
import AnalysisExport from '../components/AnalysisExport';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const DecelerationVsTime = (props) => {
    const [decelerationTimeData, setDecelerationTimeData] = useState(props.data)
    const [touchdownTime, setTouchdownTime] = useState(props.touchdownTime)

    const svgRef = useRef()

    useEffect(() => {
        const totalElapsedTime = decelerationTimeData[decelerationTimeData.length-1].et

        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;
            // console.log('responsiveComponent', width, height)

            // let svg = selection.selectAll('svg').data([null]);
            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });

            const dryzone = g.selectAll('.dryzone').data([null]);
            dryzone.enter()
                .append('rect')
                .merge(dryzone)
                .attr('class', 'dryzone')
                .attr('y', 0)
                .attr('width', innerWidth)
                .attr('height', innerHeight / 10 * 6)
                .attr('fill', '#458140')

            const goodzone = g.selectAll('.goodzone').data([null]);
            goodzone.enter().append('rect')
                .merge(goodzone)
                .attr('class', 'goodzone')
                .attr('y', innerHeight / 10 * 6)
                .attr('width', innerWidth)
                .attr('height', innerHeight / 10 * 2)
                .attr('fill', '#80C373');
                
            const mediumzone = g.selectAll('.mediumzone').data([null]);
            mediumzone.enter().append('rect')
                .merge(mediumzone)
                .attr('class', 'mediumzone')
                .attr('y', innerHeight / 10 * 8)
                .attr('width', innerWidth)
                .attr('height', innerHeight / 20 * 1)
                .attr('fill', '#FFFD3E');

            const poorzone = g.selectAll('.poorzone').data([null]);
            poorzone.enter().append('rect')
                .merge(poorzone)
                .attr('class', 'poorzone')
                .attr('y', innerHeight / 20 * 17)
                .attr('width', innerWidth)
                .attr('height', innerHeight / 20 * 2)
                .attr('fill', '#EC8A4E');

            const nilzone = g.selectAll('.nilzone').data([null]);
            nilzone.enter().append('rect')
                .merge(nilzone)
                .attr('class', 'nilzone')
                .attr('y', innerHeight / 20 * 19)
                .attr('width', innerWidth)
                .attr('height', innerHeight / 20 * 1)
                .attr('fill', '#FC1F27');

            const xScale = scaleLinear()
                .domain([0, totalElapsedTime])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = 0 //min(decelerationTimeData.map((item) => item.deceleration))
            const yMax = 1 //max(decelerationTimeData.map((item) => item.deceleration))

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));


            // gridlines in x axis function
            function make_x_gridlines() {		
                return axisBottom(xScale)
                    .ticks()
            }

            // gridlines in y axis function
            function make_y_gridlines() {		
                return axisLeft(yScale)
                    .ticks()
            }

            // add the X gridlines
            g.selectAll('.xgrid').remove()
            g.append("g")
            .attr("class", "xgrid")
            .attr("transform", "translate(0," + innerHeight + ")")
            .call(make_x_gridlines()
                .tickSize(-innerHeight)
                .tickFormat("")
            )

            // add the Y gridlines
            g.selectAll('.ygrid').remove()
            g.append("g")			
            .attr("class", "ygrid")
            .call(make_y_gridlines()
                .tickSize(-innerWidth)
                .tickFormat("")
            )            
       


            const mainTitle = g.selectAll('.main-title').data([null])
            mainTitle
                .enter().append("text")
                .attr('class', 'main-title')
            .merge(mainTitle)
                .attr('fill', chartTheme.titleFill)
                .attr("x", (innerWidth / 2))             
                .attr("y", margin.top + 25)
                .attr("text-anchor", "middle")  
                .style("font-size", "26px") 
                .text("Deceleration vs. Time");

            // const dryZoneTitle = g.selectAll('.dryzone-title').data([null])
            // dryZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'dryzone-title')
            // .merge(dryZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 140)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "20px") 
            //     .text("DRY");

            // const goodZoneTitle = g.selectAll('.goodzone-title').data([null])
            // goodZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'goodzone-title')
            // .merge(goodZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 350)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "20px") 
            //     .text("GOOD");

            // const mediumZoneTitle = g.selectAll('.mediumzone-title').data([null])
            // mediumZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'mediumzone-title')
            // .merge(mediumZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 427)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "20px") 
            //     .text("MEDIUM");
    
            // const poorZoneTitle = g.selectAll('.poorzone-title').data([null])
            // poorZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'poorzone-title')
            // .merge(poorZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 465)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "20px") 
            //     .text("POOR");
                    
            // const nilZoneTitle = g.selectAll('.nilzone-title').data([null])
            // nilZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'nilzone-title')
            // .merge(nilZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 504)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "20px") 
            //     .text("NIL");
    



            // Touchdown Line
            const touchdownLine = g.selectAll('.touchdown-line').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-line')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdownTime))
                .attr("y1", 0)
                .attr("x2", xScale(touchdownTime))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#FFFFFF')
                .attr("x", xScale(touchdownTime) + 10)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");






            // plot the line
            const lineGenerator = line()
                .x((value, index) => xScale(value.et))
                .y((value, index) => yScale(value.deceleration))
                .curve(curveBasis)

            g.selectAll('.line')
                .data([decelerationTimeData])
                .join('path')
                .attr('class', 'line')
                .attr('d', value => lineGenerator(value))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '1')

            // draw the points
            g.selectAll('.dots')
                .data(decelerationTimeData)
                .join("circle")
                .attr('class', 'dots')
                .attr('stroke','black')
                .attr('r','2')
                .attr('fill','black')
                .attr('cx', (d) => xScale(d.et))
                .attr('cy', (d) => yScale(d.deceleration))
                .append("svg:title")
                .text(function(d, i) { return `(${d.et}, ${d.deceleration})` });

        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: window.innerWidth-20,
                height: 600,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Elapsed Time (s)',
                yAxisLabel: 'Deceleration (g)'
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    return ( 
        <>
            {props.root && <AnalysisExport name={"Deceleration Vs. Time"} data={decelerationTimeData} />}
            <svg ref={svgRef}></svg>
        </>
    );
}

export default DecelerationVsTime;
