import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import { Link } from 'react-router-dom';
import LandingAlertIcons from './LandingAlertIcons';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid } from '@material-ui/core';
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import { parseWithOptions } from 'date-fns/fp';
import { VisibilityOff } from '@material-ui/icons';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'createdDate', numeric: false, isChecked: true, disablePadding: false, label: 'Date', showOnReport: true, evaluate: (row) => {return (new Date(row.created)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })} },
  { id: 'created', numeric: false, isChecked: true, disablePadding: false, label: 'Time', showOnReport: true, evaluate: (row) => {return (new Date(row.created)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })} },
  { id: 'landingrunway', numeric: false, isChecked: true, disablePadding: false, label: 'Runway', showOnReport: true, evaluate: true },
  { id: 'icaoaddress', numeric: false, isChecked: true, disablePadding: false, label: 'ICAO', showOnReport: true, evaluate: true },
  { id: 'callsign', numeric: false, isChecked: true, disablePadding: false, label: 'CallSign', showOnReport: true, evaluate: true },
  { id: 'emitter_type', numeric: false, isChecked: true, disablePadding: false, label: 'Class', showOnReport: true, evaluate: true },
  { id: 'runway_time', numeric: false, isChecked: true, disablePadding: false, label: 'Time On Runway', showOnReport: true, evaluate: row => row['landinganalysis'] !== null  ? Math.round(row.landinganalysis.timeonrunway) +"s": "-" },
  { id: 'map', numeric: false, isChecked: true, disablePadding: false, label: 'Map', showOnReport: false, evaluate: true },
  { id: 'analysis', numeric: false, isChecked: true, disablePadding: false, label: 'Analysis', showOnReport: false, evaluate: true },
  { id: 'status', numeric: false, isChecked: true, disablePadding: false, label: 'Alert Status', showOnReport: true, evaluate: (row) => {return row.landingalerts.length === 0 ? "": row.landingalerts.map((al) => al.type).join("| ")} },
];

const headCellsLowAdmin = [
  { id: 'hidden', numeric: false, disablePadding: false, label: '' },
  { id: 'createdDate', numeric: false, isChecked: true, disablePadding: false, label: 'Date', showOnReport: true, evaluate: (row) => {return (new Date(row.created)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })} },
  { id: 'created', numeric: false, isChecked: true, disablePadding: false, label: 'Time', showOnReport: true, evaluate: (row) => {return (new Date(row.created)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })} },
  { id: 'landingrunway', numeric: false, isChecked: true, disablePadding: false, label: 'Runway', showOnReport: true, evaluate: true },
  { id: 'icaoaddress', numeric: false, isChecked: true, disablePadding: false, label: 'ICAO', showOnReport: true, evaluate: true },
  { id: 'callsign', numeric: false, isChecked: true, disablePadding: false, label: 'CallSign', showOnReport: true, evaluate: true },
  { id: 'emitter_type', numeric: false, isChecked: true, disablePadding: false, label: 'Class', showOnReport: true, evaluate: true },
  { id: 'runway_time', numeric: false, isChecked: true, disablePadding: false, label: 'Time On Runway', showOnReport: true, evaluate: row => row['landinganalysis'] !== null  ? Math.round(row.landinganalysis.timeonrunway) +"s": "-" },
  { id: 'map', numeric: false, isChecked: true, disablePadding: false, label: 'Map', showOnReport: false, evaluate: true },
  { id: 'analysis', numeric: false, isChecked: true, disablePadding: false, label: 'Analysis', showOnReport: false, evaluate: true },
  { id: 'status', numeric: false, isChecked: true, disablePadding: false, label: 'Alert Status', showOnReport: true, evaluate: (row) => {return row.landingalerts.length === 0 ? "": row.landingalerts.map((al) => al.type).join("| ")} },
];


const headCellsAdmin = [
  { id: 'hidden', numeric: false, disablePadding: false, label: '' },
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'created', numeric: false, disablePadding: false, label: 'Time' },
  { id: 'landingrunway', numeric: false, disablePadding: false, label: 'Runway' },
  { id: 'icaoaddress', numeric: false, disablePadding: false, label: 'ICAO' },
  { id: 'callsign', numeric: false, disablePadding: false, label: 'CallSign' },
  { id: 'emitter_type', numeric: false, disablePadding: false, label: 'Class' },
  { id: 'runway_time', numeric: false, disablePadding: false, label: 'Time On Runway' },
  { id: 'map', numeric: false, disablePadding: false, label: 'Map' },
  { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
  { id: 'rawdata', numeric: false, disablePadding: false, label: 'Data (RAW)' },
  { id: 'analysis', numeric: false, disablePadding: false, label: 'Analysis' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Alert Status' },
];




function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, root, role } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {
          root ? 
          headCellsAdmin.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
            : 
        (role === "admin" ? headCellsLowAdmin : headCells).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};




const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, pageName, data, setFieldsOpen, setExportMode } = props;
  const role = useSelector((state) => state.role.value);
  // const history = useHistory();

  const handleExportCSV = () => {
    // downloadAsCSV(headCells, data);
    setFieldsOpen(true);
    setExportMode('CSV');
  }

  const handleExportPDF = () => {
    // downloadAsPDF(headCells, data);
    setFieldsOpen(true);
    setExportMode('PDF');
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Landings
            </Typography>
          </Grid>

          <Grid item xs={6}>
            {pageName === "search" && data.length > 0 && role !== "operator" ? 
              <Grid container justifyContent='flex-end' spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppOutlined />}
                    onClick={handleExportCSV}
                  >
                    Export CSV
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppOutlined />}
                    onClick={handleExportPDF}
                  >
                    Export PDF
                  </Button>
                </Grid>
              </Grid>
            : null}
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  weather: {
    margin: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },

}));



export default function LandingsList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [pageName, setPageName] = React.useState(props.pageName);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState(headCells);
  const [fieldsOpen, setFieldsOpen] = React.useState(false);
  const [exportMode, setExportMode] = React.useState('CSV');
  const root = useSelector((state) => state.root.value)
  const role = useSelector((state) => state.role.value)
  const history = useHistory()


  useEffect(() => {
    console.log(props)

    const rowsPerPage = localStorage.getItem(`${pageName}_rowsPerPage`);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage));
    }

    setRows(props.data)
  }, [props])

  const handleRequestSort = (event, property) => {
    console.log('property to sort by', property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (pageName === "search" && event.target.value === -1 && props.data.length > 1000) {
      setOpen(true);
      return;
    }
    localStorage.setItem(`${pageName}_rowsPerPage`, event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setAllRowsPerPage = () => {
    localStorage.setItem(`${pageName}_rowsPerPage`, -1);
    setRowsPerPage(-1);
    setPage(0);
    setOpen(false);
  }

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar pageName={props.pageName} data={rows} setFieldsOpen={setFieldsOpen} setExportMode={setExportMode}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              root={root}
              role={role}
            />
            <TableBody>
              {
                (rowsPerPage > 0
                  ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : stableSort(rows, getComparator(order, orderBy))
                )
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      { root || role === "admin" ? 
                        <TableCell align="left" style={{width: '10px'}}>
                          {row.hidden ? <VisibilityOff color='primary' />: '' }
                        </TableCell> 
                      : ''}
                      <TableCell align="left">{(new Date(row.created)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })}</TableCell>
                      <TableCell align="left">{(new Date(row.created)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })}</TableCell>
                      <TableCell align="left">{row.landingrunway}</TableCell>
                      <TableCell align="left">{row.icaoaddress}</TableCell>
                      <TableCell align="left">{row.callsign}</TableCell>
                      <TableCell align="left">{row.emitter_desc}</TableCell>
                      <TableCell align="left">{row['landinganalysis'] !== null  ? Math.round(row.landinganalysis.timeonrunway) +"s": "-"}</TableCell>
                      <TableCell align="left">
                        <IconButton 
                          title="Map"
                          size="small"
                          color="secondary"
                          className={classes.iconButton}
                          component={Link} to={`/landing/${row.id}`}>
                          <MapOutlinedIcon />
                        </IconButton>
                      </TableCell>

                      {
                        root ? 
                          <>
                          <TableCell align="left">
                          <IconButton 
                            title="Data"
                            size="small"
                            color="secondary"
                            className={classes.iconButton}
                            component={Link} to={`/landingdata/${row.id}`}>
                            <GridOnOutlinedIcon />
                          </IconButton>
                        </TableCell>

                        <TableCell align="left">
                          <IconButton 
                            title="Data (RAW)"
                            size="small"
                            color="secondary"
                            className={classes.iconButton}
                            component={Link} to={`/landingdataraw/${row.id}`}>
                            <GridOnOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        </>
                        :
                          null
                      }

                      <TableCell align="left">
                        <IconButton 
                          title="Analysis"
                          size="small"
                          color="secondary"
                          className={classes.iconButton}
                          component={Link} to={`/analysis/${row.id}`}>
                          <AssessmentOutlinedIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell align="left">
                        {/* {
                          row.alert ?
                          <IconButton 
                            title="Alert"
                            size="small"
                            component={Link} to={`/landingalerts/${row.id}`}>
                            <ErrorOutlinedIcon style={{fill: "red"}} />
                          </IconButton>
                          : 
                          null
                        } */}
                        <LandingAlertIcons alerts={row.landingalerts} id={row.id} />
                      </TableCell>

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, { label: 'All', value: -1 }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {pageName === "search" ? LandingsListAlert(
        open, 
        () => {setOpen(false)}, 
        () => {setAllRowsPerPage()}
      ): null}
    </div>
  );
}

function LandingsListAlert(isOpen, handleClose, handleAgree) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning: You are displaying all the rows"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Doing this action may cause your browser to perform slow due to high number of rows. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Discard
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

LandingsList.propTypes = {
  data: PropTypes.array.isRequired,
  pageName: PropTypes.string.isRequired
};
