import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useRef } from "react";
import {select, line, symbol, symbolTriangle, curveBasis} from 'd3';

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    weatherItems: {
        width: '50%',
        textAlign: "center"
    },
    weatherIcon: {
        position: 'relative',
        top: '4px'
    }
}));

export default function DecelerationTrend({deceleration}) {
    const classes       =   useStyles();
    const chartRef      =   useRef();
    const containerRef  =   useRef();
    const landings      =   deceleration.reverse();

    const allDates  =   landings.map((landing)=> {
        return new Date(landing['created']).getTime();
    });

    const maxDate   =   new Date();
    const minDate   =   new Date(Math.min(...allDates));

    // 7 times between the min and max date
    const dates = [];
    for (var i = 0; i < 7; i++) {
        dates.push(new Date(minDate.getTime() + i * (maxDate.getTime() - minDate.getTime()) / 7));
    }

    dates.push(maxDate);

    const averages  =   landings.map((landing, index)=> {
        var deceleration = JSON.parse(landing['landinganalysis']['decelerationTimeData']);
        var total = 0;

        for ( var i = 0; i < deceleration.length; i++ ) {
            total += deceleration[i]['deceleration'];
        }

        var inx = (new Date(landing['created']).getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime());

        return [inx, total / deceleration.length, landing['landingalerts'].length];
    });

    const curve = line().curve(curveBasis);

    // @TODO: clean up d3 code
    useEffect(() => {
        if ( deceleration.length === 0 ) {
            return;
        }
        const width = containerRef.current.clientWidth-32;
        const height = 300;
        var element   =   select(chartRef.current)
        var triangle  =   symbol().type(symbolTriangle).size(20);

        const getDate = (date) => {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return hours + ":" + minutes ;
        }
        
        element = element.enter().append('svg')
                .merge(element)
                .attr('width',  width)
                .attr('height', height);

        // plot the x-axis lables with the dates available
        element.selectAll(".x-dates").remove()
        element.selectAll(".x-dates")
            .data(dates)
            .enter()
            .append("text")
            .attr("class", "x-dates")
            .attr("x", (d, i) => i * (width / dates.length) + 40)
            .attr("y", height - 6)
            .text(d => getDate(d));

        element.selectAll(".y-label").remove()
        element.append("text")
            .attr("class", "y-label")
            .attr("text-anchor", "end")
            .attr("y", 6)
            .attr("x", -70)
            .attr("dy", ".75em")
            .attr("transform", "rotate(-90)")
            .text("Deceleration (g)");


        element.selectAll("circle").remove()
        element.selectAll(".circle")
            .data(averages)
            .enter()
            .append("circle")
            .attr("cx", d => d[0] * width + 40)
            .attr("cy", d => d[1]*300+height/3)
            .attr("r", 4)
            .attr("fill", d => d[2] > 0 ? "#FF0000" : "#5275BF")

        // Plotting the curve
        element.selectAll(".curve").remove()
        element.append('path')
            .attr('d', curve(averages.map((d)=> {return [d[0] * width + 40, d[1]*300+height/3]})))
            .attr("class", "curve")
            .attr('stroke', '#5275BF')
            .attr('fill', 'none')
            .attr('stroke-width', '4') 

        element.selectAll(".triangle").remove()
        element.append("path")
            .attr("d", triangle)
            .attr("class", "triangle")
            .attr("stroke", "#5275BF")
            .attr("fill", "#5275BF")
            .attr("transform", "translate(" + (width - 70) + "," + 8 + ")" );

        element.selectAll(".trianglet").remove()
        element.append("text")
            .attr("class", "trianglet")
            .attr("text-anchor", "end")
            .attr("y", 2)
            .attr("x", width)
            .attr("dy", ".75em")
            .attr("font-size", "13px")
            .text("Improving");


        element.selectAll(".triangle2").remove()
        element.append("path")
            .attr("d", triangle)
            .attr("class", "triangle2")
            .attr("stroke", "#5275BF")
            .attr("fill", "#5275BF")
            .attr("transform", "translate(" + (width - 70) + "," + 30 + ") scale(1, -1)" )

        element.selectAll(".trianglet2").remove()
        element.append("text")
            .attr("class", "trianglet2")
            .attr("text-anchor", "end")
            .attr("y", 25)
            .attr("x", width)
            .attr("dy", ".75em")
            .attr("font-size", "13px")
            .text("Declining");

        console.log(chartRef)
    }, [...deceleration])

    if ( deceleration.length === 0 ) {
        return <Paper className={classes.widget}>
            <Box marginTop={1}>
                <Typography variant="body1" color="textSecondary">
                    No recent landings.
                </Typography>
            </Box>
        </Paper>
    }

    return <Paper className={classes.widget} ref={containerRef}>
        <Typography variant="h6">
            <Box textAlign="center">
                DECELERATION TREND (LAST 3 HOURS)
            </Box>
        </Typography>

        <svg  ref={chartRef} />
    </Paper>
}