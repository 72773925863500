// converts polynomials from string to function
export const strToSolvingEquation = (poly) => {
    poly = poly.replaceAll(" ", "")

    var vals= [];

    var temp_str = "";
    // split the string into an array of individuals poly
    for ( var i = 0; i < poly.length; i++ ) {
        var pi = poly[i];
        if ( pi === "+" || pi === "-" ) {
        if ( pi !== 0 && poly[i-1] !== "e" && temp_str !== "" ) {
            vals.push(temp_str)
            temp_str="";
        }
        }
        temp_str += pi;

        if ( i === (poly.length-1) ) {
            vals.push(temp_str)  
        }
    }

    function toFixed(x) {
        var z = x;
        if ( z < 0 ) {
            x = -x;
        }
        if (Math.abs(x) < 1.0) {
          var e = parseInt(x.toString().split('e-')[1]);
          if (e) {
              x *= Math.pow(10,e-1);
              x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
          }
        } else {
          var e = parseInt(x.toString().split('+')[1]);
          if (e > 20) {
              e -= 20;
              x /= Math.pow(10,e);
              x += (new Array(e+1)).join('0');
          }
        }
        if ( z < 0 ) {
            x = '-'+x;
        }
        return x;
      }

    // return a function that can be used to evaluate the polynomial
    const fx = (y) => {
        var output = '';
        var hasLastCoeff = false;
        for ( var x = 0; x < vals.length; x++ ) {
            // Regex to split the string into coeff and power
            var _x = vals[x].match(/-?\d+(\.\d+)?(?:e-?\d+)?|-\d+/g)

            var coeff = parseFloat(_x[0])
            var strCoeff = toFixed(coeff);
            var pow = typeof _x[1] !== 'undefined' ? parseInt(_x[1]) : 0;
            var powStr = '';

            // if no power is specified, it could be just x^1 or x
            if ( vals[x].includes("x") && pow === 0 ) {
                powStr = 'x';
            } else if ( pow === 0 ) {
                powStr = '';
                coeff = coeff - y;
                strCoeff = toFixed(coeff);
                hasLastCoeff = true;
            } else {
                powStr = 'x^' + pow;
            }
            

            // console.log(vals[x], _x, coeff, pow, coeff * Math.pow(input, pow))

            if (x !== 0 && coeff > 0) {
                output += '+';
            }

            output += strCoeff + powStr;
        }

        if ( !hasLastCoeff ) {
            output += toFixed(-y);
        }
        console.log(output)
        return output;
    }

    return fx;
}

export const getCoefficients = (equation) => {
    const regex = /([-+]?\d*\.\d+(?:[eE][-+]?\d+)?)(?=x)|([-+]?\d*\.\d+(?:[eE][-+]?\d+)?$)/g;
    const coefficients = [];
  
    let match;
    while ((match = regex.exec(equation)) !== null) {
      coefficients.push(parseFloat(match[0]));
    }
  
    return coefficients;
  }