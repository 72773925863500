import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max } from 'd3';
import { Box } from '@material-ui/core';
import AnalysisExport from '../components/AnalysisExport';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

// constants
const METERS_TO_FEET = 3.28084;
const roundUp = (num, precision=4) => {
    precision = Math.pow(10, precision)
    return Math.round(num * precision) / precision
}

const MLDecelerationVsDistance = (props) => {
    const [decelerationDistanceData, setDecelerationDistanceData] = useState(props.data)
    const [touchdown, setTouchdown] = useState(props.touchdown)
    const [runwayLength, setRunwayLength] = useState(props.length)
    // const glideSlopePosition=1000
    const [glideSlopeTouchdown, setGlideSlopeTouchdown] = useState(props.glideslope_touchdown)

    // ML values
    const [mlMaxDecel, setMLMaxDecel] = useState(props.mlMaxDecel)
    const [mlMaxDecelDelta, setMLMaxDecelDelta] = useState(props.mlMaxDecelDelta)
    const [mlCurveRMS, setMLCurveRMS] = useState(props.mlCurveRMS)

    const [mlDist, setMLDist] = useState(props.mlDist)
    const [mlGtAccel, setMLGtAccel] = useState(props.mlGtAccel)
    const [mlPredAccel, setMLPredAccel] = useState(props.mlPredAccel)

    // create decelerationDistanceData for ml data
    const mlDecelerationDistanceData = mlDist.map((item, index) => {
        return {
            distance: roundUp(item * METERS_TO_FEET),
            deceleration: roundUp(mlPredAccel[index]),
            deceleration_true: roundUp(mlGtAccel[index])
        }
    })

    const svgRef = useRef()

    

    useEffect(() => {
        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });

            const xMax = Math.max(...decelerationDistanceData.map((item) => item.distance))

            const xScale = scaleLinear()
                .domain([0, xMax])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = 0 // min(decelerationDistanceData.map((item) => item.deceleration))
            const yMax = 1 //max(decelerationDistanceData.map((item) => item.deceleration))

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));


            const dryBandSize = 60
            const goodBandSize = 20
            const mediumBandSize = 5
            const poorBandSize = 10
            const nilBandSize = 5

            const dryBandHeight = innerHeight / 100 * dryBandSize
            const goodBandHeight = innerHeight / 100 * goodBandSize
            const mediumBandHeight = innerHeight / 100 * mediumBandSize
            const poorBandHeight = innerHeight / 100 * poorBandSize
            const nilBandHeight = innerHeight / 100 * nilBandSize

            // Grey Zone
            // const greyzone = g.selectAll('.greyzone').data([null]);
            // greyzone.enter()
            //     .append('rect')
            //     .merge(greyzone)
            //     .attr('class', 'greyzone')
            //     .attr('x', 0)
            //     .attr('y', 0)
            //     .attr('width', xScale(glideSlopeTouchdown))
            //     .attr('height', innerHeight)
            //     .attr('fill', '#A6A6A6')

            // Dry Zone
            // const dryzone = g.selectAll('.dryzone').data([null]);
            // dryzone.enter()
            //     .append('rect')
            //     .merge(dryzone)
            //     .attr('class', 'dryzone')
            //     .attr('x', xScale(glideSlopeTouchdown))
            //     .attr('y', 0)
            //     .attr('width', innerWidth-xScale(glideSlopeTouchdown))
            //     .attr('height', dryBandHeight)
            //     .attr('fill', '#458140')

            // // Good Zone
            // const goodzone = g.selectAll('.goodzone').data([null]);
            // goodzone.enter().append('rect')
            //     .merge(goodzone)
            //     .attr('class', 'goodzone')
            //     .attr('x', xScale(glideSlopeTouchdown))
            //     .attr('y', dryBandHeight)
            //     .attr('width', innerWidth-xScale(glideSlopeTouchdown))
            //     .attr('height', goodBandHeight)
            //     .attr('fill', '#80C373');
            
            // // Medium Zone
            // const mediumzone = g.selectAll('.mediumzone').data([null]);
            // mediumzone.enter().append('rect')
            //     .merge(mediumzone)
            //     .attr('class', 'mediumzone')
            //     .attr('x', xScale(glideSlopeTouchdown))
            //     .attr('y', dryBandHeight + goodBandHeight)
            //     .attr('width', innerWidth-xScale(glideSlopeTouchdown))
            //     .attr('height', mediumBandHeight)
            //     .attr('fill', '#FFFD3E');

            // // Poor Zone
            // const poorzone = g.selectAll('.poorzone').data([null]);
            // poorzone.enter().append('rect')
            //     .merge(poorzone)
            //     .attr('class', 'poorzone')
            //     .attr('x', xScale(glideSlopeTouchdown))
            //     .attr('y', dryBandHeight + goodBandHeight + mediumBandHeight)
            //     .attr('width', innerWidth-xScale(glideSlopeTouchdown))
            //     .attr('height', poorBandHeight)
            //     .attr('fill', '#EC8A4E');

            // // Nil Zone
            // const nilzone = g.selectAll('.nilzone').data([null]);
            // nilzone.enter().append('rect')
            //     .merge(nilzone)
            //     .attr('class', 'nilzone')
            //     .attr('x', xScale(glideSlopeTouchdown))
            //     .attr('y', dryBandHeight + goodBandHeight + mediumBandHeight + poorBandHeight)
            //     .attr('width', innerWidth-xScale(glideSlopeTouchdown))
            //     .attr('height', nilBandHeight)
            //     .attr('fill', '#FC1F27');


            // Glide Slope Line
            const glideSlopeLine = g.selectAll('.glideslope-line').data([null])
            glideSlopeLine
                .enter().append("line")
                .attr("class", 'glideslope-line')
            .merge(glideSlopeLine)
                .attr("x1", xScale(glideSlopeTouchdown))
                .attr("y1", -20)
                .attr("x2", xScale(glideSlopeTouchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Label
            const glideSlopeTitle = g.selectAll('.glideslope-title').data([null])
            glideSlopeTitle
                .enter().append("text")
                .attr('class', 'glideslope-title')
            .merge(glideSlopeTitle)
                .attr('fill', '#368029')
                .attr("x", xScale(glideSlopeTouchdown) + 10)             
                .attr("y", -10)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .html("3&deg; Glide Slope TDP");


            // Touchdown Line
            const touchdownLine = g.selectAll('.touchdown-line').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-line')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdown))
                .attr("y1", 0)
                .attr("x2", xScale(touchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#338DCD')
                .attr("x", xScale(touchdown) + 10)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");
    

            // plot the line
            const lineGenerator_true = line()
                .x((value, index) => xScale(value.distance))
                .y((value, index) => yScale(value.deceleration_true))
                .curve(curveBasis)

            const lineGenerator_expect = line()
                .x((value, index) => xScale(value.distance))
                .y((value, index) => yScale(value.deceleration))
                .curve(curveBasis)

            g.selectAll('.line-true')
                .data([mlDecelerationDistanceData])
                .join('path')
                .attr('class', 'line-true')
                .attr('d', value => lineGenerator_true(value))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '1')

            g.selectAll('.line-expected')
                .data([mlDecelerationDistanceData])
                .join('path')
                .attr('class', 'line-expected')
                .attr('d', value => lineGenerator_expect(value))
                .attr('fill', 'none')
                .attr('stroke', '#19A400')
                .attr('stroke-width', '1')

            // show legends for the lines
            drawLegend(g, [
                {color: 'black', text: 'Actual Deceleration'},
                {color: '#19A400', text: 'Expected Deceleration'}
            ], {innerWidth, innerHeight, margin})

            // draw the points - actual decel
            g.selectAll('.dots2')
                .data(mlDecelerationDistanceData)
                .join("circle")
                .attr('class', 'dots2')
                .attr('stroke','black')
                .attr('r','2')
                .attr('fill','black')
                .attr('cx', (d) => xScale(d.distance))
                .attr('cy', (d) => yScale(d.deceleration_true))
                .append("svg:title")
                .text(function(d, i) { return `(${d.distance}, ${d.deceleration_true})` });

            // draw the points - expected decel
            g.selectAll('.dots')
                .data(mlDecelerationDistanceData)
                .join("circle")
                .attr('class', 'dots')
                .attr('stroke','#19A400')
                .attr('r','2')
                .attr('fill','#19A400')
                .attr('cx', (d) => xScale(d.distance))
                .attr('cy', (d) => yScale(d.deceleration))
                .append("svg:title")
                .text(function(d, i) { return `(${d.distance}, ${d.deceleration})` });

            

        }

        function drawLegend(g, items, props) {
            const { innerWidth, innerHeight, margin } = props;
        
            const legend = g.selectAll('.legend')
                .data(items)
                .join('g')
                .attr('class', 'legend')
                .attr('transform', (d, i) => `translate(${innerWidth - 150}, ${20 * i})`);
        
            legend.append('line')
                .attr('x1', 0)
                .attr('x2', 20)
                .attr('y1', 10)
                .attr('y2', 10)
                .attr('stroke', d => d.color)
                .attr('stroke-width', '2');
        
            legend.append('text')
                .attr('x', 30)
                .attr('y', 10)
                .attr('dy', '0.32em')
                .text(d => d.text)
                .style('font-size', '12px');
        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: window.innerWidth-20,
                height: 300,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Distance (ft)',
                yAxisLabel: 'Deceleration (g)'
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    return ( 
        <div style={{'pageBreakBefore': 'always'}}>
            {props.root && <AnalysisExport name={"ML Deceleration vs. Distance"} data={mlDecelerationDistanceData} />}
            <Box style={{marginLeft: 100, marginBottom: 30}}>
                ML Max Decel: {roundUp(mlMaxDecel)}g &nbsp;&nbsp;&nbsp;
                ML Max Decel Delta: {roundUp(mlMaxDecelDelta)}g &nbsp;&nbsp;&nbsp;
                ML Curve RMS: {roundUp(mlCurveRMS)} &nbsp;&nbsp;&nbsp;
            </Box>
            <svg ref={svgRef}></svg>
        </div>
    );
}

export default MLDecelerationVsDistance;
