import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import * as axios from 'axios'
import utmObj from 'utm-latlng'
// import LandingBanner from "./LandingBanner";
import AirportImage from '../charts/AirportImage'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from "@material-ui/core";
import AircraftThumbnail from "./AircraftThumbnail";
import CircularProgress from '@material-ui/core/CircularProgress';
import Weather from "./weather";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(1),
  }
}));

function Landing2() {
  const classes = useStyles();
  const [data, setData] = useState({})
  // const [runwayRect, setRunwayRect] = useState({})
  const [airport, setAirport] = useState(null)
  const { id } = useParams()

  const [airportName, setAirportName] = useState('')
  const [airportCode, setAirportCode] = useState('')
  const [landingDate, setLandingDate] = useState('')
  const [landingRunway, setLandingRunway] = useState('')
  const [icao, setIcao] = useState('')
  const [callsign, setCallsign] = useState('')
  const [satimgTopLeft, setSatimgTopLeft] = useState([0,0])

  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [registration, setRegistration] = useState('')
  // const [owner, setOwner] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    // console.log('id:', id)
    if (id !== undefined) {
      axios.get(`${WEBAPI}/landing/${id}`)
      .then((res) => {
          let d = res.data[0]
          // console.log(d)

          setAirportName(d.runway.airport.airportname)

          setSatimgTopLeft(d.runway.airport.satimg_topleft.coordinates)
          setAirportCode(d.runway.airport.code)

          setLandingDate(d.created)
          setLandingRunway(d.landingrunway)
          setIcao(d.icaoaddress)
          setCallsign(d.callsign)
          
          setData(d)

          // console.log(d.runway)
          const coords = d.runway.polygon.coordinates[0]
          const rwRect = []

          const pt1 = utmTolatlon([coords[0][0], coords[0][1]])
          const pt2 = utmTolatlon([coords[1][0], coords[1][1]])
          const pt3 = utmTolatlon([coords[2][0], coords[2][1]])
          const pt4 = utmTolatlon([coords[3][0], coords[3][1]])

          rwRect.push([pt1.lat, pt1.lng])
          rwRect.push([pt2.lat, pt2.lng])
          rwRect.push([pt3.lat, pt3.lng])
          rwRect.push([pt4.lat, pt4.lng])

          // setRunwayRect(rwRect)

          setAirport(d.runway.airport)

          axios.get(`${WEBAPI}/aircraft/${d.icaoaddress}`)
          .then((res) => {
            // console.log(res.data)
            setMake(res.data.make)
            setModel(res.data.model)
            setRegistration(res.data.registration)
            // setOwner(res.data.owner)
      
            setImageUrl(res.data.image_url)
          })
          .catch((err) => {
            console.log(err)
          })

      })
      .catch((err) => {
          console.log(err);
      })
    }

  }, [id]);

  const utmTolatlon = (pt) => {
    const utm = new utmObj(); //Default Ellipsoid is 'WGS 84'
    const latlon = utm.convertUtmToLatLng(pt[0], pt[1], 18, 'N')
  
    return latlon
  }
  
  return (
      <div>
        { airport ? 
          <>
            <div className={classes.root}>
              <Paper elevation={3} style={{ padding: 10}}>

              <Box className={classes.header} display='inline' >
                <Box display='inline'>
                  <Weather code={airportCode} ts={landingDate}  /> 
                  &nbsp;&nbsp;

                  <Typography color="textSecondary" variant="body2" display='inline'>
                    Airport:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" title={airportName} display='inline'>
                    {airportCode}
                  </Typography>
                  &nbsp;&nbsp;

                  <Typography color="textSecondary" variant="body2" display='inline'>
                    Date:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" display='inline'>
                    {(new Date(landingDate)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })}&nbsp;&nbsp;
                    {(new Date(landingDate)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })}
                  </Typography>
                  &nbsp;&nbsp;

                  <Typography color="textSecondary" variant="body2" display='inline'>
                  Runway:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" display='inline'>
                    {landingRunway}
                  </Typography>

                </Box>
              </Box>

              <Grid container style={{marginTop: 10}}>
                <Grid item xs={12} lg={2} >
                    <AircraftThumbnail thumbnailURL={imageUrl} />
                    
                    {/* <Box>
                      <Typography color="textSecondary" variant="body2" display='inline'>
                        Airport:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" title={airportName} display='inline'>
                        {airportCode}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography color="textSecondary" variant="body2" display='inline'>
                      Date:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {(new Date(landingDate)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })}&nbsp;&nbsp;
                        {(new Date(landingDate)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography color="textSecondary" variant="body2" display='inline'>
                      Runway:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {landingRunway}
                      </Typography>
                    </Box> */}

                    {
                      registration ?
                      <Box>
                        <Typography color="textSecondary" variant="body2" display='inline'>
                        Registration:&nbsp;
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2" display='inline'>
                          {registration}
                        </Typography>
                      </Box>
                      :
                      null
                    }

                    <Box>
                      <Typography color="textSecondary" variant="body2" display='inline'>
                      ICAO:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {icao}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography color="textSecondary" variant="body2" display='inline'>
                      Callsign:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {callsign}
                      </Typography>
                    </Box>

                    {
                      make ?
                      <Box>
                        <Typography color="textSecondary" variant="body2" display='inline'>
                        Make:&nbsp;
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2" display='inline'>
                          {make}
                        </Typography>
                      </Box>
                      :
                      null
                    }

                    {
                      model ?
                      <Box>
                        <Typography color="textSecondary" variant="body2" display='inline'>
                        Model:&nbsp;
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2" display='inline'>
                          {model}
                        </Typography>
                      </Box>
                      :
                      null
                    }

                  </Grid>

                  <Grid item xs={12} lg={10} >
                      <AirportImage code={airportCode} utm={satimgTopLeft} landing={data.landingdata}  />
                  </Grid>

                </Grid>

              </Paper>
            </div>
           </>
        : 
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </Box>
        }
      </div>
  );

}

export default Landing2;
