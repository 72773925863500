import { useSelector } from 'react-redux';
import { FormControl, makeStyles, TextField, Typography, Grid, Switch, FormControlLabel, Button, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';

const WEBAPI = process.env.REACT_APP_WEB_API;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1),
    },
    spacing: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    textfield: {
        width: '100%',
    }
  }));

export default function Settings() {
    const classes = useStyles();

    const [snackopen, setSnackOpen] = React.useState(false)
    const [snackmessage, setSnackMessage] = React.useState('')
    const [snackseverity, setSnackSeverity] = React.useState('success')

    const [isMaintanceModeEnabled, setIsMaintanceModeEnabled] = useState(false)
    const [maintanceModeMessage, setMaintanceModeMessage] = useState('')

    const root = useSelector((state) => state.root.value)

    useEffect(() => {
        if ( root ) {
            axios.get(`${WEBAPI}/setting`).then((res) => {
                if ( res.data.length > 0 ) {
                    res.data.forEach((setting) => {
                        if ( setting.name === 'is_maintance_mode_enabled' ) {
                            setIsMaintanceModeEnabled(setting.value === 'true' ? true : false)
                        } else if ( setting.name === 'maintance_mode_message' ) {
                            setMaintanceModeMessage(setting.value === 'null' ? '' : setting.value)
                        }
                    })
                }
            })
        }
    }, []);

    if ( root !== undefined ) {
        if ( root !== true ) {
            return '';
        }
    }

    function setSnack (open=false, message='', severity='success') {
        setSnackOpen(open)
        setSnackMessage(message)
        setSnackSeverity(severity)
    }

    const handleSaveSettings = () => {
        axios.post(`${WEBAPI}/setting`, {
            settings: [
                {
                    name: 'is_maintance_mode_enabled',
                    value: isMaintanceModeEnabled
                },
                {
                    name: 'maintance_mode_message',
                    value: maintanceModeMessage
                }
            ]
        }).then((res) => {
            if ( res.data.success === true ) {
                setSnack(true, 'Settings saved successfully', 'success')
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            } else {
                setSnack(true, 'Error saving settings', 'error')
            }
        })
    }
    
    return (
        <div className={classes.root}>
            <Typography variant="h5">Settings</Typography>

            <Grid container>
                <Grid item xs={12} sm={4} >
                    <FormControlLabel 
                        label={"Enable Maintainance Mode"} 
                        className={classes.spacing}
                        control={
                        <Switch 
                            label="Enable Maintainance Mode"
                            name="is_maintance_mode_enabled"
                            color="primary"
                            checked={isMaintanceModeEnabled}
                            onChange={(e) => setIsMaintanceModeEnabled(e.target.checked)}
                        />} 
                    />
                        
                    <FormControl className={classes.textfield}>
                        <TextField
                            id="maintance_mode_message"
                            name="maintance_mode_message"
                            label="Maintainance Notice Message"
                            multiline
                            minRows={4}
                            variant="outlined"
                            value={maintanceModeMessage}
                            onChange={(e) => setMaintanceModeMessage(e.target.value)}
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.spacing}
                        onClick={handleSaveSettings}
                    >
                        Save Settings
                    </Button>
                </Grid>
            </Grid>

            <Snackbar open={snackopen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
                <Alert severity={snackseverity} onClose={() => setSnackOpen(false)}>
                    {snackmessage}
                </Alert>
            </Snackbar>
        </div>
    )
}
