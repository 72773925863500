import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from 'react-router-dom';
import * as axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setAuthorityId } from '../redux/authoritySlice'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const WEBAPI = process.env.REACT_APP_WEB_API;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'airport', numeric: false, disablePadding: false, label: 'Airport' },
  { id: 'period', numeric: false, disablePadding: false, label: 'Hours after Precipitation' },
  { id: 'windspeed', numeric: false, disablePadding: false, label: 'Wind Speed (Knots)' },
  { id: 'update_alert_history', numeric: false, disablePadding: false, label: 'Update Alert History' },

];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox className={classes.headerCheckbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all airports' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, id } = props;
  const history = useHistory();

  // const handleDelete = (id) => {
  //   console.log('handleDelete', id);
  // }

  const handleEdit = (id) => {
    // history.push(`/user/${id}`)
    history.push({pathname: `/airportalert/${id}`})
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Airport Alert Conditions
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <IconButton 
            title="Edit"
            className={classes.iconButton}
            onClick={() => handleEdit(id)}>
            <EditOutlinedIcon />
          </IconButton>

          {/* <IconButton 
            title="Delete"
            className={classes.iconButton}
            onClick={() => handleDelete(id)}>
            <DeleteIcon />
          </IconButton> */}
      </>
    ) :
    null
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  authorityId: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.info.main
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  airportSelect: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },

}));

export default function AirportAlerts() {
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [authorities, setAuthorities] = useState([]);
  const authorityId = useSelector((state) => state.authorityId.value)
  const dispatch = useDispatch()
  const root = useSelector((state) => state.root.value)
  const role = useSelector((state) => state.role.value)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios
    .get(`${WEBAPI}/authority`)
    .then((res) => {
      setAuthorities(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);

  useEffect(() => {
    setLoading(true)

    if (authorityId !== '') {
      axios.get(`${WEBAPI}/airport/alerts/${authorityId}`)
      .then((res) => {
        console.log('Data: ', res.data)
        if (res.data.length === 0) {
          setRows([])
        } else {
          setRows(res.data)
        }
      })
      .catch((err) => {
          console.log(err);
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }, [authorityId]);

  const handleDelete = (id) => {
    console.log('handleDelete', id)

    const payload = {data: {id: id}}
    axios.delete(`${WEBAPI}/alert`, payload)
    .then((res) => {
        // setRows(res.data)
        console.log('delete successful', res.status);
        const newRows = rows.filter((item) => {
          return item.id !== id
        }) 

        setSelected([])
        setRows(newRows)
    })
    .catch((err) => {
        console.log(err);
    })

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (role === 'sales') return; 
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(id)
    }

    // if (selectedIndex === -1) { 
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) { 
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   // setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const handleAuthorityChange = (e) => {
    console.log('handleAuthorityChange')
    dispatch(setAuthorityId(e.target.value))
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
    {
      loading ?
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress color="secondary" />
      </Box>
      :
      <Paper className={classes.paper}>
        {
          root ?
            <Grid container className={classes.airportSelect}>
              <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size={"small"}
                  >
                    <InputLabel>Authority</InputLabel>
                    <Select
                      value={authorityId}
                      onChange={(e) => { handleAuthorityChange(e) }}
                      id="authority"
                      label="Authority"
                      name="authority"
                      autoFocus
                    >
                      {authorities?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
              </Grid>
            </Grid>
          :
          null
        }

        <EnhancedTableToolbar numSelected={selected.length} id={selected[0]} handleDelete={handleDelete} authorityId={authorityId} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={role === "sales"}
                        />
                      </TableCell>
                      {/* <TableCell component="th" id={row.id} scope="row" padding="none">{row.code}</TableCell> */}
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.alert_precip_period}</TableCell>
                      <TableCell align="left">{row.alert_windspeed}</TableCell>
                      <TableCell align="left">{row.update_alert_history === false ? "No" : "Yes"}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    }
    </div>
  );
}
