import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import * as axios from 'axios'
import utmObj from 'utm-latlng'
// import LandingBanner from "./LandingBanner";
import AirportImage from '../charts/AirportImage'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from "@material-ui/core";
import AircraftThumbnail from "./AircraftThumbnail";
import CircularProgress from '@material-ui/core/CircularProgress';
import Weather from "./weather";
import LandingBanner from './LandingBanner';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(1),
  },
  airportImage: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.info.main
  },

}));

export function TestMap() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState({})
  // const [runwayRect, setRunwayRect] = useState({})
  const [airport, setAirport] = useState(null)
  const id= "4ad35a74-2bd2-418f-9f51-32a492b2c36a"
  const coordinates = [
    294213.58, 5278769.90
  ]
// const id = "cc1f4ca8-d482-409d-9cca-c30b66799801"
// const coordinates = [
//     447787.41,
//     5018331.66
// ]


  const [airportName, setAirportName] = useState('')
  const [airportCode, setAirportCode] = useState('')
  const [landingDate, setLandingDate] = useState('')
  const [landingRunway, setLandingRunway] = useState('')
  const [icao, setIcao] = useState('')
  const [callsign, setCallsign] = useState('')
  const [satimgTopLeft, setSatimgTopLeft] = useState([0,0])
  const [prophidden, setHidden] = useState(false)
  const [pattern, setPattern] = useState(null)

  const root = useSelector((state) => state.root.value)
  const role = useSelector((state) => state.role.value)

  // const [make, setMake] = useState('')
  // const [model, setModel] = useState('')
  // const [registration, setRegistration] = useState('')
  // // const [owner, setOwner] = useState('')
  // const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    // console.log('id:', id)
    if (id !== undefined) {
      axios.get(`${WEBAPI}/airport/${id}`)
      .then((res) => {
          let d = res.data
          // console.log(d)

          if ( !(root || role === "admin") ) {
            if ( d.hidden === true ) {
              // redirect to landing page
              history.push('/landings')
            }
          }

          console.log(d)

          setAirportName(d.airportname)

          setSatimgTopLeft(d.satimg_topleft.coordinates)
          setAirportCode(d.code)

          setLandingDate(d.created)
          setLandingRunway(d.landingrunway)
          setIcao(d.icaoaddress)
          setCallsign(d.callsign)

          setHidden(d.hidden)
          setPattern(d.landing_pattern)
          
          setData({landingdata: [{utm: {coordinates: coordinates}}], landingalerts: []})

          // console.log(d.runway)
        //   const coords = d.runway.polygon.coordinates[0]
        //   const rwRect = []

        //   const pt1 = utmTolatlon([coords[0][0], coords[0][1]])
        //   const pt2 = utmTolatlon([coords[1][0], coords[1][1]])
        //   const pt3 = utmTolatlon([coords[2][0], coords[2][1]])
        //   const pt4 = utmTolatlon([coords[3][0], coords[3][1]])

        //   rwRect.push([pt1.lat, pt1.lng])
        //   rwRect.push([pt2.lat, pt2.lng])
        //   rwRect.push([pt3.lat, pt3.lng])
        //   rwRect.push([pt4.lat, pt4.lng])

          // setRunwayRect(rwRect)

          setAirport(d)

          // axios.get(`${WEBAPI}/aircraft/${d.icaoaddress}`)
          // .then((res) => {
          //   // console.log(res.data)
          //   setMake(res.data.make)
          //   setModel(res.data.model)
          //   setRegistration(res.data.registration)
          //   // setOwner(res.data.owner)
      
          //   setImageUrl(res.data.image_url)
          // })
          // .catch((err) => {
          //   console.log(err)
          // })

      })
      .catch((err) => {
          console.log(err);
      })
    }

  }, [id]);

  const utmTolatlon = (pt) => {
    const utm = new utmObj(); //Default Ellipsoid is 'WGS 84'
    const latlon = utm.convertUtmToLatLng(pt[0], pt[1], 18, 'N')
  
    return latlon
  }
  
  return (
      <div>
        { airport ? 
          <>
          <div className={classes.airportImage}>
            <Paper className={classes.paper}>
              <AirportImage code={airportCode} utm={satimgTopLeft} landing={data.landingdata} alerts={data.landingalerts} />
            </Paper>
          </div>
          </>
        : 
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress color="secondary" />
          </Box>
        }
      </div>
  );

}
