// converts polynomials from string to function
export const strToFx = (poly) => {
    poly = poly.replaceAll(" ", "")
    poly = poly.split("=")[1]

    var vals= [];

    var temp_str = "";
    // split the string into an array of individuals poly
    for ( var i = 0; i < poly.length; i++ ) {
        var pi = poly[i];
        if ( pi === "+" || pi === "-" ) {
        if ( pi !== 0 && poly[i-1] !== "e" && temp_str !== "" ) {
            vals.push(temp_str)
            temp_str="";
        }
        }
        temp_str += pi;

        if ( i === (poly.length-1) ) {
        vals.push(temp_str)  
        }
    }

    // return a function that can be used to evaluate the polynomial
    const fx = (input) => {
        var output = 0;
        for ( var x = 0; x < vals.length; x++ ) {
            // Regex to split the string into coeff and power
            var _x = vals[x].match(/-?\d+(\.\d+)?(?:e-?\d+)?|-\d+/g)

            var coeff = parseFloat(_x[0])
            var pow = typeof _x[1] !== 'undefined' ? parseInt(_x[1]) : 0;

            // if no power is specified, it could be just x^1 or x
            if ( pow === 0 ) {
                pow = vals[x].includes("x") ? 1 : 0;
            }

            // console.log(vals[x], _x, coeff, pow, coeff * Math.pow(input, pow))

            output += coeff * Math.pow(input, pow)
        }

        return output;
    }

    return fx;
}