export const chartTheme = {
  titleFill: '#C0C0BB',
  gridLineFill: '#635F5D',
  xAxisLabelFill: '#635F5D',
  yAxisLabelFill: '#635F5D',
  xAxisLabelOffset: 52,
  yAxisLabelOffset: 64,
  xAxisLabelFontSize: '24px',
  yAxisLabelFontSize: '24px',
  xAxisTickFontSize: '16px',
  yAxisTickFontSize: '16px',
  xAxisTickFontFill: '#8E8883',
  yAxisTickFontFill: '#8E8883',
  xAxisTickLineStroke: '#C0C0BB',
  yAxisTickLineStroke: '#C0C0BB',
  xAxisTickDensity: 70, // Pixels per tick.
  yAxisTickDensity: 70,
  xAxisDomainLineStroke: '#C0C0BB',
  yAxisDomainLineStroke: '#C0C0BB'
};