import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import landingDateReducer from './landingDateSlice'
import airportCodeReducer from './airportCodeSlice'
import authorityIdReducer from './authoritySlice'
import rootReducer from './rootSlice'
import authenticatedReducer from './authenticatedSlice'
import firstNameReducer from './firstNameSlice'
import roleReducer from './roleSlice'
import landingsCountReducer from './landingsCountSlice'

const persistConfig = {
  key: 'airportCode',
  storage,
}

const persistedAirportCodeReducer = persistReducer(persistConfig, airportCodeReducer)

const store = configureStore({
  reducer: {
    landingDate: landingDateReducer,
    airportCode: persistedAirportCodeReducer,
    authorityId: authorityIdReducer,
    root: rootReducer,
    authenticated: authenticatedReducer,
    firstName: firstNameReducer,
    role: roleReducer,
    landingsCount: landingsCountReducer
  },
})

const persistor = persistStore(store)

// export { store, persistor }
export default store;