import { createSlice } from '@reduxjs/toolkit'

export const authenticatedSlice = createSlice({
  name: 'authenticated',
  initialState: {
    value: false,
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setAuthenticated } = authenticatedSlice.actions
export default authenticatedSlice.reducer
