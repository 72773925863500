import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useRef } from "react";
import {select, line, curveBasis} from 'd3';

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    weatherItems: {
        width: '50%',
        textAlign: "center"
    },
    weatherIcon: {
        position: 'relative',
        top: '4px'
    }
}));

export default function DecelerationTrend({recentLanding, runway}) {
    const classes       =   useStyles();
    const chartRef      =   useRef();
    const containerRef  =   useRef();
    const created       =   recentLanding[runway] && new Date( recentLanding[runway][0].created );
    const analysis      =   recentLanding[runway] && (recentLanding[runway][0]['landinganalysis'] === null ? null: JSON.parse(recentLanding[runway][0]['landinganalysis']['directionalComplianceSmoothedData']));

    
    const curve = line().curve(curveBasis);

    // @TODO: clean up d3 code
    useEffect(() => {
        if ( analysis === null || recentLanding[runway] === undefined ) {
            return null;
        }

        const minX  =   Math.min(...analysis.map((d) => d.x))
        const maxX  =   Math.max(...analysis.map((d) => d.x))


        const width = containerRef.current.clientWidth-32;
        var element   =   select(chartRef.current)
        
        element = element.enter().append('svg')
                .merge(element)
                .attr('width',  width)
                .attr('height', 300);

        // Axis Lines
        element.selectAll(".x-line").remove();
        element.append("line")
            .attr("class", "x-line")
            .attr("x1", 40)
            .attr("y1", 270)
            .attr("x2", width-16)
            .attr("y2", 270)
            .attr("stroke", "#ccc")

        element.selectAll(".y-line").remove();
        element.append("line")
            .attr("class", "y-line")
            .attr("x1", 40)
            .attr("y1", 30)
            .attr("x2", 40)
            .attr("y2", 270)
            .attr("stroke", "#ccc")

        // Y-Axis lables
        element.selectAll(".y-text").remove();
        const axisData  =   [-20, -10, 0, 10, 20]
        element.selectAll(".y-text")
            .data(axisData)
            .enter()
            .append("text")
            .attr("class", "y-text")
            .attr("text-anchor", "end")
            .attr("x", 37)
            .attr("y", d => 150 + d*3)
            .attr("fill", "#ccc")
            .attr("font-size", "13px")
            .text(d => d)
    

        // Draw the runway or simulated lines
        element.selectAll(".x-line1").remove();
        element.append("line")
            .attr("class", "x-line1")
            .attr("x1", 40)
            .attr("y1", 150)
            .attr("x2", width-16)
            .attr("y2", 150)
            .attr("stroke", "#ccc")

        element.selectAll(".x-line2").remove();
        element.append("line")
            .attr("class", "x-line2")
            .attr("x1", 40)
            .attr("y1", 150+20*3)
            .attr("x2", width-16)
            .attr("y2", 150+20*3)
            .attr("stroke", "#ED7D31")
            .attr("stroke-dasharray","10,10");

        element.selectAll(".x-line3").remove();
        element.append("line")
            .attr("class", "x-line3")
            .attr("x1", 40)
            .attr("y1", 150-20*3)
            .attr("x2", width-16)
            .attr("y2", 150-20*3)
            .attr("stroke", "#ED7D31")
            .attr("stroke-dasharray","10,10");


        // Axis labels
        element.selectAll(".x-label").remove()
        element.append("text")
            .attr("class", "x-label")
            .attr("text-anchor", "end")
            .attr("x", width-50*6)
            .attr("y", 300 - 6)
            .text((created.toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit', hour: '2-digit', minute:'2-digit', hour12: true }) + " "+recentLanding[runway][0]['icaoaddress']));

        element.selectAll(".y-label").remove()
        element.append("text")
            .attr("class", "y-label")
            .attr("text-anchor", "end")
            .attr("y", 0)
            .attr("x", -30)
            .attr("dy", ".75em")
            .attr("transform", "rotate(-90)")
            .text("Distance from Center line (ft)");


        // element.selectAll("circle").remove()
        // element.selectAll(".circle")
        //     .data(analysis)
        //     .enter()
        //     .append("circle")
        //     .attr("cx", d => ((d.x-minX)/(maxX))*(width-6)+30)
        //     .attr("cy", d => d.y*3+150)
        //     .attr("r", 4)
        //     .attr("fill", "#5275BF").on('mouseover', (d) => {console.log(d)})

        // Plotting the curve
        element.selectAll("path").remove()
        element.append('path')
            .attr('d', curve(analysis.map((d) => [(((d.x-minX)/(maxX))*(width-6)+40), d.y*3+150])))
            .attr('stroke', '#5275BF')
            .attr('fill', 'none')
            .attr('stroke-width', '4') 

        console.log(chartRef)
    }, [...recentLanding, runway])

    if ( recentLanding[runway] === undefined ) {
        return <Paper className={classes.widget}>
            <Box marginTop={1}>
                <Typography variant="body1" color="textSecondary">
                    No recent landings.
                </Typography>
            </Box>
        </Paper>
    }

    return <Paper className={classes.widget} ref={containerRef}>
        <Typography variant="h6">
            <Box textAlign="center">
                DIRECTIONAL COMPLIANCE (LAST LANDING)
            </Box>
        </Typography>

        <svg  ref={chartRef} />
    </Paper>
}