import { useEffect, useRef, useState } from 'react'
// import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
// import { marginConvention } from './helpers/marginConvention'
import { select, zoom, zoomIdentity, zoomTransform, path, pointer } from 'd3';
// import Airport from '../components/Airport';
import { makeStyles } from '@material-ui/core/styles';
// import getCenter from 'geolib/es/getCenter';
// import Button from '@material-ui/core/Button';
// import { Box } from '@material-ui/core';
// import { alertTypes, runwayArea } from '../helpers/helper'


const MARKER_PASS_OUTER_COLOUR = '#000000'
const MARKER_FAIL_OUTER_COLOUR = '#000000'
const MARKER_PASS_INNER_COLOUR = '#00FF00'
const MARKER_FAIL_INNER_COLOUR = '#FFFF00'

const useStyles = makeStyles((theme) => ({
    svg: {
    //   marginTop: theme.spacing(2),
    //   border: '5px solid red' 
    },
    container: {
        width: window.innerWidth,
        height: window.innerHeight,
        border: '5px solid red'
    },
  }));
  

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const AirportImage = (props) => {
    console.log("AirportImage", props)
    const [code, setCode] = useState('')
    const [runways, setRunways] = useState([])
    const [easting, setEasting] = useState('')
    const [northing, setNorthing] = useState('')
    const [landing, setLanding] = useState([])
    const [alerts, setAlerts] = useState([])

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [k, setK] = useState(1)
    const [initialized, setInitialized] = useState(false)
    const xRef = useRef()
    const yRef = useRef()
    const kRef = useRef()
    const initRef = useRef()


    const svgRef = useRef()
    const tooltipRef = useRef()
    const classes = useStyles();

    const zoomIn = () => {
        console.log('zoomIn')
    }
    const zoomOut = () => {
        console.log('zoomOut')
    }

    useEffect(() => {
        // console.log('AirportImage useEffect props', props)

        xRef.current = x
        yRef.current = y
        kRef.current = k
        initRef.current = initialized

        function responsiveComponent(svg, tooltip, props) {
            const { width, height, margin, code, runways, easting, northing, landing, alerts } = props;
            
            console.log('responsiveComponent', code, runways, easting, northing)

            console.log('alerts', alerts)
            const pointsBeforeRunway = 1
           
            let alertsMap = new Map(); // maps an index (point on the runway) to an array of alert messages. 
            if (alerts && alerts.length > 0 && alerts[0].alert_points != null && alerts[0].alert_points.length > 0) {
                alerts.forEach(element => {
                // console.log('element', element)
                JSON.parse(element.alert_points).map((item) => {
                    // console.log('item', item)
                    let pt = item + pointsBeforeRunway
                    if (!alertsMap.has(pt)) {
                        alertsMap.set(pt, []);
                    }
                    alertsMap.get(pt).push(element.message + '\r\n'); // save this data point
                })
            });
            }
            // console.log('alertsMap', alertsMap)


            const image = new Image
            image.src = `/images/${code}.jpg`
            // console.log(image.src)
            image.onload = () => {

                const markerOuterColour = (data, index) => {
                    if (alertsMap.has(index)) {
                        return MARKER_FAIL_OUTER_COLOUR
                    } else {
                        return MARKER_PASS_OUTER_COLOUR
                    }
                }
   
                const markerInnerColour = (data, index) => {
                    if (alertsMap.has(index)) {
                        return MARKER_FAIL_INNER_COLOUR
                    } else {
                        return MARKER_PASS_INNER_COLOUR
                    }
                }

                const markerTitle = (index) => {
                    if (alertsMap.has(index)) {
                        const alertMessages = alertsMap.get(index)
                        let formattedMessages = ''

                        alertMessages.forEach((m) => {
                            formattedMessages += m 
                        })

                        return formattedMessages
                    } else {
                        return ''
                    }
                }

                // console.log('image loaded')
                svg.selectAll("*").remove();
                const g = svg.append('g')

                var img = g.selectAll("image").data([0]);
                img.enter()
                    .append("image")
                    .attr('xlink:href', `/images/${code}.jpg`)
                    .attr("x", "0")
                    .attr("y", "0")

                svg.call(zoom().extent([[0, 0], [image.width,image.height]]).scaleExtent([1,20]).translateExtent([[0, 0], [image.width, image.height]]).on('zoom', (ev) => {
                    xRef.current = ev.transform.x
                    yRef.current = ev.transform.y
                    kRef.current = ev.transform.k

                    g.attr('transform', ev.transform)
                }))

                const zoomed = (event) => {
                    var transform = event.transform;
                    xRef.current = transform.x
                    yRef.current = transform.y
                    kRef.current = transform.k

                    g.attr('transform', 'translate(' + transform.x + ', ' + transform.y + ') scale(' + transform.k + ')');
                }
                var myZoom = zoom().on('zoom', zoomed)

                // Zoom into the landing points
                if (landing && initRef.current === false) {
                    const pt1 = landing[0].utm.coordinates
                    const pt2 = landing[landing.length-1].utm.coordinates

                    const width = image.width
                    const height = image.height
    
                    const dx = Math.abs(pt1[0]-pt2[0])
                    const dy = Math.abs(pt1[1]-pt2[1])

                    const x = (pt1[0] + pt2[0]) / 2 - easting
                    const y = -((pt1[1] + pt2[1]) / 2 - northing)
    
                    const scale = Math.max(1, Math.min(20, .9 / Math.max(dx / width, dy / height)))

                    const translate = [width / 2 - scale * x, height / 2 - scale * y];

                    const t = zoomIdentity.translate(translate[0], translate[1]).scale(scale)
                    svg.call(myZoom.transform, t)
                    initRef.current = true
                } else if (landing && initRef.current === true) {
                    const t = zoomIdentity.translate(xRef.current, yRef.current).scale(kRef.current)
                    svg.call(myZoom.transform, t)
                }
    
                svg.attr('viewBox', `0 0 ${image.width} ${image.height}`)

                const paths = []

                // console.log('easting, northing', easting, northing)
                if (runways && runways.length > 0 && easting != undefined && northing != undefined) {
                    runways.forEach(rw => {
                        let runwayPath = path()
                        runwayPath.moveTo(rw.poly[0][0][0]-easting, -(rw.poly[0][0][1]-northing));
                        runwayPath.lineTo(rw.poly[0][1][0]-easting, -(rw.poly[0][1][1]-northing));
                        runwayPath.lineTo(rw.poly[0][2][0]-easting, -(rw.poly[0][2][1]-northing));
                        runwayPath.lineTo(rw.poly[0][3][0]-easting, -(rw.poly[0][3][1]-northing));
                        runwayPath.closePath();
    
                        paths.push(runwayPath)
                    });
    
                    // console.log(paths)
    
                    g.selectAll(".runway")
                    .data(paths)
                    .enter().append("path")
                    .attr("class", "runway")
                    .attr("d", (path, idx) =>  paths[idx])
                    .attr("fill", "#FFFD3E")
                    .attr("opacity", 0.5)
                    .append("svg:title")
                        .text(function(d, i) { return `Runway: ${runways[i].name}` });
                }

                // console.log('landing', landing)

                if (landing) {
                    var mouseover = function(e, d) {
                        const tt = markerTitle(d.idx)
                        if (tt.length > 0) {
                            tooltip.style("opacity", 1)
                        }
                    }
                    var mousemove = function(e, d) {
                        // console.log(e)
                        const tt = markerTitle(d.idx)
                        if (tt.length > 0) {
                            tooltip.style("left", (e.pageX+20) + "px")
                            tooltip.style("top", (e.pageY) + "px")
                            tooltip.html(tt)
                        }
                    }
                    var mouseleave = function(e, d) {
                        tooltip.style("left", "-100px")
                        tooltip.style("top", "-100px")
                        tooltip.style("opacity", 0)
                    }

                    // Remove turn around points
                    const filteredLanding = [];

                    let tempDistance = -10000;
                    landing.forEach((item, index) => {
                        const dist = parseInt(item.distance)
                        if ( dist >= tempDistance ) {
                            filteredLanding.push(item);
                            tempDistance = dist;
                        }
                    }) 

                    // draw the points
                    g.selectAll('.dots')
                        .data(filteredLanding)
                        .join("circle")
                        .attr('class', 'dots')
                        .attr('stroke', (d, i) => markerOuterColour(d, i) )
                        .attr('stroke-width','2')
                        .attr('r','6')
                        .attr('fill', (d, i) => markerInnerColour(d, i))
                        .attr('cx', (d) => d.utm.coordinates[0]-easting)
                        .attr('cy', (d) => -(d.utm.coordinates[1]-northing))
                        .attr('data', (d) => JSON.stringify(d.utm.coordinates))
                        .on("mouseover", (e, i) => mouseover(e, i))
                        .on("mousemove", (e, i) => mousemove(e, i))
                        .on("mouseleave", (e, i) => mouseleave(e, i))
                    
                }
                

                // select("#zoom_in").on("click", function() {
                //     console.log('zoom_in click function', scale)
                //     scale *= 2
                //     if (scale > 20) scale = 20
                //     svg.transition().call(myZoom.scaleTo, scale)
                // });
                // select("#zoom_out").on("click", function() {
                //     console.log('zoom_out click function', scale)
                //     scale *= 0.5
                //     if (scale < 1) scale = 1.0
                //     svg.transition().call(myZoom.scaleTo, scale)
                // });


            }
            image.onerror = () => {
                console.log('Removing image')
                svg.selectAll("*").remove();
            }
        }

        const RenderChart = () => {
            const svg = select(svgRef.current)
            const tooltip = select(tooltipRef.current)

            // console.log('Checking for Re-Render')
            const codeLowerCase = props.code.toLowerCase()
            // console.log(code, codeLowerCase)
            // console.log(runways, props.runways)
            // console.log(easting, props.utm[0])
            // console.log(northing, props.utm[1])
            // console.log(landing, props.landing)
            // console.log(props.alerts)

            if (codeLowerCase != code || props.runways != runways || props.utm[0] != easting || props.utm[1] != northing || props.landing != landing)
            {
                // console.log('Re-Render changes')
                setCode(codeLowerCase)
                setRunways(props.runways)
                setEasting(props.utm[0])
                setNorthing(props.utm[1])
                setLanding(props.landing)
                setAlerts(props.alerts)

                // if (props.landing.length > 0) {
                //     const landingId = props.landing[0].landingid

                // }
                
                responsiveComponent(svg, tooltip, Object.assign({}, chartTheme, {
                    // width: window.innerWidth,
                    // height: window.innerHeight,
                    code: codeLowerCase,
                    runways: props.runways,
                    easting: props.utm[0],
                    northing: props.utm[1],
                    landing: props.landing,
                    alerts: props.alerts
                }));
            }
        }

        RenderChart()

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        })

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }

    }, [props])

    return ( 
        <>
        {/* <Box style={{marginBottom: 10}}>
            <Button id="zoom_out" type="button" variant="contained" color="primary" onClick={zoomOut} style={{marginRight: 10}}>-</Button>
            <Button id="zoom_in" type="button" variant="contained" color="primary" onClick={zoomIn}>+</Button>
        </Box> */}

        <svg ref={svgRef} ></svg>
        <div className="mapMarkerTooltip" ref={tooltipRef}></div>
        </>
    );
}

export default AirportImage;
