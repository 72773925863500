import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max, path } from 'd3';
import AnalysisExport from '../components/AnalysisExport';
import { set } from 'date-fns';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const GroundSpeed = (props) => {
    const [speedDistanceData, setSpeedDistanceData] = useState(props.data)
    const [runwayLength, setRunwayLength] = useState(props.length)
    const [runwayWidth, setRunwayWidth] = useState(props.width)
    const [touchdown, setTouchdown] = useState(props.touchdown)
    // const glideSlopePosition=1000
    const [glideSlopeTouchdown, setGlideSlopeTouchdown] = useState(props.glideslope_touchdown)

    const svgRef = useRef()

    useEffect(() => {
        svgRef.current.innerHTML = ""
        setSpeedDistanceData(props.data)
        setRunwayLength(props.length)
        setRunwayWidth(props.width)
        setTouchdown(props.touchdown)
        setGlideSlopeTouchdown(props.glideslope_touchdown)
        
        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });


            const xScale = scaleLinear()
                .domain([0, runwayLength])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = 0 
            const yMax = 200 

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));

            
            // Yellow Zone Left
            const yellowzoneLeft = g.selectAll('.yellowzoneLeft').data([null]);
            yellowzoneLeft.enter().append('rect')
                .merge(yellowzoneLeft)
                .attr('class', 'yellowzoneLeft')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', xScale(glideSlopeTouchdown))
                .attr('height', innerHeight / 200 * 50)
                .attr('fill', '#FFFD3E');

            // Grey Zone
            const greyzone = g.selectAll('.greyzone').data([null]);
            greyzone.enter()
                .append('rect')
                .merge(greyzone)
                .attr('class', 'greyzone')
                .attr('x', 0)
                .attr('y', innerHeight / 200 * 50)
                .attr('width', innerWidth)
                .attr('height', innerHeight-50)
                .attr('fill', '#A6A6A6')

            // Yellow Zone Right
            const yellowZoneRightPath = path()
            yellowZoneRightPath.moveTo(innerWidth/2, innerHeight / 200 * 50);
            yellowZoneRightPath.lineTo(innerWidth, innerHeight / 200 * 50);
            yellowZoneRightPath.lineTo(innerWidth, innerHeight);
            yellowZoneRightPath.lineTo(innerWidth/2, innerHeight/200*130);
            yellowZoneRightPath.closePath();

            const yellowZoneRight = g.selectAll(".yellowzone-path").data([null])
            yellowZoneRight
                .enter().append("path")
                .attr("class", "yellowzone-path")
            .merge(yellowZoneRight)
                .attr("d", yellowZoneRightPath)
                .attr("fill", "#FFFD3E")


            // Center Line
            const centerLine = g.selectAll('.center-line').data([null])
            centerLine
                .enter().append("line")
                .attr("class", 'center-line')
            .merge(centerLine)
                .attr("x1", 10)
                .attr("y1", innerHeight / 200 * 130)
                .attr("x2", innerWidth / 2)
                .attr("y2", innerHeight / 200 * 130)
                .style("stroke-dasharray", ("30, 10"))
                .style("stroke", '#FFFFFF');            

            // Glide Slope Line
            const glideSlopeLine = g.selectAll('.glideslope-line').data([null])
            glideSlopeLine
                .enter().append("line")
                .attr("class", 'glideslope-line')
            .merge(glideSlopeLine)
                .attr("x1", xScale(glideSlopeTouchdown))
                .attr("y1", -20)
                .attr("x2", xScale(glideSlopeTouchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Label
            const glideSlopeTitle = g.selectAll('.glideslope-title').data([null])
            glideSlopeTitle
                .enter().append("text")
                .attr('class', 'glideslope-title')
            .merge(glideSlopeTitle)
                .attr('fill', '#368029')
                .attr("x", xScale(glideSlopeTouchdown) + 10)             
                .attr("y", -10)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .html("3&deg; Glide Slope TDP");

            // Touchdown Line
            const touchdownLine = g.selectAll('.touchdown-line').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-line')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdown))
                .attr("y1", 0)
                .attr("x2", xScale(touchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#338DCD')
                .attr("x", xScale(touchdown) + 10)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");
    

            // plot the line
            const lineGenerator = line()
                .x((value, index) => xScale(value.distance))
                .y((value, index) => yScale(value.speed))
                .curve(curveBasis)

            g.selectAll('.line')
                .data([speedDistanceData])
                .join('path')
                .attr('class', 'line')
                .attr('d', value => lineGenerator(value))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '1')

            // draw the points
            g.selectAll('.dots')
                .data(speedDistanceData)
                .join("circle")
                .attr('class', 'dots')
                .attr('stroke','black')
                .attr('r','2')
                .attr('fill','black')
                .attr('cx', (d) => xScale(d.distance))
                .attr('cy', (d) => yScale(d.speed))
                .append("svg:title")
                .text(function(d, i) { return `(${d.distance}, ${d.speed})` });

        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: props.width_auto ? (window.innerWidth / 2)-50 : window.innerWidth-20,
                height: props.height || 300,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Distance (ft)',
                yAxisLabel: 'Ground Speed (knots)',
                yAxisLabelFontSize: props.yAxisLabelFontSize || chartTheme.yAxisLabelFontSize,
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [props.data])

    return ( 
        <>
            { props.root && <AnalysisExport name={"GroundSpeed"} data={speedDistanceData} /> }
            <svg ref={svgRef}></svg>
        </>
    );
}

export default GroundSpeed;
