import { Grid, Paper, Typography, Box, Badge, Tooltip, LinearProgress, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import React, { useEffect } from "react";
import axios from "axios";


const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(5.4)
    },
    weatherItems: {
        width: '50%',
        textAlign: 'center'
    },
    weatherIcon: {
        position: 'relative',
        top: '4px',
        '& .wi': {
            color: 'black',
            fontSize: '23px',
            position: 'relative',
            top: '-3px'
        }
    },
}));

const icons =   {
    "rain": <i className="wi wi-showers"></i>,
    "snow": <AcUnitOutlinedIcon />,
    "sleet": <i className="wi wi-sleet"></i>,
    "clear": <WbSunnyOutlinedIcon />,
    "cloud": <CloudOutlinedIcon />,
    "hail": <i className="wi wi-hail"></i>
}

const conditionText =   {
    "rain": "Rainy",
    "snow": "Snow",
    "sleet": "Sleet",
    "clear": "Clear",
    "cloud": "Cloudy",
    "hail": "Hail"
}

const weatherSources = [
    "metar",
    "vaisala",
];

// const ToggleSwitch = withStyles({
//     switchBase: {
//       color: 'grey',
//       '& + $track': {
//         backgroundColor: 'grey',
//       },
//       '&$checked': {
//         color: 'grey',
//       },
//       '&$checked + $track': {
//         backgroundColor: 'grey',
//       },
//     },
//     checked: {},
//     track: {},
//   })(Switch);
  

export default function DashboardWeather({weather, code, vaisala}) {
    if ( weather === undefined ) {
        weather = {
            temperature: 0,
            dewpoint: 0,
            pressure: 0,
            windspeed_knots: 0,
            wind_direction: 0,
            condition: "clear"
        }
    }
    const classes       =   useStyles();

    const [response, setResponse] = React.useState(false);
    const [temperature, setTemperature] = React.useState(weather.temperature);
    const [dewpoint, setDewpoint] = React.useState(weather.dewpoint);
    const [pressure, setPressure] = React.useState(weather.pressure);
    const [windSpeed, setWindSpeed] = React.useState(weather.windspeed_knots);
    const [windDirection, setWindDirection] = React.useState(weather.wind_direction);
    const [condition, setCondition] = React.useState(weather.condition);
    const [loading, setLoading] = React.useState(false);
    const [vData, setVData] = React.useState(vaisala);

    const [source, setSource] = React.useState("metar");


    // useEffect(() => {
    //     if ( code === "CYOW" ) {
    //         /**
    //          * - fetch from vaisala data
    //          * - store (current date + 5 minutes) in local storage
    //          * - if (current date + 5 minutes) is greater than local storage date, fetch new data
    //          */
    //         const vaisalaData = JSON.parse(localStorage.getItem('vaisalaData'));
    //         const ts = new Date().toISOString();

    //         var needNewData = false;
    //         if ( vaisalaData === null ) {
    //             needNewData = true;
    //         } else if (vaisalaData.date <= new Date().getTime()) {
    //             needNewData = true;
    //         }

    //         if ( needNewData ) {
    //             axios.get(`${WEBAPI}/weather2/currentweather?code=${code}&ts=${ts}`)
    //             .then((res) => {
    //                 // setResponse(res.data);
    //                 // setTemperature(res.data.temperature);
    //                 // setDewpoint(res.data.dewpoint);
    //                 // setPressure(res.data.pressure);
    //                 // setWindSpeed(res.data.windspeed_knots);
    //                 // setWindDirection(res.data.wind_direction);
    //                 // setCondition(res.data.condition);
    //                 // setWeather(res.data)

    //                 setVData({
    //                     temperature: res.data.temperature,
    //                     dewpoint: res.data.dewpoint,
    //                     pressure: res.data.pressure,
    //                     windspeed_knots: res.data.windspeed_knots,
    //                     wind_direction: res.data.wind_direction,
    //                     condition: res.data.condition,
    //                 });

    //                 const localData = {...res.data, date: new Date().getTime() + 300000};
    //                 localStorage.setItem('vaisalaData', JSON.stringify(localData));

    //                 setLoading(false);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //         } else {
    //             setVData({
    //                 temperature: vaisalaData.temperature,
    //                 dewpoint: vaisalaData.dewpoint,
    //                 pressure: vaisalaData.pressure,
    //                 windspeed_knots: vaisalaData.windspeed_knots,
    //                 wind_direction: vaisalaData.wind_direction,
    //                 condition: vaisalaData.condition,
    //             });
    //             // setTemperature(vaisalaData.temperature);
    //             // setDewpoint(vaisalaData.dewpoint);
    //             // setPressure(vaisalaData.pressure);
    //             // setWindSpeed(vaisalaData.windspeed_knots);
    //             // setWindDirection(vaisalaData.wind_direction);
    //             // setCondition(vaisalaData.condition);
    //             setLoading(false);
    //             // setWeather(vaisalaData)
    //         }
    //     } else {
    //         setLoading(false);
    //     }
    // }, []);

    useEffect(() => {
        if ( source === "vaisala" ) {
            setTemperature(vaisala.temperature);
            setDewpoint(vaisala.dewpoint);
            setPressure(vaisala.pressure);
            setWindSpeed(vaisala.windspeed_knots);
            setWindDirection(vaisala.wind_direction);
            setCondition(vaisala.condition);
        } else {
            setTemperature(weather.temperature);
            setDewpoint(weather.dewpoint);
            setPressure(weather.pressure);
            setWindSpeed(weather.windspeed_knots);
            setWindDirection(weather.wind_direction);
            setCondition(weather.condition);
        }

        if (!vaisala) {
            setVData(false);
        } else {
            setVData(vaisala);
        }
    }, [source, weather, vaisala]);


    // function to check if the weather is within the 3-Kelvin Spread
    const is3kAlert = () => {
        if ( typeof weather === "undefined" ) return false;
        if (temperature === "" || dewpoint === "") return false;
        return temperature <= 3 && (temperature - dewpoint) <= 3;
    };

    const toggleDataSource = () => {
        // Only for CYOW
        if ( vaisala ) {
            return <Box textAlign="center">
                <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
                    {weatherSources.map((item, index) => {
                        return <Button key={index} onClick={() => setSource(item)} {...(source === item ? {color:"primary", variant: "contained"} : "" )}>{item}</Button>
                    })}
                </ButtonGroup>
            </Box>;
        } else {
            return <Box textAlign="center">
                <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
                    {["metar"].map((item, index) => {
                        return <Button key={index} onClick={() => setSource(item)} {...(source === item ? {color:"primary", variant: "contained"} : "" )}>{item}</Button>
                    })}
                </ButtonGroup>
            </Box>;
        }

        return;
    };

    if ( loading ) {
        return <Paper className={classes.widget}>
             <Typography variant="h6" >
                <Box textAlign="center">
                    CURRENT WEATHER
                </Box>
            </Typography> 
            <LinearProgress />
        </Paper>
    }

    return <Paper className={classes.widget}>
        <Typography variant="h6" >
            <Box textAlign="center">
                CURRENT WEATHER
            </Box>
        </Typography> 

        <br />
        {toggleDataSource()}        
        <br />


        <Typography variant="h6" >
            <Box textAlign="center">
                <span className={classes.weatherIcon}>{icons[condition]}</span> {conditionText[condition]}
            </Box>
        </Typography>

        <Grid container justifyContent="center" alignItems="center" spacing={4}>
            { temperature !== "" ? <Grid item className={classes.weatherItems}>
                
                <Typography variant="h4" >
                    {temperature}C
                </Typography>
                

                <Typography variant="body1" >
                    temperature
                </Typography>
            </Grid> : ""}

            {dewpoint !== "" ? <Grid item className={classes.weatherItems}>
                {is3kAlert() ? <Tooltip title="3-Kelvin Spread Alert" position="top">
                    <Badge badgeContent="3k" color="error" overlap="rectangular">
                        <Typography variant="h4" >
                            {dewpoint}C
                        </Typography>
                    </Badge>
                </Tooltip> :  <Typography variant="h4">
                            {dewpoint}C
                        </Typography>}

                <Typography variant="body1" >
                    dewpoint
                </Typography>
            </Grid> : ""}

            {windSpeed !== "" ?<Grid item className={classes.weatherItems}>
                <Typography variant="h4" >
                    {windSpeed}kt
                </Typography>

                <Typography variant="body1" >
                    windspeed
                </Typography>
            </Grid> : ""}

            {windDirection !== "" ? <Grid item className={classes.weatherItems}>
                <Typography variant="h4" >
                    {windDirection}°
                </Typography>

                <Typography variant="body1" >
                    wind direction
                </Typography>
            </Grid> : ""}

            {pressure ? <Grid item className={classes.weatherItems}>
                <Typography variant="h4" >
                    {pressure}
                </Typography>

                <Typography variant="body1" >
                    pressure (in. hg)
                </Typography>
            </Grid> : ""}
        </Grid>
    </Paper>
}