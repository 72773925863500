import React, { useState, useEffect } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from 'react-redux'
// import { setAirportCode } from '../redux/airportCodeSlice'
import Paper from "@material-ui/core/Paper";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  activetext: {
    margin: theme.spacing(1),
  }
}));

const validationSchema = yup.object({
  runwaynumber: yup.string().notOneOf(["00"], "Runway is required"),
  length_ft: yup
    .number()
    .required("Length is required")
    .positive("Length cannot be negative")
    .integer(),
  width_ft: yup
    .number()
    .required("Width is required")
    .positive("Width cannot be negative")
    .integer(),
});

export default function Runway() {
  const [airports, setAirports] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const airportCode = useSelector((state) => state.airportCode.value)

  useEffect(() => {
    if (id === undefined) {
      axios
        .get(`${WEBAPI}/airport`)
        .then((res) => {
          console.log(res.data);
          setAirports(res.data);
          // setCode(res.data.code);
          // setName(res.data.airportname);
          // setElevation(res.data.elevation);
          // setLat(res.data.lat);
          // setLon(res.data.lon);
          // setPingStationId(res.data.pingstationid)
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      axios
        .get(`${WEBAPI}/runway/${id}`)
        .then((res) => {
          console.log(res.data);
          setAirports([res.data.airport]);

          formik.setFieldValue("airport", res.data.airport.code, false);

          const runwayname = res.data.runwayname;
          const runwaynumber = runwayname.substring(0, 2);
          // console.log(runwaynumber)
          let runwaylrc = formik.values.runwaylrc;
          if (runwayname.length === 7) {
            // L/R/C
            runwaylrc = runwayname.substring(2, 3);
            formik.setFieldValue("runwaylrc", runwaylrc, true);
          }
          // console.log(runwaylrc, formik.values.runwaylrc)

          formik.setFieldValue("runwaynumber", runwaynumber, false);

          updateRunwayName(runwaynumber, runwaylrc);

          formik.setFieldValue("length_ft", res.data.length_ft, false);
          formik.setFieldValue("width_ft", res.data.width_ft, false);
          formik.setFieldValue("active", res.data.active, false);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, []);

  const formik = useFormik({
    // validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      airport: airportCode,
      runwaynumber: "00",
      runwaylrc: "None",
      runwayname: "",
      length_ft: "",
      width_ft: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values);
console.log('id', id)
      if (id === undefined) {
        axios
          .post(`${WEBAPI}/runway`, {
            airportcode: values.airport,
            runwayname: values.runwayname,
            length_ft: values.length_ft,
            width_ft: values.width_ft,
            active: values.active,
          })
          .then((res) => {
            history.push("/runways");
            setSubmitting(false);
          })
          .catch((err) => {
            // console.log(err);
            setSubmitting(false);
          });
      } else {
        axios
          .patch(`${WEBAPI}/runway`, {
            id: id,
            runwayname: values.runwayname,
            length_ft: values.length_ft,
            width_ft: values.width_ft,
            active: values.active,
          })
          .then((res) => {
            history.push("/runways");
            // setSubmitting(false);
          })
          .catch((err) => {
            // console.log(err);
            setSubmitting(false);
          });
      }

      // setSubmitting(false);
    },
  });

  const updateRunwayName = (runwayNum, lrc) => {
    if (runwayNum === "00") {
      formik.setFieldValue("runwayname", "", false);
      return;
    }

    let oppositeDirection = 18 + parseInt(runwayNum);
    let runwayName = runwayNum;

    if (lrc === "None") {
      runwayName += "/";
      runwayName += oppositeDirection;
    } else {
      runwayName += lrc + "/";
      runwayName += oppositeDirection;

      switch (lrc) {
        case "L":
          runwayName += "R";
          break;
        case "R":
          runwayName += "L";
          break;
        case "C":
          runwayName += "C";
          break;
        default:
          break;
      }
    }

    formik.setFieldValue("runwayname", runwayName, false);
  };

  const handleRunwayNumberChange = (e) => {
    formik.handleChange(e);
    updateRunwayName(e.target.value, formik.values.runwaylrc);
  };

  const handleRunwayLRCChange = (e) => {
    formik.handleChange(e);
    updateRunwayName(formik.values.runwaynumber, e.target.value);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit} >

          <Grid container spacing={1}>

            <Grid item xs={12} md={4}> 
              <Typography component="h1" variant="h5">Runway</Typography>

                <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                // className={classes.formControl}
                size={"small"} >

                <InputLabel>Airport</InputLabel>
                <Select
                  value={formik.values.airport}
                  onChange={formik.handleChange}
                  id="airport"
                  label="Airport"
                  name="airport"
                  autoFocus
                >
                  {airports?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.code}>
                        {item.code} - {item.airportname}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} >
              <FormControl
                variant="outlined"
                style={{marginRight: '20px'}}
                margin="normal"
                size={"small"}
              >
                <InputLabel>Runway</InputLabel>
                <Select
                  value={formik.values.runwaynumber}
                  onChange={(e) => handleRunwayNumberChange(e)}
                  onBlur={formik.handleBlur}
                  id="runwaynumber"
                  label="Runway"
                  name="runwaynumber"
                  error={
                    formik.touched.runwaynumber &&
                    Boolean(formik.errors.runwaynumber)
                  }
                >
                  <MenuItem value={"00"}></MenuItem>
                  <MenuItem value={"01"}>01</MenuItem>
                  <MenuItem value={"02"}>02</MenuItem>
                  <MenuItem value={"03"}>03</MenuItem>
                  <MenuItem value={"04"}>04</MenuItem>
                  <MenuItem value={"05"}>05</MenuItem>
                  <MenuItem value={"06"}>06</MenuItem>
                  <MenuItem value={"07"}>07</MenuItem>
                  <MenuItem value={"08"}>08</MenuItem>
                  <MenuItem value={"09"}>09</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"11"}>11</MenuItem>
                  <MenuItem value={"12"}>12</MenuItem>
                  <MenuItem value={"13"}>13</MenuItem>
                  <MenuItem value={"14"}>14</MenuItem>
                  <MenuItem value={"15"}>15</MenuItem>
                  <MenuItem value={"16"}>16</MenuItem>
                  <MenuItem value={"17"}>17</MenuItem>
                  <MenuItem value={"18"}>18</MenuItem>
                </Select>
                {formik.errors.runwaynumber && formik.touched.runwaynumber ? (
                  <FormHelperText className={classes.errorMessage}>
                    {formik.errors.runwaynumber}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl
                variant="outlined"
                margin="normal"
                size={"small"}
                style={{marginRight: '10px'}}
              >
                <InputLabel>L/R/C</InputLabel>
                <Select
                  value={formik.values.runwaylrc}
                  onChange={(e) => handleRunwayLRCChange(e)}
                  id="runwaylrc"
                  label="L/R/C"
                  name="runwaylrc"
                >
                  <MenuItem value={"None"}>None</MenuItem>
                  <MenuItem value={"L"}>L</MenuItem>
                  <MenuItem value={"R"}>R</MenuItem>
                  <MenuItem value={"C"}>C</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                margin="normal"
                size={"small"}
              >
                <TextField
                  tabIndex={0}
                  size={"small"}
                  value={formik.values.runwayname}
                  onChange={formik.handleChange}
                  id="runwayname"
                  label="Runway Name"
                  name="runwaylrc"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            <FormControl
                variant="outlined"
                margin="normal"
                style={{marginRight: '10px'}}
                size={"small"}
              >
              <TextField
                size="small"
                className={classes.formControl}
                value={formik.values.length_ft}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.length_ft && Boolean(formik.errors.length_ft)
                }
                helperText={formik.touched.length_ft && formik.errors.length_ft}
                variant="outlined"
                type="number"
                required
                name="length_ft"
                label="Runway length (feet)"
                id="length_ft"
                autoComplete=""
              />
              </FormControl>

              <FormControl
                variant="outlined"
                margin="normal"
                size={"small"}
              >
              <TextField
                size="small"
                className={classes.formControl}
                value={formik.values.width_ft}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.width_ft && Boolean(formik.errors.width_ft)
                }
                helperText={formik.touched.width_ft && formik.errors.width_ft}
                variant="outlined"
                type="number"
                required
                name="width_ft"
                label="Runway width (feet)"
                id="width_ft"
                autoComplete=""
              />
              </FormControl>

            </Grid>


            <Grid item xs={12}>
              <FormControlLabel
                className={classes.formControl}
                control={
                  <Checkbox
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.submit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>

        </Paper>
    </div>



  );
}
