import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
// import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
// import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from 'react-router-dom';
import * as axios from 'axios'
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
// import { useSelector, useDispatch } from 'react-redux'
// import { setLandingDate } from '../redux/landingDateSlice'
// import { setAirportCode } from '../redux/airportCodeSlice'
// import { format } from 'date-fns'
import { Button } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from "@material-ui/core";

const WEBAPI = process.env.REACT_APP_WEB_API;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  { id: 'ts', numeric: true, disablePadding: false, label: 'ts' },
  { id: 'sequencenumber', numeric: true, disablePadding: false, label: 'Sequence' },
  { id: 'latdd', numeric: true, disablePadding: false, label: 'Latitude' },
  { id: 'londd', numeric: true, disablePadding: false, label: 'Longitude' },
  { id: 'altitudemm', numeric: true, disablePadding: false, label: 'Altitude (mm)' },
  { id: 'headingde2', numeric: true, disablePadding: false, label: 'Heading (centi-degrees)' },
  { id: 'horvelocitycms', numeric: true, disablePadding: false, label: 'Hor Velocity (cm/s)' },
  { id: 'vervelocitycms', numeric: true, disablePadding: false, label: 'Ver Velocity (cm/s)' },
  { id: 'timeofreceptions', numeric: true, disablePadding: false, label: 'TimeOfReceptionS' },
  { id: 'timeofreceptionns', numeric: true, disablePadding: false, label: 'TimeOfReceptionNS' },
  { id: 'emittertype', numeric: true, disablePadding: false, label: 'EmitterType' },
  { id: 'navintegrity', numeric: true, disablePadding: false, label: 'navIntegrity' },
  { id: 'jsondata', numeric: true, disablePadding: false, label: 'JSON' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox className={classes.headerCheckbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all airports' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, callsign, data } = props;
  // const history = useHistory();

  // const handleDelete = (id) => {
  //   console.log('handleDelete', id);
  // }

  // const handleMapClick = (id) => {
  //   //console.log('handleEdit', id);
  //   history.push(`/landing/${id}`)
  // }

  const handleExport = () => {
    let content = ""
    headCells.map((item) => {
      return content += item.label + ","
    })
    content += "\r\n"

    data.map((item) => {
      let row = ""
      for (let idx=0; idx<headCells.length; idx++)
      {
        row += item[headCells[idx].id] + ","
      }

      row += "\r\n"

      return content += row
    })
    
    var link = document.createElement('a');
    link.download = callsign?.trim() + '_RAW.csv';
    link.href = 'data:,' + content;
    link.click();
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Landing Data (RAW) for {callsign}
            </Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" startIcon={<GetAppOutlinedIcon />} color="primary" onClick={handleExport} >
              Export
            </Button>
          </Grid>

        </Grid>
      )}

      {/* {numSelected > 0 ? (
        <>
          <IconButton 
            title="Map"
            className={classes.iconButton}
            onClick={() => handleMapClick(id)}>
            <MapOutlinedIcon />
          </IconButton>

      </>
    ) : null} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  callsign: PropTypes.string.isRequired,
  landingrunway: PropTypes.string.isRequired,
  // data: PropTypes.arrayOf(PropTypes.array)
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.info.main
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },

}));

export default function LandingDataRaw() {
  const classes = useStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(() => {
    const rows = localStorage.getItem('landingdataraw_rowsPerPage');
    if (rows) {
      return parseInt(rows);
    } else {
      return 5;
    }
  });
  const [rows, setRows] = React.useState([]);
  const [callsign, setCallsign] = React.useState('')
  const [landingRunway, setLandingRunway] = React.useState('')
  const { id } = useParams()

  useEffect(() => {
    console.log('landingID: ', id)
    if (id !== undefined) {
      axios.get(`${WEBAPI}/landingdataraw/${id}`)
      .then((res) => {
          const d = res.data
          console.log(d)

          setCallsign(d[0].callsign?.trim())
          setLandingRunway(d[0].landingrunway)

          if (d.length === 0) {
            setRows([])
          } else {
            setRows(d)
          }
        })
      .catch((err) => {
        console.log(err);
      })
    }

  }, [id]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem('landingdataraw_rowsPerPage', event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
        {
        rows.length>0 ? 
        <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} id={selected[0]} handleDelete={null} callsign={callsign} landingrunway={landingRunway} data={rows} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {
                (rowsPerPage > 0
                  ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : stableSort(rows, getComparator(order, orderBy))
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >

                    <TableCell align="right">{row.ts}</TableCell>
                    <TableCell align="right">{row.sequencenumber}</TableCell>
                    <TableCell align="right">{row.latdd}</TableCell>
                    <TableCell align="right">{row.londd}</TableCell>
                    <TableCell align="right">{row.altitudemm}</TableCell>
                    <TableCell align="right">{row.headingde2}</TableCell>
                    <TableCell align="right">{row.horvelocitycms}</TableCell>
                    <TableCell align="right">{row.vervelocitycms}</TableCell>
                    <TableCell align="right">{row.timeofreceptions}</TableCell>
                    <TableCell align="right">{row.timeofreceptionns}</TableCell>
                    <TableCell align="right">{row.emittertype}</TableCell>
                    <TableCell align="right">{row.navintegrity}</TableCell>
                    <TableCell align="right">{row.jsondata}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, { label: 'All', value: -1 }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </Paper>
        :
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress color="secondary" />
        </Box>
        }
    </div>
  );
}
