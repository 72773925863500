import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core";
import Appbar from "./components/Appbar";
// import Home from "./components/Home";
import Login from "./components/Login";
import Airport from "./components/Airport";
import Airports from "./components/Airports";
import Runway from "./components/Runway";
import Runways from "./components/Runways";
import Sandbox from "./components/Sandbox";
// import Map from "./components/Map";
import AirportImage from "./charts/AirportImage";
import Landings from "./components/Landings";
import Landing from "./components/Landing";
import Landing2 from "./components/Landing2";
import LandingData from "./components/LandingData";
import LandingDataRaw from "./components/LandingDataRaw";
import Analysis from "./components/Analysis";
import PingStation from "./components/PingStation";
import PingStations from "./components/PingStations";
import Authorities from "./components/Authorities";
import Authority from "./components/Authority";
import User from "./components/User";
import Users from "./components/Users";
import Alerts from "./components/Alerts";
import Alert from "./components/Alert";
import AirportAlerts from "./components/AirportAlerts";
import AirportAlert from "./components/AirportAlert";
import LoginPassword from "./components/LoginPassword";
import LoginPasswordNew from "./components/LoginPasswordNew";
// import jwt from "jwt-decode";
import * as axios from "axios";
// import { setRoot } from "./redux/rootSlice";
import { useSelector } from "react-redux";
import Search from "./components/Search";
import LandingAlerts from "./components/LandingAlerts";
import AppBar2 from "./components/Appbar2";
import LoginPasswordCode from "./components/LoginPasswordCode";
import Logs from "./components/Logs";
import Dashboard from "./components/Dashboard";
import ReportDistribution from "./components/ReportDistribution";
import ReportDistributionNew from "./components/ReportDistributionNew";
import ReportDistributionEdit from "./components/ReportDistributionEdit";
import Account from "./components/Account";
import Version from "./components/Version";
import Settings from "./components/Settings";
import { TestMap } from "./components/TestMap";
import DashboardNew from "./components/DashboardNew";

// seems like as a good a place as any to request notifications
// Notification.requestPermission()

// Request Interceptor for API calls
axios.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json;charset=utf-8";

  const accessToken = localStorage.getItem("token");
  if (accessToken) config.headers.Authorization = "Bearer " + accessToken;

  return config;
});

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/login";
    }
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B305C",
    },
    secondary: {
      main: "#FCBB18",
    },
  },
});

function App() {
  const authenticated = useSelector((state) => state.authenticated.value);
  const role = useSelector((state) => state.role.value);
  const root = useSelector((state) => state.root.value);

  useEffect(() => {
    // console.log('App', authenticated)
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Appbar />
        {/* <AppBar2 /> */}

        <Switch>
          <Route exact path="/">
            {authenticated ? <DashboardNew /> : <Login />}
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/loginpassword">
            <LoginPassword />
          </Route>
          <Route exact path="/loginpasswordnew">
            <LoginPasswordNew />
          </Route>
          <Route exact path="/loginpasswordcode">
            <LoginPasswordCode />
          </Route>

          {authenticated && 
            <Route exact path="/landings">
              <Landings />
            </Route>
          }

          {authenticated && 
            <Route exact path="/search">
              <Search />
            </Route>
          }

          {authenticated &&
            <Route exact path="/landing/:id?">
              <Landing />
            </Route>
          }
          {authenticated &&
            <Route exact path="/landing2/:id?">
              <Landing2 />
            </Route>
          }
          {authenticated && 
            <Route exact path="/analysis/:id?">
              <Analysis />
            </Route>
          }
          {authenticated && 
            <Route exact path="/landingalerts/:id">
              <LandingAlerts />
            </Route>
          }

          {authenticated && 
            <Route exact path="/account">
              <Account />
            </Route>
          }

          {(root || (role === "admin" || role === "sales")) && 
            <Route exact path="/users">
              <Users />
            </Route>
          }

          {(root || (role === "admin")) && 
            <Route exact path="/user/:id?">
              <User />
            </Route>
          }
          
          {(root || (role === "admin" || role === "sales")) && 
            <Route exact path="/alerts">
              <Alerts />
            </Route>
          }

          {(root || (role === "admin")) && 
            <Route exact path="/alert/:id?">
              <Alert />
            </Route>
          }

          {(root || (role === "admin" || role === "sales")) && 
            <Route exact path="/airportalerts">
              <AirportAlerts />
            </Route>
          }

          {(root || (role === "admin")) && 
            <Route exact path="/airportalert/:id?">
              <AirportAlert />
            </Route>
          }

          {root && 
            <Route exact path="/landingdata/:id">
              <LandingData />
            </Route>
          }

          {root && 
            <Route exact path="/landingdataraw/:id">
              <LandingDataRaw />
            </Route>
          }
          {root && 
            <Route exact path="/authorities">
              <Authorities />
            </Route>
          }
          {root && 
            <Route exact path="/authority/:id?">
              <Authority />
            </Route>
          }
          {root && 
            <Route exact path="/airports">
              <Airports />
            </Route>
          }

          {root && 
            <Route exact path="/airport/:id?">
              <Airport />
            </Route>
          }
          {root && 
            <Route exact path="/runways">
              <Runways />
            </Route>
          }
          {root && 
            <Route exact path="/runway/:id?">
              <Runway />
            </Route>
          }

          {root && 
            <Route exact path="/pingstation/:id?">
              <PingStation />
            </Route>
          }

          {root && 
            <Route exact path="/pingstations">
              <PingStations />
            </Route>
          }

          {root && 
            <Route exact path="/sandbox">
              <Sandbox />
            </Route>
          }

          {root && 
            <Route exact path="/airportimage">
              <AirportImage />
            </Route>
          }

          {root && 
            <Route exact path="/settings">
              <Settings />
            </Route>
          }

          {authenticated && 
            <Route exact path="/dashboardold">
              <Dashboard />
            </Route>
          }

          {authenticated && 
            <Route exact path="/dashboard">
              <DashboardNew />
            </Route>
          }

          {authenticated && 
            <Route exact path="/reports">
              <ReportDistribution />
            </Route>
          }

          {authenticated && 
            <Route exact path="/reports/new">
              <ReportDistributionNew />
            </Route>
          }

          {authenticated && 
            <Route exact path="/reports/edit/:id">
              <ReportDistributionEdit />
            </Route>
          }

          {
            <Route exact path="/version">
              <Version />
            </Route>
          }
          {authenticated &&
            <Route exact path="/testmap">
              <TestMap />
            </Route>
          }
          {/* {root && 
            <Route exact path="/logs">
              <Logs />
            </Route>
          } */}

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
