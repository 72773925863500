import { createSlice } from '@reduxjs/toolkit'

export const landingDateSlice = createSlice({
  name: 'landingDate',
  initialState: {
    value: new Date().toDateString(),
  },
  reducers: {
    setLandingDate: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setLandingDate } = landingDateSlice.actions
export default landingDateSlice.reducer
