import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import jwt from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import { setRoot } from "../redux/rootSlice";
import { setAuthenticated } from "../redux/authenticatedSlice";
import { setAuthorityId } from "../redux/authoritySlice";
import { setFirstName } from "../redux/firstNameSlice";
import { setAirportCode } from "../redux/airportCodeSlice";
import { setRole } from "../redux/roleSlice";
import { IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import MaintenanceBanner from './MaintenanceBanner';

const TEAM_EAGLE = process.env.REACT_APP_TEAM_EAGLE_AUTHORITY_NAME;
const DEFAULT_AIRPORT = process.env.REACT_APP_DEFAULT_AIRPORT;


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export default function ButtonAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMenu = Boolean(menuAnchorEl);
  const dispatch = useDispatch()
  const root = useSelector((state) => state.root.value)
  const authenticated = useSelector((state) => state.authenticated.value)
  const role = useSelector((state) => state.role.value)
  const currentAirportCode = useSelector((state) => state.airportCode.value)
  const [width, setWidth] = React.useState(window.innerWidth);
 
  useEffect(() => {
    // console.log('Processing Token')

    const token = localStorage.getItem('token')
    if (token != null) {
      const decodedToken = jwt(token)
      // console.log(decodedToken)

      dispatch(setRoot(decodedToken.authorityName === TEAM_EAGLE))
      const role = decodedToken.airportRoles.length > 0 ? decodedToken.airportRoles[0].role : ''
      dispatch(setRole(role))
      dispatch(setAuthenticated(true))
      dispatch(setAuthorityId(decodedToken.authorityId))
      dispatch(setFirstName(decodedToken.fname))
      if ( decodedToken.authorityName === TEAM_EAGLE ) {
        // do nothing
      } else {
        const airportCode = decodedToken.airportRoles.length === 1 && role !== 'none' ? decodedToken.airportRoles[0].airport.code : (currentAirportCode ? currentAirportCode : DEFAULT_AIRPORT)
        dispatch(setAirportCode(airportCode))
      }

    }

    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  // const handleChange = (event) => {
  //   // setAuth(event.target.checked);
  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const handleAirports = () => {
    setAnchorEl(null);
    history.push('/airports')
  };

  const handleRunways = () => {
    setAnchorEl(null);
    history.push('/runways')
  };

  const handlePingStations = () => {
    setAnchorEl(null);
    history.push('/pingstations')
  };

  const handleAuthorities = () => {
    setAnchorEl(null);
    history.push('/authorities')
  };

  const handleUsers = () => {
    setAnchorEl(null);
    history.push('/users')
  };

  const handleAlerts = () => {
    setAnchorEl(null);
    history.push('/alerts')
  };

  const handleAirportAlerts = () => {
    setAnchorEl(null);
    history.push('/airportalerts')
  };

  const handleSearch = () => {
    setAnchorEl(null);
    history.push('/search')
  };

  const handleSchedule = () => {
    setAnchorEl(null);
    history.push('/reports')
  };

  const handleLogs = () => {
    setAnchorEl(null);
    history.push('/logs')
  };

  const handleSettings = () => {
    setAnchorEl(null);
    history.push('/settings')
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem('token')
    dispatch(setAuthenticated(false))
    dispatch(setRoot(false))
    history.push('/login')
  };

  const handleAccount = () => {
    setAnchorEl(null);
    history.push('/account')
  };

  const isMobile = width <= 640;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <MaintenanceBanner />
        <Toolbar variant="dense">
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title} onClick={()=> history.push('/')} >
            ADEW
          </Typography>

          {
            authenticated && !isMobile ? 
            <>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
            <Button color="inherit" onClick={() => history.push('/dashboard')}>Dashboard</Button>
            <Button color="inherit" onClick={() => history.push('/landings')}>Landings</Button>
            {role === 'operator' ? <Button color="inherit" onClick={() => history.push('/search')}>Search</Button> : null}
            </>
            :
            null
            // <Button color="inherit" onClick={() => history.push('/login')}>Login</Button>
          }

          {
            authenticated && isMobile ? 
            <>
            <Button color="inherit" onClick={handleMobileMenu}>Menu</Button>
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              
              <MenuItem onClick={() => history.push('/dashboard')}>Dashboard</MenuItem>
              <MenuItem onClick={() => history.push('/landings')}>Landings</MenuItem>
              {role === 'operator' ? <MenuItem onClick={() => history.push('/search')}>Search</MenuItem> : null}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            </>
            :
            null
            // <Button color="inherit" onClick={() => history.push('/login')}>Login</Button>
          }

          {
            authenticated && root ? 
            <>
            <Button color="inherit" onClick={handleMenu}>Admin</Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSearch}>Search</MenuItem>
              <MenuItem onClick={handleSchedule}>Report Schedules</MenuItem>
              <MenuItem onClick={handleAuthorities}>Authorities</MenuItem>
              <MenuItem onClick={handleAirports}>Airports</MenuItem>
              <MenuItem onClick={handleAirportAlerts}>Airport Alert Conditions</MenuItem>
              <MenuItem onClick={handleAlerts}>Airport Alerts</MenuItem>
              <MenuItem onClick={handleUsers}>Users</MenuItem>
              <MenuItem onClick={handleRunways}>Runways</MenuItem>
              <MenuItem onClick={handlePingStations}>PingStations</MenuItem>
              <MenuItem onClick={handleSettings}>Settings</MenuItem>
              {/* <MenuItem onClick={handleLogs}>Log Files</MenuItem> */}
            </Menu>
            </>
              :
              authenticated && (role === 'admin' || role === 'sales') ?
              <>
                <Button color="inherit" onClick={handleMenu}>Admin</Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleSearch}>Search</MenuItem>
                  <MenuItem onClick={handleSchedule}>Report Schedules</MenuItem>
                  <MenuItem onClick={handleAirportAlerts}>Airport Alert Conditions</MenuItem>
                  <MenuItem onClick={handleAlerts}>Airport Alerts</MenuItem>
                  <MenuItem onClick={handleUsers}>Users</MenuItem>
                </Menu>
              </>
              :
              null
            }

            {
              authenticated ? <IconButton color="inherit" onClick={handleAccount}><AccountCircle  /></IconButton> : null
            }

        </Toolbar>
      </AppBar>
    </div>
  );
}
