import { Paper, Typography, Box, Tooltip, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InfoIcon from '@material-ui/icons/Info';

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    flightinfo: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '20px',
        curor: 'pointer'
    }
}));

function CustomProgressBar({start, end, code='theme'}) {
    var colors  =   {
        'red': {
            'background': '#E0E0E0',
            'progress': '#FF0000'
        },
        'green': {
            'background': '#E0E0E0',
            'progress': 'rgb(0 116 0)'
        },
        'theme': {
            'background': 'rgb(168, 176, 193)',
            'progress': '#1B305C'
        }
    }; 

    var colorCode   =   colors[code];
    return <div style={{position: 'relative', height: '4px', width: '100%'}}>
        <div style={{width:'100%', background: colorCode.background, height: '4px', display: 'table'}}></div>
        <div style={{position: 'absolute', top: '0', left: start+"%",right: (100-end)+'%', background: colorCode.progress, height: '4px'}}></div>
    </div>
}

const conditionMessages = {
    0: 'No anomaly detected',
    1: 'ADEW detected anomalies in the recent landings'
}

export default function LandingSummary({recentLanding, runway}) {
    const classes       =   useStyles();

    if ( recentLanding[runway] === undefined ) {
        return <Paper className={classes.widget}>
            <Box marginTop={1}>
                <Typography variant="body1" color="textSecondary">
                    No recent landings.
                </Typography>
            </Box>
        </Paper>
    }

    const runwayCondition = recentLanding[runway].slice(0, 5).filter((landing) => {
        return landing.landingalerts.length > 0;
    }).length > 0 ? 1 : 0;


    class ProcessLanding {
        constructor(landing) {
            let currentDateTime = new Date();
            this.landing    =   landing;
            this.time       =   new Date(landing.created);
            // Add date if the date is not today
            this.time       =   (currentDateTime.getDate() === this.time.getDate() ? '' : this.checkDigits(this.time.getDate()) + "/" + this.checkDigits(this.time.getMonth()+1) + "/" + this.time.getFullYear() + " ") +
                                this.checkDigits(this.time.getHours() > 12 ? this.time.getHours()%12 : this.time.getHours())
                                +":"+
                                this.checkDigits(this.time.getMinutes())
                                +(this.time.getHours() < 11 ? "AM" : "PM");

            this.alert      =   landing.landingalerts.length > 0 ? true : false;
            // this.runwaytime =   landing.landinganalysis === null || !'timeonrunway' in landing.landinganalysis ? 0 : landing.landinganalysis.timeonrunway;
            this.flightinfo =   "ICAO: "+landing.icaoaddress + " Callsign: " + landing.callsign; 
        }

        checkDigits(int) {
            return int < 10 ? "0"+int : int;
        }
    };

    var lastLanding    =   new ProcessLanding(recentLanding[runway][0]);
    return <Paper className={classes.widget}>
        <Typography variant="h6" >
            <Box textAlign="center">
                ADEW SUMMARY
            </Box>
        </Typography> 

        <Box display="flex" alignItems="center" marginTop={3} marginBottom={3}>
            <Box minWidth={70}>
                <Typography variant="body2" color="textSecondary">
                    {lastLanding.time}
                </Typography>
            </Box>
            <Box width="100%" mr={1}>
                <CustomProgressBar start={25} end={60} code={lastLanding.alert ? "red": "green"}/>
            </Box>
            <Box minWidth={35}>
                <Tooltip title={lastLanding.flightinfo}>
                    <InfoIcon className={classes.flightinfo} />
                </Tooltip>
            </Box>
        </Box>

        <Box mb={3} textAlign="center">
            <Typography variant="body1" >
                {conditionMessages[runwayCondition]}
            </Typography>
        </Box>

        <Typography variant="h6" >
            <Box textAlign="center">
                LAST 4 LANDINGS
            </Box>
        </Typography> 

        {recentLanding[runway].filter((x,i)=> i<5&&i>0).map((data, index) => {
            var landing =   new ProcessLanding(data);
            return <Box display="flex" alignItems="center" mt={index === 0 ? 3 : 1} mb={1} key={index}>
                <Box minWidth={70}>
                    <Typography variant="body2" color="textSecondary">
                        {landing.time}
                    </Typography>
                </Box>
                <Box width="100%" mr={1}>
                    <CustomProgressBar start={25} end={65} code={landing.alert ? "red": "green"}/>
                </Box>
                <Box minWidth={35}>
                    <Tooltip title={landing.flightinfo}>
                        <InfoIcon className={classes.flightinfo} />
                    </Tooltip>
                </Box>
            </Box>
        })}
        
    </Paper>
}