import { Paper, Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    }
}));



export default function RunwayTiming({recentLanding, runway, averages}) {
    const classes       =   useStyles();

    if ( recentLanding[runway] === undefined ) {
        return <Paper className={classes.widget}>
            <Box marginTop={1}>
                <Typography variant="body1" color="textSecondary">
                    No recent landings.
                </Typography>
            </Box>
        </Paper>
    }

    var analysis        =   recentLanding[runway][0]['landinganalysis'];
    var latestRunwayTime = analysis === null || !'timeonrunway' in analysis ? 0 : parseInt(analysis['timeonrunway']);
    var caclulateDiff =  latestRunwayTime - averages[runway];
    caclulateDiff = caclulateDiff > 0 ? "+"+caclulateDiff+"s more than" : ""+caclulateDiff+"s less than";

    function calculateAverage() {
        var total = 0;
        for ( var i = 0; i < recentLanding[runway].length; i++ ) {
            total += recentLanding[runway][i]['landinganalysis'] !== null ? parseInt(recentLanding[runway][i]['landinganalysis']['timeonrunway']) : 0;
        }

        return total / 10;
    }
    
    return <Paper className={classes.widget}>
        <Typography variant="h6" >
            <Box textAlign="center">
                TIME ON RUNWAY
            </Box>
        </Typography> 
        <Box marginTop={1}>
            <Box minWidth={70}>
                <Typography variant="body1" color="textSecondary">
                    Last Landing ({recentLanding[runway][0]['icaoaddress']})
                </Typography>
            </Box>
            <Box minWidth={35}>
                <Typography variant="h6">{latestRunwayTime}s</Typography>
                <Typography variant="caption" color="textSecondary"> {caclulateDiff} average runway time of landings of the same class.</Typography>
            </Box>
        </Box>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Box marginTop={2}>
                    <Box minWidth={70}>
                        <Typography variant="body1" color="textSecondary">
                            Last 10 Landing
                        </Typography>
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="h6">{calculateAverage()}s</Typography>
                    </Box>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}>

                <Box marginTop={2} marginBottom={1}>
                    <Box minWidth={70}>
                        <Typography variant="body1" color="textSecondary">
                            Average
                        </Typography>
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="h6">{averages[runway]}s</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Paper>
}