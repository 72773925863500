import React, { useState, useEffect } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
// import { setAirportCode } from "../redux/airportCodeSlice";
import Paper from "@material-ui/core/Paper";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  authorityid: yup.string('Select Authority').required('Authority is required'),
  fname: yup.string("Enter First Name").required("First Name is required"),
  lname: yup.string("Enter Last Name").required("Last Name is required"),
  email: yup.string("Enter Email").required("Email is required"),
  mobile: yup.string("Enter Mobile Number"),
});

export default function User() {
  const [airports, setAirports] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  // const airportCode = useSelector((state) => state.airportCode.value);
  const [authorityId, setAuthorityId] = useState('')
  const [authorities, setAuthorities] = useState([])
  const [maxUsersError, setMaxUsersError] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [secondary_mobile, setSecondaryMobile] = useState('')
  const [emailAlerts, setEmailAlerts] = useState(false)
  const [smsAlerts, setSmsAlerts] = useState(false)
  const [active, setActive] = useState(true)
  const root = useSelector((state) => state.root.value)


  useEffect(() => {
    axios.get(`${WEBAPI}/authority`)
    .then((res) => {
      // console.log(res.data)
      setAuthorities(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    console.log(`id, authorityId: |${id}|${location.state.authorityId}|`);

    setAuthorityId(location.state.authorityId)

    axios
    .get(`${WEBAPI}/authority/${location.state.authorityId}`)
    .then((res) => {
      // console.log(res.data);

      const airportArray = res.data.airports
      setAirports(airportArray);

      if (id === undefined) {
        // console.log('New user')
  
        const defaultRoles = airportArray.map((item, index)=> {
          return 'none'
        })
        formik.setFieldValue("roles", defaultRoles, false);
  
      } else {
        // console.log('Edit user', airportArray.length)
  
        axios.get(`${WEBAPI}/user/${id}`)
        .then((res) => {
          console.log('Data: ', res.data)
          setFirstName(res.data.fname)
          setLastName(res.data.lname)
          setEmail(res.data.email)
          setEmailAlerts(res.data.email_alerts)
          setSmsAlerts(res.data.sms_alerts)
          setActive(res.data.active)
          setMobile(res.data.mobile)
          setSecondaryMobile(res.data.secondary_mobile)
  
          // get the role for each airport
          const defaultRoles = airportArray.map((item, index)=> {
            // console.log(item)
            const airportUser = res.data.airportusers.find(element => element.airportid === item.id);
            // console.log('airportUser', airportUser)

            return airportUser.role
          })
          // console.log(defaultRoles)
          formik.setFieldValue("roles", defaultRoles, false);
        })
        .catch((err) => {
            console.log(err);
        })
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);

  const formik = useFormik({
    // validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      authorityid: authorityId,
      fname: firstName,
      lname: lastName,
      email: email,
      mobile: mobile,
      secondary_mobile: secondary_mobile,
      // password: "",
      email_alerts: emailAlerts,
      sms_alerts: smsAlerts,
      active: active,
      pwdreset: false,
      roles: []
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log(values);
      // console.log("authorityId", id);
      // console.log(airports)

      const airportRoles = []
      airports.map((item, index) => {
        airportRoles.push({airportId: item.id, role: formik.values.roles[index]})
        return item
      })
      // console.log(airportRoles)
      

      if (id === undefined) { // New
        axios
          .post(`${WEBAPI}/user`, {
            authorityid: authorityId,
            fname: values.fname,
            lname: values.lname,
            email: values.email,
            mobile: values.mobile,
            secondary_mobile: values.secondary_mobile,
            email_alerts: values.email_alerts,
            sms_alerts: values.sms_alerts,
            active: values.active,
            roles: airportRoles
          }, { validateStatus: function (status) { return status < 500; } })
          .then((res) => {
            console.log('409 received here.', res.status)
            if (res.status === 409) {
              // console.log('Max users exceeded.')
              setMaxUsersError(true)
            } else {
              history.push("/users");
            }
          })
          .catch((err) => {
            console.log('Error found:', err);
          });
      } else { // Edit
        axios
          .put(`${WEBAPI}/user`, {
            id: id,
            fname: values.fname,
            lname: values.lname,
            email: values.email,
            mobile: values.mobile,
            secondary_mobile: values.secondary_mobile,
            email_alerts: values.email_alerts,
            sms_alerts: values.sms_alerts,
            active: values.active,
            pwdreset: values.pwdreset,
            roles: airportRoles
          })
          .then((res) => {
            history.push("/users");
          })
          .catch((err) => {
            console.log(err);
          });
      }

      setSubmitting(false);
    },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>

          <Grid container spacing={2}>

              <Grid container item xs={12} md={4}>
                <Typography component="h1" variant="h5">User</Typography>

                {
                  root ?
                    <FormControl
                      disabled={authorityId !== undefined}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      // className={classes.formControl}
                      size={"small"}
                      required
                      error={formik.touched.authorityid && Boolean(formik.errors.authorityid)}
                      >
                      <InputLabel>Authority</InputLabel>
                      <Select
                        value={formik.values.authorityid}
                        onChange={formik.handleChange}
                        id="authorityid"
                        label="Authority"
                        name="authorityid"
                      >
                        {authorities?.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText style={{display: (formik.touched.authorityid && Boolean(formik.errors.authorityid) ? 'block' : 'none' ) }} >Authority is required</FormHelperText>
                    </FormControl>
                  :
                  null
                }

                <TextField
                  size="small"
                  value={formik.values.fname}
                  onChange={formik.handleChange}
                  error={formik.touched.fname && Boolean(formik.errors.fname)}
                  helperText={formik.touched.fname && formik.errors.fname}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="fname"
                  label="First Name"
                  name="fname"
                  autoComplete=""
                  autoFocus
                />

                <TextField
                  size="small"
                  value={formik.values.lname}
                  onChange={formik.handleChange}
                  error={formik.touched.lname && Boolean(formik.errors.lname)}
                  helperText={formik.touched.lname && formik.errors.lname}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lname"
                  label="Last Name"
                  name="lname"
                  autoComplete=""
                />

                <TextField
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete=""
                />

                <TextField
                  size="small"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="mobile"
                  label="Mobile #"
                  name="mobile"
                  autoComplete=""
                />

                <TextField
                  size="small"
                  value={formik.values.secondary_mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.secondary_mobile && Boolean(formik.errors.secondary_mobile)}
                  helperText={formik.touched.secondary_mobile && formik.errors.secondary_mobile}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="secondary_mobile"
                  label="Secondary Mobile #"
                  name="secondary_mobile"
                  autoComplete=""
                />

                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={formik.values.email_alerts}
                      onChange={formik.handleChange}
                      name="email_alerts"
                      color="primary"
                    />
                  }
                  label="Email Alerts"
                />

                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={formik.values.sms_alerts}
                      onChange={formik.handleChange}
                      name="sms_alerts"
                      color="primary"
                    />
                  }
                  label="SMS Alerts"
                />

                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={formik.handleChange}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />

                {
                  id === undefined ?
                  null
                  :
                  <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      checked={formik.values.pwdreset}
                      onChange={formik.handleChange}
                      name="pwdreset"
                      color="primary"
                    />
                  }
                  label="Password Reset"
                />
                }

              </Grid>

              <Grid container item xs={12} md={8} spacing={1}>
                <Grid item xs={8}  >
                  <Typography component="h1" variant="h5">Airport</Typography>

                  { 
                    airports?.map((item, index) => {
                        return (
                          <TextField
                            key={item.id}
                            size="small"
                            value={item.airportname}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                          />
                        )
                      }
                    )
                  }
                </Grid>

                <Grid item xs={4} >
                  <Typography component="h1" variant="h5">Role</Typography>

                  { 
                    airports?.map((item, index) => {
                        return (
                          <FormControl
                          key={item.id}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          size={"small"}
                          >
                          <InputLabel>Role</InputLabel>
                          <Select
                            value={formik.values.roles[index] ?? 'none' }
                            onChange={formik.handleChange}
                            label="Role"
                            name={`roles[${index}]`}
                          >
                            <MenuItem key='ADMIN' value='admin'>ADMIN</MenuItem>
                            <MenuItem key='OPERATOR' value='operator'>OPERATOR</MenuItem>
                            { root ? <MenuItem key='SALES' value='sales'>SALES</MenuItem> : null }
                            <MenuItem key='NONE' value='none'>NONE</MenuItem>
                          </Select>
                        </FormControl>
                      )
                      }
                    )
                  }

                </Grid>
              </Grid>

              <Grid container item xs={12} md={4}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                    className={classes.submit}
                  >
                    Save
                  </Button>

                  <FormHelperText style={{display: (maxUsersError ? 'block' : 'none' ), color: 'red', marginTop: 10, marginLeft: 10, fontSize: 20 }} >Max users exceeded.</FormHelperText>

              </Grid>
          </Grid>

        </form>
      </Paper>
    </div>
  );
}
