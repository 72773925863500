import React, { useState, useEffect } from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as axios from 'axios'
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormHelperText from '@material-ui/core/FormHelperText';

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));

const validationSchema = yup.object({
  name: yup
  .string('Enter the Authority name')
  .required('Authority name is required'),
  maxusers: yup
  .number('Enter the Maximum Users')
  .required('Maximum Users is required')
  .min(1).max(100)
  .positive()
  .integer()
});



export default function Authority() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams()
  const [name, setName] = useState('')
  const [maxUsers, setMaxUsers] = useState(10)
  const [mlVisible, setMlVisible] = useState(false)

  const root = useSelector(state => state.root.value)


  useEffect(() => {
    // axios.get(`${WEBAPI}/airport`)
    // .then((res) => {
    //   // console.log(res.data)
    //   setAirports(res.data);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }, []);


  useEffect(() => {
    console.log('id', id)

    if (id !== undefined) {
      axios.get(`${WEBAPI}/authority/${id}`)
      .then((res) => {
        console.log(res.data)
          setName(res.data.name)
          setMaxUsers(res.data.max_users)
          setMlVisible(res.data.dcml_visible)
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }, [id]);


  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: name,
        maxusers: maxUsers,
        dcml_visible: mlVisible
      },
      validationSchema: validationSchema,
      onSubmit: (values, {setSubmitting, resetForm} ) => {
        console.log('Submitting Form', values)

        if (id === undefined) {
          axios.post(`${WEBAPI}/authority`, { name: values.name, maxusers: values.maxusers, dcml_visible: values.dcml_visible })
              .then((res) => {
                setSubmitting(false)
                history.push('/authorities')
                })
              .catch((err) => {
                console.log(err);
                setSubmitting(false)
              })
        } else {
          axios.put(`${WEBAPI}/authority`, { id: id, name: values.name, maxusers: values.maxusers, dcml_visible: values.dcml_visible })
              .then((res) => {
                setSubmitting(false)
                history.push('/authorities')
                })
              .catch((err) => {
                  console.log(err);
                  setSubmitting(false)
              })
        }
      },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={ formik.handleSubmit }>
        <Grid container direction="column" alignItems="stretch" className={classes.grid} spacing={1}> 
          <Grid item xs={12}>
            <Typography component="h1" align="left" variant="h5" noWrap>
              Authority
            </Typography>
          </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Authority Name"
                name="name"
                autoComplete=""
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                value={formik.values.maxusers}
                onChange={formik.handleChange}
                error={formik.touched.maxusers && Boolean(formik.errors.maxusers)}
                helperText={formik.touched.maxusers && formik.errors.maxusers}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="maxusers"
                label="Max Users"
                name="maxusers"
                autoComplete=""
              />
            </Grid>

            {root && <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.dcml_visible}
                    onChange={formik.handleChange}
                    name="dcml_visible"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    label="DCML Visible"
                  />
                }
                label="Enable ML for everyone"
              />
            </Grid> }


            <Grid item xs={12} md={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.submit}
              >
                Save
              </Button>
            </Grid>


        </Grid>
        </form>
      </Paper>
    </div>

  )
}