import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Add, Delete, EditOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
        padding: window.innerWidth < 600 ? 0: theme.spacing(2)
    },
    table: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    }
}));

export default function ReportDistribution() {
    const classes = useStyles();
    
    const [reports, setReports] =   React.useState([])

    useEffect(() => {
        axios.get(`${WEBAPI}/report/schedule`)
        .then((res) => {
            setReports(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [])


    const handleDelete = (report_id, schedule_id) => {
        const params = {
            schedule_id,
        }
        axios.delete(`${WEBAPI}/report/delete`, {data: params})
        .then((res) => {
            setReports(res.data.reports);
        }).catch((err) => {
            console.log(err);
        })
    }

    const renderReports = () => {
        var rows = [];
        if ( reports.length > 0 ) {
            reports.map((report, i) => {
                return report.schedules.length > 0 ? report.schedules.map((schedule, j) => {
                    rows.push( <TableRow key={schedule.id}>
                        <TableCell padding="none">{schedule.name}</TableCell>
                        <TableCell padding="none">{new Date(schedule.next_run).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:  '2-digit', hour: '2-digit', minute:'2-digit', hour12: true  })}</TableCell>
                        <TableCell padding="none">
                            <IconButton component={Link} to={'/reports/edit/'+schedule.id}>
                                <EditOutlined />
                            </IconButton>

                            <IconButton onClick={() => handleDelete(report.id, schedule.id)}>
                                <Delete />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
                    return schedule;
                }) : null;
            })
        }

        return rows;
    }

    return (
        <div className={classes.root}>
            <div className={classes.paper}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h5">
                            Scheduled Reports
                        </Typography>
                    </Grid>

                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <Link to={'/reports/new'} style={{textDecoration: 'none'}}>
                            <Button variant="contained" color="primary">
                                <Add />
                                Add New
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                {/* Render All Schedules */}
                <Paper className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none">Schedule Name</TableCell>
                                <TableCell padding="none">Next Run</TableCell>
                                <TableCell padding="none">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {renderReports()}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        </div>
    );
}