import { Grid, Paper, Typography, Box, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// theme styles
const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    notamBox: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    notamBoxInner: {
        border: '2px solid #ccc',
        borderRight: '0',
        height: 68
    },
    colorBlue: {
        color: '#5275BF'
    },
    runwayLeft: {
        lineHeight: '2.1',
        transform: "rotate(90deg)"
    },
    runwayRight: {
        lineHeight: '2.1',
        transform: "rotate(-90deg)"
    },
}));

export default function DashboardNotam ({notam, runway, runways}) {
    const classes       =   useStyles();
    const currentRunway =   runways[runway].runwayname.split("/")
   
    if ( !(runway in notam) ) {
        return <Paper className={classes.widget}>
            <Typography variant="h6">
                <Box textAlign="center">
                    RSC NOTAM
                </Box>
            </Typography> 

            <Box spacing={4} textAlign="center">
                <Typography variant="body1" >
                    NOTAM not found or expired for this runway.
                </Typography>
            </Box>
        </Paper>
    }

    const isExpired     =   new Date(notam[runway].validTill).getTime() < new Date().getTime();
    const scores        =   [notam[runway].firstThirdScore, notam[runway].secondThirdScore, notam[runway].lastThirdScore];
    const [first, second, last] = notam[runway]['runwaynumber'] === currentRunway[0] ? scores: scores.reverse();
    

    return <Paper className={classes.widget}>
        <Typography variant="h6">
            <Box textAlign="center">
                {isExpired ? <Badge badgeContent={"expired"} color="primary" overlap={"rectangular"}>
                    RSC NOTAM
                </Badge>: "RSC NOTAM"}
            </Box>
        </Typography> 

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} className={classes.notamBox}>
            <Grid item className={classes.notamBoxInner} style={{border: "0"}}>
                <Typography variant="subtitle1" className={classes.runwayLeft}>
                    {currentRunway[0]}
                </Typography>
            </Grid>
            <Grid item className={classes.notamBoxInner}>
                <Typography variant="h6" className={classes.colorBlue}>
                    {first}
                </Typography>
            </Grid>
            <Grid item className={classes.notamBoxInner}>
                <Typography variant="h6" className={classes.colorBlue}>
                    {second}
                </Typography>
            </Grid>
            <Grid item className={classes.notamBoxInner} style={{borderRight: "2px solid #ccc"}}>
                <Typography variant="h6" className={classes.colorBlue}>
                    {last}
                </Typography>
            </Grid>
            <Grid item className={classes.notamBoxInner} style={{border: "0"}}>
                <Typography variant="subtitle1" className={classes.runwayRight}>
                    {currentRunway[1]}
                </Typography>
            </Grid>
        </Grid>

        <Typography variant="body1">
            {[notam[runway].firstThirdText, notam[runway].secondThirdText, notam[runway].lastThirdText].join(", ")}<br />
            VALID: {new Date(notam[runway].validFrom).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})} - {new Date(notam[runway].validTill).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})}
        </Typography>
    </Paper>
}