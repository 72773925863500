import { createSlice } from '@reduxjs/toolkit'

export const landingsCountSlice = createSlice({
  name: 'landingsCount',
  initialState: {
    value: 0,
  },
  reducers: {
    setLandingsCount: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setLandingsCount } = landingsCountSlice.actions
export default landingsCountSlice.reducer
