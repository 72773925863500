import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    notice: {
        backgroundColor: '#0072ff',
        textAlign: 'center',
    },
    noticeText: {
        marginTop: '9px',
        marginBottom: '9px',
    },
}));
const WEBAPI = process.env.REACT_APP_WEB_API;

const MaintenanceBanner = () => {
    const classes = useStyles();

    const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);
    const [maintenanceNotice, setMaintenanceNotice] = useState('');

    useEffect(() => {
        axios.get(`${WEBAPI}/setting/maintenance`).then((response) => {
            if (response.data) {
                setIsMaintenanceActive(response.data.is_maintance_mode_enabled === "true");
                setMaintenanceNotice(response.data.maintance_mode_message);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    console.log(isMaintenanceActive, maintenanceNotice);

    return (
        isMaintenanceActive && (
            <div className={classes.notice}>
                <p className={classes.noticeText}>{maintenanceNotice}</p>
            </div>
        )
    );
};

export default MaintenanceBanner;