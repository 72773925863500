import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import jwt from 'jwt-decode'
import axios from "axios";

const WEBAPI = process.env.REACT_APP_WEB_API;

export default function RestrictedMLAccess({children}) {
    const root = useSelector((state) => state.root.value);
    const role = useSelector((state) => state.role.value);

    const user = jwt(localStorage.getItem('token'))
    const authorityId = user.authorityId;

    const [isAllowedForML, setIsAllowedForML] = useState(false)

    useEffect(() => {
        if ( !authorityId ) {
            return;
        }

        axios.get(`${WEBAPI}/authority/${authorityId}`).then((res) => {
            console.log(res.data)
            if (res.data.dcml_visible) {
                setIsAllowedForML(true)
            }
        })
    }, [])

    // if no user is logged in we return ''
    // OR if ML is not allowed for this authority and the user is not root, we return ''
    if ( isAllowedForML ) {
        return children;
    } else if ( root ) {
        return children;
    }else {
        return '';
    }
}