import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max } from 'd3';
import { Box } from '@material-ui/core';
import AnalysisExport from '../components/AnalysisExport';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const DirectionalCompliance = (props) => {
    // Filter turn around data
    let initialDistance = -1000;
    const turnAroundData = [];
    
    props.data.forEach((item, index) => {
        if (item.x > initialDistance) {
            turnAroundData.push(item);
            initialDistance = item.x;
        }
    })


    const [data, setData] = useState(turnAroundData)
    const [touchdown, setTouchdown] = useState(props.touchdown)
    const [runwayLength, setRunwayLength] = useState(props.length)
    const [runwayWidth, setRunwayWidth] = useState(props.width)
    const [windDirection, setWindDirection] = useState(props.wind_direction)
    const [windSpeed, setWindSpeed] = useState(props.wind_speed)
    const [glideSlopeTouchdown, setGlideSlopeTouchdown] = useState(props.glideslope_touchdown)
    const [weatherSource, setWeatherSource] = useState(props.source)
    const svgRef = useRef()
    const goodZoneColour = '#80C373'
    const mediumZoneColour = '#FFFD3E'
    const poorZoneColour = '#FF3333'

    // update when weather source changes
    useEffect(() => {
        setWindSpeed(props.wind_speed)
        setWindDirection(props.wind_direction)
        setWeatherSource(props.source)
    }, [props])

    useEffect(() => {
        svgRef.current.innerHTML = ""
        setData(turnAroundData)
        setTouchdown(props.touchdown)
        setRunwayLength(props.length)
        setRunwayWidth(props.width)
        setGlideSlopeTouchdown(props.glideslope_touchdown)

        console.log('direction, speed', windDirection, windSpeed)
        // const totalElapsedTime = decelerationTimeData[decelerationTimeData.length-1].et
        // console.log('data: ', data)

        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });


            const xScale = scaleLinear()
                .domain([0, runwayLength])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = -30 //-runwayWidth/2
            const yMax = 30 //runwayWidth/2

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));





            // Grey Zone
            const greyzone = g.selectAll('.greyzone').data([null]);
            greyzone.enter()
                .append('rect')
                .merge(greyzone)
                .attr('class', 'greyzone')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', xScale(glideSlopeTouchdown))
                .attr('height', innerHeight)
                .attr('fill', '#A6A6A6')

            // size of bands in %
            const greenBandSize = 20/60*100  // 1 Green band
            const yellowBandSize = 10/60*100  // 2 Yellow bands
            const orangeBandSize = 10/60*100 // 2 Orange bands

            const greenBandHeight = innerHeight / 100 * greenBandSize
            const yellowBandHeight = innerHeight / 100 * yellowBandSize
            const orangeBandHeight = innerHeight / 100 * orangeBandSize

            // Poor Zone Top
            const poorzoneTop = g.selectAll('.poorzoneTop').data([null]);
            poorzoneTop.enter().append('rect')
                .merge(poorzoneTop)
                .attr('class', 'poorzoneTop')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', 0)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', orangeBandHeight)
                .attr('fill', poorZoneColour);

            // Medium Zone Top
            const mediumzoneTop = g.selectAll('.mediumzoneTop').data([null]);
            mediumzoneTop.enter().append('rect')
                .merge(mediumzoneTop)
                .attr('class', 'mediumzoneTop')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', orangeBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', yellowBandHeight)
                .attr('fill', mediumZoneColour);

            // Good Zone
            const goodzone = g.selectAll('.goodzone').data([null]);
            goodzone.enter().append('rect')
                .merge(goodzone)
                .attr('class', 'goodzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', orangeBandHeight + yellowBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', greenBandHeight)
                .attr('fill', goodZoneColour);

            // Medium Zone Bottom
            const mediumzoneBottom = g.selectAll('.mediumzoneBottom').data([null]);
            mediumzoneBottom.enter().append('rect')
                .merge(mediumzoneBottom)
                .attr('class', 'mediumzoneBottom')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', orangeBandHeight + yellowBandHeight + greenBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', yellowBandHeight)
                .attr('fill', mediumZoneColour);

            // Poor Zone Bottom
            const poorzoneBottom = g.selectAll('.poorzoneBottom').data([null]);
            poorzoneBottom.enter().append('rect')
                .merge(poorzoneBottom)
                .attr('class', 'poorzoneBottom')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', orangeBandHeight + yellowBandHeight + greenBandHeight + yellowBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', orangeBandHeight)
                .attr('fill', poorZoneColour);


            // Center Line
            const centerLine = g.selectAll('.center-line').data([null])
            centerLine
                .enter().append("line")
                .attr("class", 'center-line')
            .merge(centerLine)
                .attr("x1", 10)
                .attr("y1", innerHeight / 100 * 50)
                .attr("x2", innerWidth - 10)
                .attr("y2", innerHeight / 100 * 50)
                .style("stroke-dasharray", ("30, 10"))
                .style("stroke", '#FFFFFF');            

            // Glide Slope Line
            const glideSlopeLine = g.selectAll('.glideslope-line').data([null])
            glideSlopeLine
                .enter().append("line")
                .attr("class", 'glideslope-line')
            .merge(glideSlopeLine)
                .attr("x1", xScale(glideSlopeTouchdown))
                .attr("y1", -20)
                .attr("x2", xScale(glideSlopeTouchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Label
            const glideSlopeTitle = g.selectAll('.glideslope-title').data([null])
            glideSlopeTitle
                .enter().append("text")
                .attr('class', 'glideslope-title')
            .merge(glideSlopeTitle)
                .attr('fill', '#368029')
                .attr("x", xScale(glideSlopeTouchdown) + 10)             
                .attr("y", -10)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .html("3&deg; Glide Slope TDP");


            // Touchdown Line
            const touchdownLine = g.selectAll('.touchdown-line').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-line')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdown))
                .attr("y1", 0)
                .attr("x2", xScale(touchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#FFFFFF')
                .attr("x", xScale(touchdown) + 10)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");
    

            // // gridlines in x axis function
            // function make_x_gridlines() {		
            //     return axisBottom(xScale).ticks()
            // }

            // // gridlines in y axis function
            // function make_y_gridlines() {		
            //     return axisLeft(yScale).ticks()
            // }

            // // add the X gridlines
            // g.selectAll('.xgrid').remove()
            // g.append("g")
            // .attr("class", "xgrid")
            // .attr("transform", "translate(0," + innerHeight + ")")
            // .call(make_x_gridlines().tickSize(-innerHeight).tickFormat(""))

            // // add the Y gridlines
            // g.selectAll('.ygrid').remove()
            // g.append("g")			
            // .attr("class", "ygrid")
            // .call(make_y_gridlines().tickSize(-innerWidth).tickFormat(""))            




            // plot the line
            const lineGenerator = line()
                .x((value, index) => xScale(value.x))
                .y((value, index) => yScale(value.y))
                .curve(curveBasis)

            g.selectAll('.line')
                .data([data])
                .join('path')
                .attr('class', 'line')
                .attr('d', value => lineGenerator(value))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '2')

            // draw the points
            // g.selectAll('.dots')
            //     .data(data)
            //     .join("circle")
            //     .attr('class', 'dots')
            //     .attr('stroke','black')
            //     .attr('r','2')
            //     .attr('fill','black')
            //     .attr('cx', (d) => xScale(d.x))
            //     .attr('cy', (d) => yScale(d.y))

        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: props.width_auto ? (window.innerWidth / 2)-50 : window.innerWidth-20,
                height: props.height || 300,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Distance (ft)',
                yAxisLabel: 'Directional Compliance',
                yAxisLabelFontSize: props.yAxisLabelFontSize || chartTheme.yAxisLabelFontSize,
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [props.data])

    return ( 
        <>
        <div style={{'pageBreakBefore': 'always'}}>
            <Box style={{marginLeft: 100}}>
                Wind&nbsp;&nbsp;<i className={`wi wi-wind from-${windDirection}-deg`} title={windDirection}></i>&nbsp;&nbsp;{windSpeed}&nbsp;knots &nbsp;&nbsp;
                <u>({weatherSource})</u>
            </Box>
            { props.root && <AnalysisExport name={"Directional Compliance"} data={data} /> }
            <svg ref={svgRef}></svg>
        </div>
        </>
    );
}

export default DirectionalCompliance;
