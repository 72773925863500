import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
import jwt from 'jwt-decode'
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setRoot } from "../redux/rootSlice";
import { setAuthenticated } from "../redux/authenticatedSlice";
import { setAuthorityId } from "../redux/authoritySlice";
import { setFirstName } from "../redux/firstNameSlice";
import { setAirportCode } from "../redux/airportCodeSlice";
import { setRole } from "../redux/roleSlice";

const TEAM_EAGLE = process.env.REACT_APP_TEAM_EAGLE_AUTHORITY_NAME;
const WEBAPI = process.env.REACT_APP_WEB_API;
const DEFAULT_AIRPORT = process.env.REACT_APP_DEFAULT_AIRPORT;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters")
    .required("Password is required"),
});

export default function LoginPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [invalid, setInvalid] = useState(false)
  const dispatch = useDispatch()
  const email = sessionStorage.getItem('email')

  const formik = useFormik({
    initialValues: {
      password: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setFormSubmitting(true)

      axios.post(`${WEBAPI}/auth/login`, { email: email, password: values.password })
        .then((res) => {
          // console.log(res)

          if (res.data.status === "INVALID_PWD") {
            setInvalid(true)
          } else {
            // console.log('Successfully logged in.', res.data);
            localStorage.setItem("token", res.data.token);

            const decodedToken = jwt(res.data.token)
            dispatch(setRoot(decodedToken.authorityName === TEAM_EAGLE))
            const role = decodedToken.airportRoles.length > 0 ? decodedToken.airportRoles[0].role : ''
            dispatch(setRole(role))
            dispatch(setAuthenticated(true))
            dispatch(setAuthorityId(decodedToken.authorityId))
            dispatch(setFirstName(decodedToken.fname))
            let airportCode = decodedToken.airportRoles.length === 1 && role !== 'none' ? decodedToken.airportRoles[0].airport.code : ''

            // if user has no airport role (for root users), set airport code to default airport
            if (airportCode === '' && decodedToken.authorityName === TEAM_EAGLE) {
              airportCode = DEFAULT_AIRPORT
              dispatch(setAirportCode(airportCode))
            }

            
            history.push("/");
          }
        })
        .catch((err) => {
            console.log(err);
        })

      setSubmitting(false)
    },
  });

  const handlePasswordChange = (e) => {
    setInvalid(false)
    formik.setFieldValue("password", e.target.value, false);
  }

  const forgotPasswordHandler = () => {
    // call api to create a pin and send email with pin
    axios.post(`${WEBAPI}/auth/forgotpassword`, { email: email })
    .then((res) => {
      // console.log(res)
      history.push(`/loginpasswordcode/`)
    })
    .catch((err) => {
        console.log(err);
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password
        </Typography>

        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="false"
        >
          <TextField
            size="small"
            value={formik.values.password}
            onChange={handlePasswordChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password"
            }}
            />
          <FormHelperText style={{display: (invalid) ? 'block' : 'none' , color: 'red' }} >Your password does not match.</FormHelperText>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.submit}
          >
            Log In
          </Button>
        </form>

        <Grid container>
          <Grid item xs>
            <Link
              component="button"
              variant="body2"
              onClick={() => forgotPasswordHandler()}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
