import { Paper, Grid, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";

const { PUBLIC_URL }    =   process.env;

export default function DashboardInfoBar({runway, weather}) {

    const [maxCrosswind, setMaxCrosswind] = useState(0);

    useEffect(() => {
        if (runway && weather) {
            setMaxCrosswind(_maxCrosswind(runway));
        }
    }, [runway, weather]);

    // calculated maximum crosswind component
    function _maxCrosswind(runway) {
        const sin = (a) => Math.sin(Math.PI * a / 180);
        if (weather && runway) {
            const wind_direction = typeof weather.wind_direction !== 'undefined' ? 100 : -1;
            const windspeed_knots  = typeof weather.windspeed_knots !== 'undefined' ? weather.windspeed_knots : -1;

            if ( wind_direction !== -1 && windspeed_knots !== -1 ) {
                const runway_direction = parseInt( runway.substring(0, 2)+'0' );
                const wind_component = Math.abs(wind_direction - runway_direction);
                const crosswind_component = Math.abs(sin( wind_component > 180 ? wind_component-180 : wind_component ));

                return (Math.round(windspeed_knots*(crosswind_component)*10)/10) + "kt";
            }
        }

        return '0';
    }

    // calculated maximum headwind component
    function maxHeadwind(runway) {
        const cos = (a) => Math.cos(Math.PI * a / 180);
        if (weather && runway) {
            const wind_direction = typeof weather.wind_direction !== 'undefined' ? 100 : -1;
            const windspeed_knots  = typeof weather.windspeed_knots !== 'undefined' ? weather.windspeed_knots : -1;

            if ( wind_direction !== -1 && windspeed_knots !== -1 ) {
                const runway_direction = parseInt( runway.substring(0, 2)+'0' );
                const wind_component = Math.abs(wind_direction - runway_direction);
                const headwind_component = Math.abs(cos( wind_component > 180 ? wind_component-180 : wind_component ));

                return (Math.round(windspeed_knots*(headwind_component)*10)/10) + "kt";
            }
        }

        return '0';
    }

    // calculated maximum tailwind component
    function maxTailwind(runway) {
        const cos = (a) => Math.cos(Math.PI * a / 180);
        if (weather && runway) {
            const wind_direction = typeof weather.wind_direction !== 'undefined' ? 100 : -1;
            const windspeed_knots  = typeof weather.windspeed_knots !== 'undefined' ? weather.windspeed_knots : -1;

            if ( wind_direction !== -1 && windspeed_knots !== -1 ) {
                const runway_direction = parseInt( runway.substring(0, 2)+'0' );
                const wind_component = Math.abs(wind_direction - runway_direction);
                const tailwind_component = Math.abs(cos( wind_component > 180 ? wind_component-180 : wind_component ));

                return (Math.round(windspeed_knots*(tailwind_component)*10)/10) + "kt";
            }
        }

        return '0';
    }

    return (
        <Paper className="dashboard-info-bar" style={{padding: '0px 10px', marginTop: '10px'}}>
            <Grid container spacing={2}>
                <Grid item>
                    <Tooltip title={"Maximum Crosswind Component for "+runway} placement="top">
                        <div className="info-bar-item">
                            <img src={PUBLIC_URL +'/icons/crosswind.svg'} alt="Crosswind Icon" width="20" /> {maxCrosswind}
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    )
}