import { FormControl, TextField, makeStyles, Typography, Button, Grid, Snackbar, Switch, Tooltip, MenuItem, Select, InputLabel, CircularProgress  } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import axios from "axios";
import MuiAlert from '@material-ui/lab/Alert';
import { NotificationsActive, NotificationsPaused } from "@material-ui/icons";

const WEBAPI = process.env.REACT_APP_WEB_API;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const messages = {
    'USER_NOT_FOUND_OR_INACTIVE': 'User not found or inactive',
    'INVALID_OLD_PWD': 'Invalid current password',
    'USER_PASSWORD_CHANGED': 'Password changed successfully',
    'USER_DND_UPDATED': 'DND updated successfully',
    'USER_DND_NOT_UPDATED': 'DND not updated',
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
        padding: window.innerWidth < 600 ? 0: theme.spacing(2)
    },
    section: {
        paddingTop: theme.spacing(1),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControls: {
        maxWidth: '320px',
        width: '100%',
        //marginTop: theme.spacing(3),
        margin: theme.spacing(3, 0, 0),
    },
    submit: {
        width: '100%',
    },
    dndIcon: {
        position: 'relative',
        top: '7px',
    }
}));

const validateScheme = yup.object({
    currentpassword: yup
        .string('Enter your current password')
        .required('Current password is required')
        .min(8, 'Password should be of minimum 8 characters length'),
    password: yup
        .string("Enter your new password")
        .test(
            "regex",
            "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
            val => {
                let regExp = new RegExp(
                    "^(?=.*\\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                );
                return regExp.test(val);
            }
        ),

    password2: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref('password'), null], 'Passwords must match')
})


export default function Account() {
    const classes = useStyles();

    const [snackopen, setSnackOpen] = React.useState(false)
    const [snackmessage, setSnackMessage] = React.useState('')
    const [snackseverity, setSnackSeverity] = React.useState('success')
    const [dndLoading, setDndLoading] = React.useState(false)
    const [dnd, setDnd] = React.useState(false)
    const [dndstart, setDndStart] = React.useState(17)
    const [dndend, setDndEnd] = React.useState(8)

    // Get current DND status
    useEffect(() => {
        axios.get(`${WEBAPI}/user/dnd`, { dnd }).then(res => {
            if ( 'dnd' in res.data ) {
                setDnd(res.data.dnd)
                
                if ( res.data.dnd_start ) {
                    setDndStart(res.data.dnd_start)
                }

                if ( res.data.dnd_end ) {
                    setDndEnd(res.data.dnd_end)
                }
            }
        })
    }, [])

    // Update DND when user changes the switch
    function handleDNDChange() {
        setDndLoading(true)

        axios.put(`${WEBAPI}/user/dnd`, { dnd: dnd, dnd_start: dndstart, dnd_end: dndend }).then(res => {
            if ( res.data.success === true ) {
                setSnack(true, messages[res.data.status], 'success');
            } else {
                setSnack(true, messages[res.data.status], 'error');
            }
            setDndLoading(false)
        })
    }


    function setSnack (open=false, message='', severity='success') {
        setSnackOpen(open)
        setSnackMessage(message)
        setSnackSeverity(severity)
      }

    const formik = useFormik({
        initialValues: {
            currentpassword: '',
            password: '',
            password2: '',
        },
        validationSchema: validateScheme,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            
            axios.post(`${WEBAPI}/auth/changepassword`, { ...values }).then(res => {
                if ( res.data.success === true ) {
                    setSnack(true, messages[res.data.status], 'success');
                    resetForm();
                } else {
                    setSnack(true, messages[res.data.status], 'error');
                }
            })
            
            setSubmitting(false);
        },

    })


    return <div className={classes.root}>
        <div className={classes.paper}>
            <Typography variant="h5">Account</Typography>

            <Typography variant="h6">Do Not Disturb </Typography>
            <Typography variant="body1">
                Enable Scheduled DND  &nbsp; &nbsp; &nbsp;
                    <span>
                        <Switch
                            checked={dnd}
                            onChange={(e) => setDnd(e.target.checked)}
                            name="dnd"
                            inputProps={{ 'aria-label': 'dnd' }}
                        />&nbsp; &nbsp; &nbsp;
                        {/* { dnd ? <NotificationsPaused className={classes.dndIcon} /> : <NotificationsActive className={classes.dndIcon} /> } */}
                    </span>
            </Typography>
            <FormControl className={classes.formControls} size="medium" style={{marginTop: '0'}}>
                <InputLabel id="dnd-start">DND Start</InputLabel>
                <Select
                    labelId="dnd-start-label"
                    id="dnd-start-select"
                    value={dndstart}
                    onChange={(e) => setDndStart(e.target.value)}
                    
                >
                    {new Array(24).fill(1).map((a, i) => {
                        return <MenuItem value={i}>{i < 10 ? '0'+i : i}:00</MenuItem>
                    })}
                </Select>
            </FormControl><br />

            <FormControl className={classes.formControls} size="medium">
                <InputLabel id="dnd-end">DND End</InputLabel>
                <Select
                    labelId="dnd-end-label"
                    id="dnd-end-select"
                    value={dndend}
                    onChange={(e) => setDndEnd(e.target.value)}
                >
                    {new Array(24).fill(1).map((a, i) => {
                        return <MenuItem value={i}>{i < 10 ? '0'+i : i}:00</MenuItem>
                    })}
                </Select>
            </FormControl><br />
            
            <FormControl className={classes.formControls}>
                <Button variant="contained" color="primary" type="submit" className={classes.submit} onClick={handleDNDChange}>
                    Save DND Settings &nbsp;&nbsp;
                    {dndLoading ? <CircularProgress size={18} color="secondary" /> : null}
                </Button>
            </FormControl><br /><br />
            

            <form
                className={classes.form}
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="false"
            >
                <Grid container direction="column">
                <Typography variant="h6">Change Password</Typography>
                <Typography variant="body1">Change your password</Typography>
                <FormControl className={classes.formControls}>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="none"
                        required
                        fullWidth
                        id="currentpassword"
                        label="Current Password"
                        name="currentpassword"
                        type="password"
                        autoComplete="old-password"
                        inputProps={{
                            autoComplete: "old-password"
                        }}
                        value={formik.values.currentpassword}
                        onChange={formik.handleChange}
                        error={formik.touched.currentpassword && Boolean(formik.errors.currentpassword)}
                        helperText={formik.touched.currentpassword && formik.errors.currentpassword}
                    />

                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="New Password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        inputProps={{
                            autoComplete: "new-password"
                        }}
                        style={{marginBottom: '0px'}}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />

                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="password2"
                        label="Confirm New Password"
                        name="password2"
                        type="password"
                        autoComplete="new-password"
                        inputProps={{
                            autoComplete: "new-password"
                        }}
                        value={formik.values.password2}
                        onChange={formik.handleChange}
                        error={formik.touched.password2 && Boolean(formik.errors.password2)}
                        helperText={formik.touched.password2 && formik.errors.password2}
                    />
                </FormControl>

                <FormControl className={classes.formControls}>
                    <Button variant="contained" color="primary" type="submit" className={classes.submit} disabled={formik.isSubmitting}>
                        Save
                    </Button>
                </FormControl>
                </Grid>
            </form>

            <Snackbar open={snackopen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
                <Alert severity={snackseverity} onClose={() => setSnackOpen(false)}>
                    {snackmessage}
                </Alert>
            </Snackbar>
        </div>
    </div>
}