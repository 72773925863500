import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    buttonCover: {
        textAlign: "right",
        padding: theme.spacing(2),
    }
}));

export default function AnalysisExport({name, data}) {
    const classes = useStyles();

    const exportData = () => {
        const labels    =  Object.keys(data[0]);
        const csv       =  data.map(row => labels.map(fieldName => JSON.stringify(row[fieldName])).join(','));

        const content   =  labels.join(',') + '\n' + csv.join('\n');

        var link = document.createElement('a');
        link.download = `analysisdata-${name}`+'.csv';
        link.href = 'data:,' + content;
        link.click();
        link.remove();
    }

    return <div className={classes.buttonCover}>
        <Button variant="contained" color="primary" onClick={exportData}>Export {name}</Button>
    </div>;
}