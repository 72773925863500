import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import { Link } from 'react-router-dom';
import LandingAlertIcons from './LandingAlertIcons';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { GetApp, TuneOutlined } from '@material-ui/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const roundUp = (num, precision=4) => {
  precision = Math.pow(10, precision)
  return Math.round(num * precision) / precision
}

const METER_TO_FEET = 3.28084
const DND_ITEM_TYPE = 'column';

/**
 * @param {string} id
 * @param {boolean} numeric
 * @param {boolean} disablePadding
 * @param {string} label
 * @param {object} settings
 * @param {function} settings.evaluate - computes the value to display or to show in report
 * @param {function} settings.render - overrides evaluate only for display
 * @param {boolean} settings.showOnReport - if false, the column will never be included in the report even when checked
 * @param {boolean} settings.isChecked - if true, the column will be displayed and included in the report
 * @param {boolean} settings.isAdmin - if true, the column will be displayed and included in the report only for admin users
 */
const columnCells = [
    { id: 'createdDate', numeric: false, disablePadding: false, label: 'Date', settings: {
        evaluate: (row) => {return (new Date(row.created)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'created', numeric: false, disablePadding: false, label: 'Time', settings: {
        evaluate: (row) => {return (new Date(row.created)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'landingrunway', numeric: false, disablePadding: false, label: 'Runway', settings: {
        evaluate: (row) => {return row.landingrunway},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'icaoaddress', numeric: false, disablePadding: false, label: 'ICAO', settings: {
        evaluate: (row) => {return row.icaoaddress},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'callsign', numeric: false, disablePadding: false, label: 'CallSign', settings: {
        evaluate: (row) => {return row.callsign},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'emitter_type', numeric: false, disablePadding: false, label: 'Class', settings: {
        evaluate: (row) => {return row.emitter_type},
        render: (row) => {return row.emitter_desc},
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'runway_time', numeric: false, disablePadding: false, label: 'Time On Runway', settings: {
        evaluate: row => row['landinganalysis'] !== null  ? Math.round(row.landinganalysis.timeonrunway) +"s": "-",
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'map', numeric: false, disablePadding: false, label: 'Map', settings: {
        evaluate: (row, classes) => <IconButton 
            title="Map"
            size="small"
            color="secondary"
            className={classes.iconButton}
            component={Link} to={{
                pathname: `/landing/${row.id}`,
                state: { from: 'landing' }
            }}>
            <MapOutlinedIcon />
        </IconButton>,
        showOnReport: false,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'data', numeric: false, disablePadding: false, label: 'Data', settings: {
        evaluate: (row, classes) => <IconButton
            title="Data"
            size="small"
            color="secondary"
            className={classes.iconButton}
            component={Link} to={`/landingdata/${row.id}`}>
            <GridOnOutlinedIcon />
        </IconButton>,
        showOnReport: false,
        isChecked: true,
        isAdmin: true
    } },
    { id: 'rawdata', numeric: false, disablePadding: false, label: 'Data (RAW)', settings: {
        evaluate: (row, classes) => <IconButton
            title="Data (RAW)" 
            size="small"
            color="secondary"
            className={classes.iconButton}
            component={Link} to={`/landingdataraw/${row.id}`}>
            <GridOnOutlinedIcon />
        </IconButton>,
        showOnReport: false,
        isChecked: true,
        isAdmin: true
    } },
    { id: 'analysis', numeric: false, disablePadding: false, label: 'Analysis', settings: {
        evaluate: (row, classes) => <IconButton
            title="Analysis"
            size="small"
            color="secondary"
            className={classes.iconButton}
            component={Link} to={`/analysis/${row.id}`}>
            <AssessmentOutlinedIcon />
        </IconButton>,
        showOnReport: false,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'status', numeric: false, disablePadding: false, label: 'Alert Status', settings: {
        evaluate: (row) => {return row.landingalerts.length === 0 ? "": row.landingalerts.map((al) => al.type).join("| ")},
        render: (row) => <LandingAlertIcons alerts={row.landingalerts} id={row.id} />,
        showOnReport: true,
        isChecked: true,
        isAdmin: false
    } },
    { id: 'temperature', numeric: false, disablePadding: false, label: 'Temperature', settings: {
        evaluate: (row) => {return row.weather === null ? "-": Math.round(row.weather.temp_c)+"°C"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'dew_point', numeric: false, disablePadding: false, label: 'Dew Point', settings: {
        evaluate: (row) => {return row.weather === null ? "-": Math.round(row.weather.dewpoint_c)+"°C"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'windspeed', numeric: false, disablePadding: false, label: 'WindSpeed', settings: {
        evaluate: (row) => {return row.weather === null ? "-": Math.round(row.weather.wind_speed_kt)+"kt"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'v_temperature', numeric: false, disablePadding: false, label: 'Temperature (Vaisala)', settings: {
        evaluate: (row) => {return row.vaisala === null ? "-": Math.round(row.vaisala.temp_c)+"°C"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'v_dew_point', numeric: false, disablePadding: false, label: 'Dew Point (Vaisala)', settings: {
        evaluate: (row) => {return row.vaisala === null ? "-": Math.round(row.vaisala.dewpoint_c)+"°C"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'v_windspeed', numeric: false, disablePadding: false, label: 'WindSpeed (Vaisala)', settings: {
        evaluate: (row) => {return row.vaisala === null ? "-": Math.round(row.vaisala.wind_speed_kt)+"kt"},
        showOnReport: true,
        isChecked: false,
        isAdmin: false
    } },
    { id: 'max_decel', numeric: false, disablePadding: false, label: 'Max Decel (g)', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": row.landinganalysis.max_decel},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
    } },
    { id: 'min_decel_in_range', numeric: false, disablePadding: false, label: 'Min Decel In Range (g)', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": row.landinganalysis.min_decel_in_range},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
    } },
    { id: 'avg_decel_in_range', numeric: false, disablePadding: false, label: 'Avg Decel In Range (g)', settings: {
      evaluate: (row) => {return row.landinganalysis === null ? "-": row.landinganalysis.avg_decel_in_range},
      showOnReport: true,
      isChecked: false,
      isAdmin: true
  } },
      { id: 'ml_max_decel', numeric: false, disablePadding: false, label: 'ML Max Decel (g)', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": roundUp( row.landinganalysis.ml_max_decel_g )},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
      } },
      { id: 'ml_max_decel_delta', numeric: false, disablePadding: false, label: 'ML Max Decel Delta (g)', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": roundUp( row.landinganalysis.ml_max_decel_delta_g )},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
      } },
      { id: 'ml_curve_rms', numeric: false, disablePadding: false, label: 'ML Curve RMS', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": roundUp( row.landinganalysis.ml_curve_rms )},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
      } },
      { id: 'distance_at_exit', numeric: false, disablePadding: false, label: 'Distance at Exit', settings: {
        evaluate: (row) => {return row.landinganalysis === null ? "-": Math.abs( parseFloat( row.landinganalysis.distance_at_exit * METER_TO_FEET ) )},
        showOnReport: true,
        isChecked: false,
        isAdmin: true
      } },
  ]


const columnsPosition = columnCells.map((headCell, index) => {
  return {
    id: headCell.id,
    index: index
  }
})

console.log('column_position',columnsPosition)

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

const rowMap = {
  "runway_time": "landinganalysis.timeonrunway",
  "max_decel": "landinganalysis.max_decel",
  "min_decel_in_range": "landinganalysis.min_decel_in_range",
  "avg_decel_in_range": "landinganalysis.avg_decel_in_range",
  "ml_max_decel": "landinganalysis.ml_max_decel_g",
  "ml_max_decel_delta": "landinganalysis.ml_max_decel_delta_g",
  "ml_curve_rms": "landinganalysis.ml_curve_rms",
  "temperature": "weather.temp_c",
  "dew_point": "weather.dewpoint_c",
  "windspeed": "weather.wind_speed_kt",
  "v_temperature": "vaisala.temp_c",
  "v_dew_point": "vaisala.dewpoint_c",
  "v_windspeed": "vaisala.wind_speed_kt",
  "distance_at_exit": "landinganalysis.distance_at_exit"
}

function resolve(path, obj) {
  path = rowMap[path];
  return path.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : null
  }, obj || this)
}

function descendingComparator(a, b, orderBy) {
  // the value might be a nested object, so we need to resolve it
  if ( orderBy in rowMap ) {
    var _a = resolve(orderBy, a);
    var _b = resolve(orderBy, b);

    // compare the type of the values and sort accordingly
    if (typeof parseFloat(_a) === "number" && typeof parseFloat(_b) === "number") {
      _a = parseFloat(_a);
      _b = parseFloat(_b);
      if (_b < _a) {
        return -1;
      }
      if (_b > _a) {
        return 1;
      }
      return 0;
    } else if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b);
    } else if (typeof a === "object" && typeof b === "object") {
      _a = _a.length;
      _b = _b.length;

      if (_b < _a) {
        return -1;
      }
      if (_b > _a) {
        return 1;
      }
      return 0;
    } else {
      if (_b < _a) {
        return -1;
      }
      if (_b > _a) {
        return 1;
      }
      return 0;
    }

  } else {
    // default sort to the old way
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DraggableHeaderCell = ({ headCell, index, orderBy, order, moveColumn, classes, createSortHandler }) => {
  const originalIndex = index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DND_ITEM_TYPE, id: headCell.id, originalIndex },
    type: DND_ITEM_TYPE,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveColumn(droppedId, originalIndex, false);
      }
    },
  });

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    canDrop: () => true,
    hover({ id: draggedId }) {
      if (draggedId !== headCell.id) {
        moveColumn(draggedId, index, true);
      }
    },
  });

  return (
    <TableCell
      ref={node => drag(drop(node))}
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      padding={headCell.disablePadding ? 'none' : 'default'}
      sortDirection={orderBy === headCell.id ? order : false}
      style={{ cursor: 'move', opacity: isDragging ? 0.5 : 1 }}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        
      </TableSortLabel>
      
    </TableCell>
  );
};

function EnhancedTableHead(props) {
  const { onRequestSort, root, fields } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <DndProvider backend={HTML5Backend}>
          {
            fields.filter((cell) => (root ? true : !cell.settings.isAdmin)&&cell.settings.isChecked).map((headCell, idx) => {
              if (!headCell || headCell.id == null) {
                return null; // or return some default/fallback component
              }

              return <DraggableHeaderCell
                headCell={headCell}
                key={headCell.id}
                index={idx}
                {...props}
                createSortHandler = {createSortHandler}
              />
            })
          }
        </DndProvider>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};




const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));



const downloadAsCSV = (headCells, data) => {
  let content = ""
  headCells.filter((cell) => cell.settings.showOnReport && cell.settings.isChecked).map((item) => {
    return content += item.label + ","
  })
  content += "\r\n"

  data.map((item) => {
    let row = ""
    for (let idx=0; idx<headCells.length; idx++)
    {
      if ( headCells[idx].settings.showOnReport === true && headCells[idx].settings.isChecked === true ) { // Choose the column that needs to be shown on the report
        row += 'evaluate' in headCells[idx] ? item[headCells[idx].id]: headCells[idx].settings.evaluate(item)
        row += ","
      }
    }

    row += "\r\n"

    return content += row
  })

  var date  = new Date();
  
  var link = document.createElement('a');
  link.download = `LANDINGS-${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}-search`+'.csv';
  link.href = 'data:,' + content;
  link.click();
  link.remove();
}

const downloadAsPDF = (headCells, data) => {
  var date  = new Date();
  let content = `<title>LANDINGS-${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}-search</title>`;
  content += '<table cellspacing="0" cellpadding="2" border="1" style="width:100%; font-family: Roboto, Helvetica, Arial, sans-serif;">';

  content += '<tr>';
  headCells.filter((cell) => cell.settings.showOnReport && cell.settings.isChecked).map((item) => {
    return content += `<th>${item.label}</th>`;
  })
  content += '</tr>';


  data.map((item) => {
    let row = "<tr>"
    for (let idx=0; idx<headCells.length; idx++){
      if ( headCells[idx].settings.showOnReport === true && headCells[idx].settings.isChecked === true ) { // Choose the column that needs to be shown on the report
        row += "<td align='center'>";
        row += 'evaluate' in headCells[idx] ? item[headCells[idx].id]: headCells[idx].settings.evaluate(item)
        row += "</td>";
      }
    }
    content += (row + "</tr>");
  });
  content += '</table>';

  var tab = window.open('', '', 'left=0,top=0,toolbar=0,scrollbars=0,status=0');
  tab.document.write(content);
  tab.document.close();
  tab.print();
}



const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, pageName, data, fields, handleChange, root } = props;
  const role = useSelector((state) => state.role.value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  // const history = useHistory();

  const handleExportCSV = () => {
    downloadAsCSV(fields, data);
    // setFieldsOpen(true);
    // setExportMode('CSV');
    
  }

  const handleExportPDF = () => {
    downloadAsPDF(fields, data);
    // setFieldsOpen(true);
    // setExportMode('PDF');
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Landings
            </Typography>
          </Grid>

          <Grid item xs={6}>
            {pageName === "search" && data.length > 0 && role !== "operator" ? 
              <Grid container justifyContent='flex-end' alignContent='center' spacing={2}>
                <Grid item>
                    <IconButton
                        aria-label="filter list"
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                        }}
                    >
                        <Tooltip title="Export">
                            <GetApp />
                        </Tooltip>
                    </IconButton>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <Typography variant="body2" style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 5, paddingTop: 5}}><b>EXPORT:</b></Typography>
                        <MenuItem onClick={handleExportCSV}>Download as CSV</MenuItem>
                        <MenuItem onClick={handleExportPDF}>Download as PDF</MenuItem>
                    </Menu>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="filter list"
                        onClick={(e) => {
                           setAnchorEl1(e.currentTarget)
                        }}
                    >
                        <Tooltip title="Choose Columns">
                            <TuneOutlined />
                        </Tooltip>
                    </IconButton>
                    <Menu
                        id="download-menu"
                        anchorEl={anchorEl1}
                        keepMounted
                        open={Boolean(anchorEl1)}
                        onClose={() => setAnchorEl1(null)}
                    >
                        <Typography variant="body2" style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 5, paddingTop: 5}}><b>CHOOSE COLUMNS:</b></Typography>
                        {fields.map((field, index) => {
                            return (root ? true : !field.settings.isAdmin) ? (
                                <div key={index}>
                                    <FormControlLabel
                                    control={<Checkbox checked={field.settings.isChecked} onClick={(e) => handleChange(e, index)} name={field.id} />}
                                    label={field.label}
                                    style={{paddingLeft: 15, paddingRight: 15}}
                                    />
                                    <br />
                                </div>
                            ): null
                        })}
                    </Menu>
                    
                </Grid>
              </Grid>
            : null}
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  weather: {
    margin: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },

}));



export default function SearchLandingsList(props) {
  let cCells = [];

  // have columnCells to be in same order of props.columns and save in cCells
  if ( props.columns?.length > 0 ) { 
    console.log('Has Columns', props.columns)
    props.columns.forEach((column) => {
      columnCells.forEach((cell) => {
        if (cell.id === column.id) {
          cCells.push(cell);
        }
      })
    })
  } else {
    console.log('Has No Columns')
    cCells = columnCells;
  }

  const classes = useStyles();
  // const [order, setOrder] = React.useState('desc');
  // const [orderBy, setOrderBy] = React.useState('created');
  const [pageName, setPageName] = React.useState(props.pageName);
  // const [page, setPage] = React.useState(0);
  const { page, setPage, order, setOrder, orderBy, setOrderBy } = props;
  console.log('page', page)
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState(cCells);
  //console.log('fields', fields.map((field) => field.id))
  const root = useSelector((state) => state.root.value)
  const history = useHistory()


  useEffect(() => {
    const rowsPerPage = localStorage.getItem(`${pageName}_rowsPerPage`);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage));
    }

    setRows(props.data)
    
    if ( props.columns?.length > 0 ) {
      if ( JSON.stringify(props.columns) !== JSON.stringify(getCurrentChecked()) ) {
        console.log('setting new columns')
        var temp_fields = [...fields]
        temp_fields.map( (field) => {
          props.columns.map( (column) => {
            if ( column.id === field.id ) {
              field.settings.isChecked = column.checked
            }
          })
        })
        setFields(temp_fields)
      }
    }
  }, [props])
  
  useEffect(() => {
    if ( JSON.stringify(props.columns) !== JSON.stringify(getCurrentChecked()) ) {
      props.setColumns(getCurrentChecked())
      console.log('columns changed', getCurrentChecked())
    }
  }, [fields])

  function getCurrentChecked() {
    var temp_fields = [...fields]
    return temp_fields.map( (field) => {
      return {
        id: field.id,
        checked: field.settings.isChecked,
      };
    });
  }

  const handleRequestSort = (event, property) => {
    console.log('property to sort by', property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (pageName === "search" && event.target.value === -1 && props.data.length > 1000) {
      setOpen(true);
      return;
    }
    localStorage.setItem(`${pageName}_rowsPerPage`, event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setAllRowsPerPage = () => {
    localStorage.setItem(`${pageName}_rowsPerPage`, -1);
    setRowsPerPage(-1);
    setPage(0);
    setOpen(false);
  }

  // This code allows the user to drag and drop columns to reorder them
  const moveColumn = (dragIndex, hoverIndex, needsUpdated) => {
    if ( !needsUpdated ) {
      return
    }


    let index = fields.map((item) => item.id).indexOf(dragIndex);

    if (index === hoverIndex) {
      return;
    }

    const dragField = fields[index];
    const temp_fields = [...fields]
    temp_fields.splice(index, 1);
    temp_fields.splice(hoverIndex, 0, dragField);

    setFields(temp_fields);
  }

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar pageName={props.pageName} data={rows} fields={fields} root={root} handleChange={(e, i) => {
          let temp = Object.assign([], fields);
          temp[i].settings.isChecked = !('checked' in e) ? !temp[i].settings.isChecked : e.target.checked;
          setFields(temp);
        }}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              root={root}
              fields={fields}
              moveColumn={moveColumn}
            />
            <TableBody>
              {
                (rowsPerPage > 0
                  ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : stableSort(rows, getComparator(order, orderBy))
                )
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                        {fields.filter((cell) => (root ? true : !cell.settings.isAdmin)&&cell.settings.isChecked).map((columnCell, index) => {
                            return (
                                <TableCell key={index} align="left">
                                    {'render' in columnCell.settings? 
                                        columnCell.settings.render(row, classes):
                                        columnCell.settings.evaluate(row, classes)}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, { label: 'All', value: -1 }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {pageName === "search" ? LandingsListAlert(
        open, 
        () => {setOpen(false)}, 
        () => {setAllRowsPerPage()}
      ): null}
    </div>
  );
}

function LandingsListAlert(isOpen, handleClose, handleAgree) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning: You are displaying all the rows"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Doing this action may cause your browser to perform slow due to high number of rows. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Discard
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

SearchLandingsList.propTypes = {
  data: PropTypes.array.isRequired,
  pageName: PropTypes.string.isRequired
};
