const AircraftType = {
    0: 'Unknown',
    1: 'Light (< 15,500 lbs)',
    2: 'Small (15,500 - 75,000 lbs)',
    3: 'Large (75,000 - 300,000 lbs)',
    4: 'High Vortex Large (e.g. B757)',
    5: 'Heavy (> 300,000+ lbs)',
    6: 'Highly Maneuverable (> 5G acceleration)',
}

module.exports = AircraftType;