import React, { useState, useEffect } from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as axios from 'axios'
import Grid from "@material-ui/core/Grid";
import AirportImage from '../charts/AirportImage'
import Paper from '@material-ui/core/Paper';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from '@material-ui/core/FormHelperText';

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchemaNew = yup.object({
  authorityid: yup
  .string('Select Authority')
  .required('Authority is required'),
  code: yup
    .string('Enter Airport Code')
    .length(4, 'Enter a valid airport code')
    .required('Airport code is required'),
  name: yup
    .string('Enter the airport name')
    .required('Airport name is required'),
  elevation: yup
    .number('Enter the airport elevation')
    .required('Airport elevation is required'),
  geojsonfile: yup
  .string('Select the GeoJSON File')
  .required('GeoJSON file is required'),
  country: yup
    .string('Select the country')
    .required('Country is required'),

});

const validationSchemaEdit = yup.object({
  authorityid: yup
  .string('Select Authority')
  .required('Authority is required'),
  code: yup
    .string('Enter Airport Code')
    .length(4, 'Enter a valid airport code')
    .required('Airport code is required'),
  name: yup
    .string('Enter the airport name')
    .required('Airport name is required'),
  elevation: yup
    .number('Enter the airport elevation')
    .required('Airport elevation is required'),
  country: yup
    .string('Select the country')
    .required('Country is required'),
});

const countries = [
  {value: 'ca', label: 'Canada'},
  {value: 'us', label: 'United States'},
]

export default function Airport() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams()
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [elevation, setElevation] = useState('')
  const [runwayConfig, setRunwayConfig] = useState([])
  const [geoJson, setGeoJson] = useState(null)
  const [country, setCountry] = useState('ca')
  // const [initialized, setInitialized] = useState(false)
  const [authorities, setAuthorities] = useState([])
  const [authorityId, setAuthorityId] = useState('')
  // const [easting, setEasting] = useState('')
  // const [northing, setNorthing] = useState('')
  const [utmTopLeft, setUtmTopLeft] = useState([])

  useEffect(() => {
    axios.get(`${WEBAPI}/authority`)
    .then((res) => {
      // console.log(res.data)
      setAuthorities(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      axios.get(`${WEBAPI}/airport/${id}`)
      .then((res) => {
        console.log(res.data)

        setCode(res.data.code);
        setName(res.data.airportname);
        setElevation(res.data.elevation);
        setAuthorityId(res.data.authority.id)
        setCountry(res.data.country)

        // process the runways
        const runways = []
        for (let idx=0; idx<res.data.runways.length; idx++) {
          const runwayName = res.data.runways[idx].runwayname
          
          const runway = {
            name: runwayName, 
            poly: [res.data.runways[idx].polygon.coordinates[0]]
          }
          runways.push(runway)
        }
  
        setRunwayConfig(runways)

        getWorldFile(res.data.code)

      })
      .catch((err) => {
          console.log(err);
      })
    }
  }, [id]);


  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        authorityid: authorityId,
        code: code,
        name: name,
        elevation: elevation,
        geojsonfile: '',
        country: country,
      },
      validationSchema: id === undefined ? validationSchemaNew : validationSchemaEdit,
      onSubmit: (values, {setSubmitting, resetForm} ) => {

        const airportCode = values.code.toUpperCase()
        const easting = utmTopLeft[0]
        const northing = utmTopLeft[1]

        if (id === undefined) {
          axios.post(`${WEBAPI}/airport`, { authorityid: values.authorityid, code: airportCode, name: values.name, elevation: values.elevation, easting: easting, northing: northing, geojson: geoJson, country: values.country})
              .then((res) => {
                setSubmitting(false)
                history.push('/airports')
                })
              .catch((err) => {
                setSubmitting(false)
              })
        } else {
          axios.put(`${WEBAPI}/airport`, { id: id, authorityid: values.authorityid, code: airportCode, name: values.name, elevation: values.elevation, easting: easting, northing: northing, geojson: geoJson, country: values.country})
              .then((res) => {
                setSubmitting(false)
                history.push('/airports')
                })
              .catch((err) => {
                  // console.log(err);
                  setSubmitting(false)
              })
        }

      },
  });

  const selectedFile = (e) => {
    console.log('selectedFile')
    let file = e.target.files[0];

    if (file === undefined) return 

    formik.setFieldValue("geojsonfile", file.name, false);

    let reader = new FileReader();
    reader.readAsText(file);
  
    reader.onload = function() {
      const jsonData = JSON.parse(reader.result)

      console.log(jsonData)

      setGeoJson(jsonData)
  
      const runways = []
      for (let idx=0; idx<jsonData.features.length; idx++) {
        const runwayName = jsonData.features[idx].properties.RunwayName
        
        const runway = {
          name: runwayName, 
          poly: [jsonData.features[idx].geometry.coordinates[0][0]]
        }

        console.log(runway)
        runways.push(runway)
      }

      console.log(runways)

      setRunwayConfig(runways)
      // setInitialized(true)
    };
  
    reader.onerror = function() {
      console.log(reader.error);
    };
  };


  const getWorldFile = (airportCode) => {
    // get the world file so we know the UTM of the tope left of the satellite image.
    const fileName = `/images/${airportCode.toLowerCase()}.jgw`
    // console.log('Fetching World file', fileName)

    axios.get(`${fileName}`)
    .then((res) => {
      const lines = res.data.split('\n')
      if (lines.length > 5) {
        const easting = +lines[4]
        const northing = +lines[5]
  
        setUtmTopLeft([easting, northing])
        // setEasting(easting)
        // setNorthing(northing)
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const airportCodeChangeHandler = () => {
    getWorldFile(formik.values.code)
  }


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={ formik.handleSubmit }>

      <Grid container className={classes.grid} spacing={1}>
        <Grid item xs={12} md={4}>
          <Typography component="h1" align="left" variant="h5">
            Airport
          </Typography>

          <Grid container className={classes.grid}>
            <Grid item xs={12} >
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.formControl}
                size={"small"}
                required
                error={formik.touched.authorityid && Boolean(formik.errors.authorityid)}
                >
                <InputLabel>Authority</InputLabel>
                <Select
                  value={formik.values.authorityid}
                  onChange={formik.handleChange}
                  id="authorityid"
                  label="Authority"
                  name="authorityid"
                >
                  {authorities?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{display: (formik.touched.authorityid && Boolean(formik.errors.authorityid) ? 'block' : 'none' ) }} >Authority is required</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={airportCodeChangeHandler}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Code"
                name="code"
                autoComplete=""
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                id="name"
                autoComplete=""
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                value={formik.values.elevation}
                onChange={formik.handleChange}
                error={formik.touched.elevation && Boolean(formik.errors.elevation)}
                helperText={formik.touched.elevation && formik.errors.elevation}
                variant="outlined"
                margin="normal"
                type="number"
                required
                fullWidth
                name="elevation"
                label="Elevation (meters)"
                id="elevation"
                autoComplete=""
              />
            </Grid>

            <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  className={classes.formControl}
                  size={"small"}
                  required
                  fullWidth
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  >
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    id="country"
                    label="Country"
                    name="country"
                  >
                    {countries.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{display: (formik.touched.country && Boolean(formik.errors.country) ? 'block' : 'none' ) }} >Country is required</FormHelperText>
                </FormControl>
              </Grid>

            <Grid item xs={12} >
              <Grid container direction={'row'} justify="space-between" alignItems="center" className={classes.formControls} spacing={1}>
                <Grid item>
                  <TextField
                    size="small"
                    value={formik.values.geojsonfile}
                    onChange={formik.handleChange}
                    error={formik.touched.geojsonfile && Boolean(formik.errors.geojsonfile)}
                    helperText={formik.touched.geojsonfile && formik.errors.geojsonfile}
                    variant="outlined"
                    margin="dense"
                    required={ id === undefined ? true : false }
                    disabled
                    style={{maxWidth:'150px'}}
                    name="geojsonfile"
                    label="GeoJSON file"
                    id="geojsonfile"
                    autoComplete=""
                  />
                </Grid>
                <Grid item>
                  <label>
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => selectedFile(e)}
                    />
                    <Button className="btn-choose" variant="outlined" component="span">
                      Select GeoJSON
                    </Button>
                  </label>
                </Grid>


              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.submit}
              >
                Save
              </Button>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <div>
            {
              <AirportImage code={formik.values.code} utm={utmTopLeft} runways={runwayConfig} />
            }
          </div>
        </Grid>
      </Grid>

      </form>
      </Paper>
    </div>
  )
}