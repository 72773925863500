import { Button, Tooltip } from "@material-ui/core";
import SpeedIcon from '@material-ui/icons/Speed';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { Link } from 'react-router-dom';
import RestrictedMLAccess from "../lib/RestrictedMLAccess";

const { PUBLIC_URL }    =   process.env;

const icons   =   {
    'DC': <CallReceivedIcon style={{fill: '#ff0000', transform: 'rotateY(180deg)'}} />,
    'DCML': <CallReceivedIcon style={{fill: '#ff0000', transform: 'rotateY(180deg)'}} />,
    'CL': <img height={29} src={PUBLIC_URL +'/icons/center-line-compliance.svg'} style={{transform: 'rotate(90deg)'}} alt='center line compliance' />,
    'GS': <SpeedIcon style={{fill: '#ff0000'}} />
}

const runway_labels =   {
    'FIRST_THIRD': 'First Third',
    'MIDDLE_THIRD': 'Middle Third',
    'LAST_THIRD': 'Last Third',
    'FIRST_HALF': 'First Half',
    'SECOND_HALF': 'Second Half',
    'LAST_500FT': 'Last 500 ft'
}

function LandingAlertIcons({alerts, id}) {
    if ( typeof alerts === "undefined" ) return <Tooltip title="Not Enough Information">
        <span style={{paddingLeft: "15px"}}>NEI</span>
    </Tooltip>;

    // Remove duplicate alerts of same
    var types           =   [];
    var uniqueAlerts    =   alerts.filter((alert) => {
        if (types.includes(alert.type) === false) {
            types.push(alert.type)
            return true;
        } else {
            return false;
        }
    });


    function getTitle(alert) {
        const {type, runway_area, maintain, amount, message} = alert;
        switch (type){
            case 'DC': 
                return `Deceleration Alert: Decelration less than ${amount}g in the ${runway_labels[runway_area]} of runway, maintained for ${maintain} feet.`;
            case 'DCML':
                return message;
            case 'CL':
                return `Centerline Compliance Alert: Deviation over ${amount} feet in the ${runway_labels[runway_area]} of runway, maintained for ${maintain} feet.`;
            case 'GS':
                return `Ground Speed Alert: Speed over ${amount} Knots in the ${runway_labels[runway_area]} of runway, maintained for ${maintain} feet.`;
            default:
                return '';
        }
    }

    return uniqueAlerts.length === 0 ? <Tooltip title="Not Enough Information">
            <span style={{paddingLeft: "15px"}}>NEI</span>
        </Tooltip> : uniqueAlerts.map((alert, i) => {
       if (alert.type === 'DCML') {
            return <RestrictedMLAccess>
                <Tooltip title={getTitle(alert)} key={i}>
                    <Button style={{maxHeight: 36, padding: '0px 3px', minWidth: '50px'}} component={Link} to={`/landingalerts/${id}`}>{icons[alert.type]}</Button>
                </Tooltip>
            </RestrictedMLAccess>
        } else {
            return <Tooltip title={getTitle(alert)} key={i}>
                <Button style={{maxHeight: 36, padding: '0px 3px', minWidth: '50px'}} component={Link} to={`/landingalerts/${id}`}>{icons[alert.type]}</Button>
            </Tooltip>
        }
    })
}

export default LandingAlertIcons;