import { Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    recentLanding: {
        width: '100%'
    },
    landingrows: {
        textAlign: 'center'
    }
}));

export default function DashboardLanding({recentLanding, runway}) {
    const classes       =   useStyles();

    if ( recentLanding[runway] === undefined ) {
        return <Paper className={classes.widget}>
            <Box marginTop={1}>
                <Typography variant="body1" color="textSecondary">
                    No recent landings.
                </Typography>
            </Box>
        </Paper>
    }

    const formatDate    =   (date) => {
        var hour    =   checkDigits(date.getHours()>12?date.getHours()%12:date.getHours());
        var minutes =   date.getMinutes();
        var ampm    =   date.getHours()>=12?'PM':'AM'

        minutes = minutes < 10 ? "0"+minutes: minutes;
        return `${date.getFullYear()}/${checkDigits(date.getMonth()+1)}/${checkDigits(date.getDate())} ${hour}:${minutes}${ampm}`
    }

    function checkDigits(int) {
        return int < 10 ? "0"+int : int;
    }

    return <Paper className={classes.widget}>
    <Typography variant="h6" >
        <Box textAlign="center">
            RECENT LANDINGS
        </Box>
    </Typography> 

    <table className={classes.recentLanding}>
        <thead>
            <tr key={0}>
                <th>Time</th>
                <th>Runway</th>
                <th>ICAO</th>
                <th>CallSign</th>
            </tr>
        </thead>
        <tbody className={classes.landingrows}>
        {recentLanding[runway].filter((x,i)=> i<5).map((data, i) => {
            var date    =   new Date(data.created);
            return <tr key={i+1}>
                <td>{formatDate(date)}</td>
                <td>{data.landingrunway}</td>
                <td>{data.icaoaddress}</td>
                <td>{data.callsign}</td>
            </tr>
        })}
        </tbody>
    </table>
</Paper>
}