import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as axios from 'axios'
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
// import { setEmail } from '../redux/emailSlice'
// import { useSelector, useDispatch } from 'react-redux'

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
});

export default function Login() {
    const classes = useStyles()
    const history = useHistory()
    // const dispatch = useDispatch()
    const [registered, setRegistered] = useState(true)

    const formik = useFormik({
      initialValues: {
        email: '',
      },

      validationSchema: validationSchema,

      onSubmit: (values, {setSubmitting, resetForm} ) => {
        // console.log(values)
        const email = values.email.toLowerCase()

        // dispatch(setEmail(values.email))
        sessionStorage.setItem('email', email)

        axios.post(`${WEBAPI}/auth/login`, { email: email })
        .then((res) => {
          // console.log(res.data)

          switch (res.data.status) {
            case "UNREGISTERED":
              setRegistered(false)
              break
            case "REGISTERED":
              // console.log('go to Password page')
              history.push('/loginpassword')
              break
            case "REGISTERED_NEW":
              // console.log('go to NewPassword page')
              history.push('/loginpasswordnew')
              break
            default:
              break;
          }
        })
        .catch((err) => {
            console.log(err);
        })

        setSubmitting(false)
      },
  });

  const handleEmailChange = (e) => {
    setRegistered(true)
    formik.setFieldValue("email", e.target.value, false);
  }
    
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Email
        </Typography>

        <form className={classes.form} noValidate onSubmit={ formik.handleSubmit } autoComplete='false' >
          <TextField
            size="small"
            value={formik.values.email}
            onChange={handleEmailChange}
            error={formik.touched.email && Boolean(formik.errors.email) }
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <FormHelperText style={{display: (!registered) ? 'block' : 'none' , color: 'red' }} >You must be registered to use this application</FormHelperText>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting || formik.values.email.length === 0 }
            className={classes.submit}>
            Next
          </Button>
        </form>
           
      </div>
    </Container>
  );
}