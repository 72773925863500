import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReportDistributionNew from './ReportDistributionNew'

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
    loading: {
        paddingTop: theme.spacing(3)
    }
}));



export default function ReportDistributionEdit () {
    const classes   = useStyles()
    const { id }    = useParams()

    const [report, setReport] = useState('LOADING')

    useEffect(() => {
        axios.get(`${WEBAPI}/report/edit/${id}`).then((res) => {
            setReport(res.data)
        }).catch((e) => {
            console.log(e)
        })
    }, [id]);

    console.log(report)

    if ( report === 'LOADING' ) {
        return <Grid container alignItems="center" alignContent="center" justifyContent="center" className={classes.loading}>
            <CircularProgress />
        </Grid>
    }
    
    if ( report.length != 0 ) {
        //return '';
        return <ReportDistributionNew updating={true} report={report} />
        //return <ReportDistributionEdit />
    } else {
        return <Typography variant="h1">Unable to find report schedule</Typography>
    }
}