import { makeStyles, Typography, Grid } from "@material-ui/core"
import axios from "axios"
import { useEffect, useState } from "react"

const { REACT_APP_WEB_API } = process.env

// theme styles
const useStyles =    makeStyles((theme) => ({
    widget: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2)
    },
    notamBox: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginRight: '-16px',
        marginLeft: 0,
        width: '260px',
        position: 'relative',
        left: '30px'
    },
    notamBoxInner: {
        border: '2px solid #ccc',
        borderRight: '0',
        height: 68
    },
    colorBlue: {
        color: '#5275BF'
    },
    runwayLeft: {
        lineHeight: '2.1',
        transform: "rotate(90deg)"
    },
    runwayRight: {
        lineHeight: '2.1',
        transform: "rotate(-90deg)"
    },
}));

export default function AnalysisNotam ({id, landingrunway}) {
    const classes   =   useStyles();

    const [notam, setNotam] = useState([])
    const [runwayCondition, setRunwayCondition] = useState([])
    const [sides, setSides] = useState([])
    const [validFrom, setValidFrom] = useState('')
    const [validTo, setValidTo] = useState('')

    useEffect(() => {
        axios.get(`${REACT_APP_WEB_API}/runwaycondition/?id=${id}`).then((res) => {
            if ( res.data.hasOwnProperty('airport') ) {
                setNotam([ res.data.firstThirdScore,  res.data.secondThirdScore,  res.data.lastThirdScore])
                setRunwayCondition([ res.data.firstThirdText,  res.data.secondThirdText,  res.data.lastThirdText])
                setValidFrom(convertToLocalTime(res.data.validFrom))
                setValidTo(convertToLocalTime(res.data.validTill))
            } else {
                setNotam([])
                setRunwayCondition([])
                setValidFrom('')
                setValidTo('')
            }
        }).catch((err) => {
            console.log(err)
        })

        const currentSide = parseInt(landingrunway+'0')
        if ( currentSide < 180 ) {
            let eastFacing = ((currentSide+180)/10);
            let westFacing = ''+(currentSide/10)
            westFacing = westFacing.length === 1 ? '0'+westFacing : westFacing
            setSides([westFacing, eastFacing])
        } else {
            let eastFacing = (currentSide/10);
            let westFacing = ''+((currentSide-180)/10)
            westFacing = westFacing.length === 1 ? '0'+westFacing : westFacing
            setSides([westFacing, eastFacing])
        }
    }, [id, landingrunway])

    const convertToLocalTime = (date) => {
        // const userOffset = new Date().getTimezoneOffset() * 10000
        // const localDate = new Date(new Date(date).getTime() - userOffset);
        return new Date(date).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})
    }
    

    function renderRunway() {
        if ( sides.length === 0 || notam.length === 0 ) {
            return 'NONE';
        }
        return <div style={{display: 'table', float: 'right', clear: 'both'}}>
            <Grid container direction="row" spacing={4} className={classes.notamBox}>
                <Grid item className={classes.notamBoxInner} style={{border: "0"}}>
                    <Typography variant="subtitle1" className={classes.runwayLeft}>
                        {sides[0]}
                    </Typography>
                </Grid>
                <Grid item className={classes.notamBoxInner}>
                    <Typography variant="h6" className={classes.colorBlue}>
                        {notam[0]}
                    </Typography>
                </Grid>
                <Grid item className={classes.notamBoxInner}>
                    <Typography variant="h6" className={classes.colorBlue}>
                        {notam[1]}
                    </Typography>
                </Grid>
                <Grid item className={classes.notamBoxInner} style={{borderRight: "2px solid #ccc"}}>
                    <Typography variant="h6" className={classes.colorBlue}>
                        {notam[2]}
                    </Typography>
                </Grid>
                <Grid item className={classes.notamBoxInner} style={{border: "0"}}>
                    <Typography variant="subtitle1" className={classes.runwayRight}>
                        {sides[1]}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container direction="row" style={{width: '250px', float: 'right'}}>
                {runwayCondition.length > 0 ? runwayCondition.join('. ') : "NONE"}<br />
                VALID: {validFrom} - {validTo}
            </Grid>
        </div>
    }

    return <div>
        <Typography variant="body2">
            <b>RSC NOTAM</b><br />
            {renderRunway()}<br />
            
        </Typography>
    </div>
}