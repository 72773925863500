import React, { useState, useEffect } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, useLocation } from 'react-router-dom';
import * as axios from 'axios'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import { Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, FormControlLabel, Checkbox, Snackbar } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MultiSelect from "react-multi-select-component";
import FormHelperText from "@material-ui/core/FormHelperText";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import SearchLandingsList from './SearchLandingsList';
import MuiAlert from '@material-ui/lab/Alert';
import jwtDecode from 'jwt-decode';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.info.main
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },

}));

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export default function Search() {
  const classes = useStyles();
  const location = useLocation()
  const state = typeof location.state === 'undefined' ? null : location.state;
  console.log('state', state)

  const [order, setOrder] = React.useState( state ? state.order : 'desc');
  const [orderBy, setOrderBy] = React.useState( state ? state.orderBy : 'created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(state ? state.page : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState(state ? state.data: []);
  const root = useSelector((state) => state.root.value)
  const authid = useSelector((state) => state.authorityId.value)
  // const landingDate = useSelector((state) => state.landingDate.value)
  // const dispatch = useDispatch()
  const history = useHistory()
  // const theme = useTheme();

  const [fromDate, setFromDate] = useState(state ? state.fromDate: new Date())
  const [toDate, setToDate] = useState(state ? state.toDate: new Date())
  const [authorities, setAuthorities] = useState([])
  const [authorityId, setAuthorityId] = useState(useSelector((state) => state.authorityId.value))
  const [airports, setAirports] = useState([]);
  const [airportCode, setAirportCode] = useState(useSelector((state) => state.airportCode.value))
  const [rangeValue, setRangeValue] = useState(state ? state.rangeValue: 0)
  const [windspeed, setWindspeed] = useState(state ? state.windspeed : 'ANY')
  const [windspeedAmount, setWindspeedAmount] = useState(state ? state.windspeedAmount : '')
  const [windspeedCompare, setWindspeedCompare] = useState(state ? state.windspeedCompare : 'et')
  const [precipitation, setPrecipitation] = useState(state ? state.precipitation: 'ANY')
  const [isSaveOpen, setSaveOpen] = useState(false)
  const [isUpdateOpen, setUpdateOpen] = useState(false)
  const [reportName, setReportName] = useState('')
  const [reportUpdateName, setReportUpdateName] = useState('')
  const [isPrivate, setPrivate] = useState(true)
  const [isUpdatePrivate, setUpdatePrivate] = useState(true)

  const [runwayList, setRunwayList] = useState([])
  // const [defaultRunways, setDefaultRunways] = useState([{}])
  const [selectedRunways, setSelectedRunways] = useState([])


  const [icao, setIcao] = useState(state ? state.icao: '')
  const [callsign, setCallsign] = useState(state ? state.callsign: '')
  const [aircraftClass, setAircraftClass] = useState(state ? state.aircraftClass: -1)
  const [alert, setAlert] = useState(state ? state.alert : 'ANY')
  const [amountUnits, setAmountUnits] = useState(state ? state.amountUnits: '')

  const [alertAmount, setAlertAmount] = useState('')
  const [alertRunwayPart, setAlertRunwayPart] = useState('ANY')
  const [alertMaintain, setAlertMaintain] = useState('')
  const [loading, setLoading] = useState(false)

  const [reports, setReports] = useState([])
  const [currentReport, setCurrentReport] = useState('0')

  const [snackopen, setSnackOpen] = useState(false)
  const [snackmessage, setSnackMessage] = useState('')
  const [snackseverity, setSnackSeverity] = useState('success')

  const [maxDecel, setMaxDecel] = useState(state ? state.maxDecel: 'ANY')
  const [maxDecelAmount, setMaxDecelAmount] = useState(state ? state.maxDecelAmount: '')
  const [maxDecelCompare, setMaxDecelCompare] = useState(state ? state.maxDecelCompare: 'et')
  const [minInRange, setMinInRange] = useState(state ? state.minInRange: 'ANY')
  const [minInRangeAmount, setMinInRangeAmount] = useState(state ? state.minInRangeAmount: '')
  const [minInRangeCompare, setMinInRangeCompare] = useState(state ? state.minInRangeCompare: 'et')
  const [avgInRange, setAvgInRange] = useState(state ? state.avgInRange: 'ANY')
  const [avgInRangeAmount, setAvgInRangeAmount] = useState(state ? state.avgInRangeAmount: '')
  const [avgInRangeCompare, setAvgInRangeCompare] = useState(state ? state.avgInRangeCompare: 'et')

  const [mlMaxDecel, setMlMaxDecel] = useState(state ? state.mlMaxDecel: 'ANY')
  const [mlMaxDecelAmount, setMlMaxDecelAmount] = useState(state ? state.mlMaxDecelAmount: '')
  const [mlMaxDecelCompare, setMlMaxDecelCompare] = useState(state ? state.mlMaxDecelCompare: 'et')
  const [mlMaxDecelDelta, setMlMaxDecelDelta] = useState(state ? state.mlMaxDecelDelta: 'ANY')
  const [mlMaxDecelDeltaAmount, setMlMaxDecelDeltaAmount] = useState(state ? state.mlMaxDecelDeltaAmount: '')
  const [mlMaxDecelDeltaCompare, setMlMaxDecelDeltaCompare] = useState(state ? state.mlMaxDecelDeltaCompare: 'et')
  const [mlCurveRMS, setMlCurveRMS] = useState(state ? state.mlCurveRMS: 'ANY')
  const [mlCurveRMSAmount, setMlCurveRMSAmount] = useState(state ? state.mlCurveRMSAmount: '')
  const [mlCurveRMSCompare, setMlCurveRMSCompare] = useState(state ? state.mlCurveRMSCompare: 'et')

  const [exitDist, setExitDist] = useState(state ? state.exitDist: 'ANY')
  const [exitDistAmount, setExitDistAmount] = useState(state ? state.exitDistAmount: '')
  const [exitDistCompare, setExitDistCompare] = useState(state ? state.exitDistCompare: 'et')

  const [columns, setColumns] = useState(state ? state.columns: []);

  function setSnack (open=false, message='', severity='success') {
    setSnackOpen(open)
    setSnackMessage(message)
    setSnackSeverity(severity)
  }


  useEffect(() => {
    // console.log('useEffect1', authorityId)

    axios.get(`${WEBAPI}/authority`)
    .then((res) => {
      // console.log(res.data)
      setAuthorities(res.data);

      // set the airports list
      const authority = res.data.find((item) => {
        return item.id === (state ? state.authorityId: authorityId)
      })
      setAirports(authority.airports)

      if (authority.airports.length === 1) {
        handleAirportChange(authority.airports[0].code)
      } else {
        setRunwayList([])
        setSelectedRunways([])
      }

      if ( state ) {
        setAuthorityId(state.authorityId)
        console.log('authorityId in state')
      }
    })
    .catch((err) => {
      console.log(err);
    });


    axios.get(`${WEBAPI}/report`)
    .then((res) => {
      setReports(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

    //if ( state ) {
      // setAuthorityId(state.authorityId)
      // // console.log(res.data)
      // setAuthorities(state.authorities);

      // // set the airports list
      // const authority = state.authorities.find((item) => {
      //   return item.id === state.authorityId
      // })
      // setAirports(authority.airports)
  
      // if (authority.airports.length === 1) {
      //   handleAirportChange(authority.airports[0].code)
      // } else {
      //   setRunwayList([])
      //   setSelectedRunways([])
      // }

    //   setIcao(state.icao)      
    //   setRows(state.rows)
    // }
  }, []);


  useEffect(() => {
    if ( currentReport !== '0' ) {
      handleSubmit();
    }
  }, [currentReport])

  // update search value if one of the table parameters changes
  useEffect(() => {
    if ( state ) {
      history.push('/search', {
        reportName, 
        fromDate, 
        toDate, 
        rangeValue, 
        authorityId: state.authorityId,
        airportCode: state.airportCode,
        icao, 
        callsign, 
        aircraftClass, 
        selectedRunways,
        alert, 
        amountUnits, 
        windspeed,  
        windspeedAmount, 
        windspeedCompare, 
        precipitation, 
        data: state.data, 
        page,
        mlMaxDecel,
        mlMaxDecelAmount,
        mlMaxDecelCompare,
        mlMaxDecelDelta,
        mlMaxDecelDeltaAmount,
        mlMaxDecelDeltaCompare,
        mlCurveRMS,
        mlCurveRMSAmount,
        mlCurveRMSCompare,
        maxDecel,
        maxDecelAmount,
        maxDecelCompare,
        minInRange,
        minInRangeAmount,
        minInRangeCompare,
        avgInRange,
        avgInRangeAmount,
        avgInRangeCompare,
        exitDist,
        exitDistAmount,
        exitDistCompare,
        order,
        orderBy,
      })
    }
  }, [page, order, orderBy]);


  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleMapClick = (id) => {
  //   console.log('handleMapClick', id);
  //   history.push(`/landing/${id}`)
  // }

  // const handleDataClick = (id) => {
  //   console.log('handleDataClick', id);
  //   history.push(`/landingdata/${id}`)
  // }

  // const handleDataRawClick = (id) => {
  //   console.log('handleDataRawClick', id);
  //   history.push(`/landingdataraw/${id}`)
  // }

  // const handleAnalysisClick = (id) => {
  //   console.log('handleAnalysisClick', id);
  //   history.push(`/analysis/${id}`)
  // }


  const handleAuthorityIdChange = (id) => {
    setAuthorityId(id)

    // set the airports list
    const authority = authorities.find((item) => {
      return item.id === id
    })
    setAirports(authority.airports)

    if (authority.airports.length === 1) {
      handleAirportChange(authority.airports[0].code)
    } else {
      setRunwayList([])
      setSelectedRunways([])
    }
  }

  const handleAirportChange = (code) => {
    setAirportCode(code)

    if (code !== '') {
      axios.post(`${WEBAPI}/runway/forairport`, {airportid: code})
      .then((res) => {
        // console.log('Data: ', res.data)

        const activeRunways=[]
        res.data.map((item) => {
          if (item.active) {
            const runwaySplit = item.runwayname.split('/')
            activeRunways.push({label: runwaySplit[0], value: runwaySplit[0]})
            activeRunways.push({label: runwaySplit[1], value: runwaySplit[1]})
          }
          return item
        })

        setRunwayList(activeRunways)
        setSelectedRunways(activeRunways)
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  const handleAlertChange = (alertType) => {
    console.log('handleAlertChange', alertType)

    switch(alertType) {
      case "GS":
        setAmountUnits('Knots')
        break
      case "DC":
        setAmountUnits('g')
        break
      case "CL":
        setAmountUnits('feet')
        break
      default:
        setAlertAmount('')
        setAlertRunwayPart('ANY')
        setAlertMaintain('')
    }

    setAlert(alertType)
  }


  const handleSubmit = () => {
    // console.log('FromDate', fromDate)
    // console.log('ToDate', toDate)
    // console.log('AuthorityID', authorityId)
    // console.log('AirportCode', airportCode)
    // console.log('ICAO', icao)
    // console.log('Callsign', callsign)
    // console.log('AircraftClass', aircraftClass)
    console.log('Alert', alert)
    console.log('AlertAmount', alertAmount)
    console.log('AlertRunwayPart', alertRunwayPart)
    console.log('AlertMaintain', alertMaintain)


    const runwayList = selectedRunways.map((item) => {
      return item.label
    })

    
    setLoading(true)

    axios.post(`${WEBAPI}/landing/search`, { fromDate, toDate, timezone, authorityId, airportCode, runwayList, icao, callsign, aircraftClass, alert, alertAmount, alertRunwayPart, alertMaintain, windspeed, windspeedAmount, windspeedCompare, precipitation,
      mlMaxDecel,
      mlMaxDecelAmount,
      mlMaxDecelCompare,
      mlMaxDecelDelta,
      mlMaxDecelDeltaAmount,
      mlMaxDecelDeltaCompare,
      mlCurveRMS,
      mlCurveRMSAmount,
      mlCurveRMSCompare,

      maxDecel,
      maxDecelAmount,
      maxDecelCompare,
      minInRange,
      minInRangeAmount,
      minInRangeCompare,
      avgInRange,
      avgInRangeAmount,
      avgInRangeCompare,
      exitDist,
      exitDistAmount,
      exitDistCompare,
     })
    .then((res) => {
      console.log('Response: ', res.data)

      if (res.data.length === 0) {
        setRows([])
      } else {
        if ( rows.length > 1000 ) {
          localStorage.setItem(`search_rowsPerPage`, 15);
        }
        setRows(res.data)
        setPage(0)

        // save the search data
        history.push('/search', {
          reportName, 
          fromDate,
          toDate,
          rangeValue,
          authorityId, 
          airportCode,
          icao,
          callsign,
          aircraftClass,
          selectedRunways,
          alert,
          amountUnits,
          windspeed, 
          windspeedAmount,
          windspeedCompare,
          precipitation,
          data: res.data,
          page: 0,
          mlMaxDecel,
          mlMaxDecelAmount,
          mlMaxDecelCompare,
          mlMaxDecelDelta,
          mlMaxDecelDeltaAmount,
          mlMaxDecelDeltaCompare,
          mlCurveRMS,
          mlCurveRMSAmount,
          mlCurveRMSCompare,
          
          maxDecel,
          maxDecelAmount,
          maxDecelCompare,
          minInRange,
          minInRangeAmount,
          minInRangeCompare,
          avgInRange,
          avgInRangeAmount,
          avgInRangeCompare,

          exitDist,
          exitDistAmount,
          exitDistCompare,
          order,
          orderBy,
        })
      }
      setLoading(false)
    })
    .catch((err) => {
        console.log(err);
    })

  }

  const handleSaveReport = () => {
    const fields = {
      fromDate,
      toDate,
      timezone,
      authorityId,
      airportCode,
      rangeValue,
      windspeed,
      windspeedAmount,
      windspeedCompare,
      precipitation,
      icao,
      callsign,
      aircraftClass,
      alert,
      amountUnits,
      alertAmount,
      alertRunwayPart,
      alertMaintain,
      runwayList,
      selectedRunways,
      columns,
      mlMaxDecel,
      mlMaxDecelAmount,
      mlMaxDecelCompare,
      mlMaxDecelDelta,
      mlMaxDecelDeltaAmount,
      mlMaxDecelDeltaCompare,
      mlCurveRMS,
      mlCurveRMSAmount,
      mlCurveRMSCompare,
    }

    console.log('columns', columns)

    const user = jwtDecode( localStorage.getItem('token') )
    const params = {
      name: reportName,
      is_private: isPrivate,
      fields: JSON.stringify(fields),
    }
    console.log( params );

    axios.post(`${WEBAPI}/report`, params)
    .then((res) => {
      setSaveOpen(false)
      setReports(res.data.reports)
      setCurrentReport(res.data.current.id)
      setReportUpdateName(res.data.current.name)
      setUpdatePrivate(res.data.current.is_private)
      setReportName('')

      setSnack(true, 'Report Saved Successfully!')
    })
  }

  const handleReportChange = (e) => {
    const id = e.target.value
    setCurrentReport(id)

    if ( id !== '0' ) {
      const getReport = reports.find((item) => {
        return item.id === id
      });

      const fields = JSON.parse(getReport.fields)

      // Custom or Dynamic Range
      if ( fields.rangeValue === 0 ) {
        setFromDate(fields.fromDate)
        setToDate(fields.toDate)
      } else {
        setFromDate(dateRage[fields.rangeValue]().from); 
        setToDate(dateRage[fields.rangeValue]().to)
      }
      
      setAuthorityId(fields.authorityId)

      const authority = authorities.find((item) => {
        return item.id === fields.authorityId
      })
      setAirports(authority.airports)
  
      if (authority.airports.length === 1) {
        handleAirportChange(fields.airportCode)
      } else {
        setRunwayList([])
        setSelectedRunways([])
      }
      setRangeValue(fields.rangeValue)
      setWindspeed(fields.windspeed)
      setWindspeedAmount(fields.windspeedAmount)
      setWindspeedCompare(fields.windspeedCompare)
      setPrecipitation(fields.precipitation)
      setIcao(fields.icao)
      setCallsign(fields.callsign)
      setAircraftClass(fields.aircraftClass)
      setAlert(fields.alert)
      setAmountUnits(fields.amountUnits)
      setAlertAmount(fields.alertAmount)
      setAlertRunwayPart(fields.alertRunwayPart)
      setAlertMaintain(fields.alertMaintain)
      setRunwayList(fields.runwayList)
      setSelectedRunways(fields.selectedRunways)
      setColumns(fields.columns)
      setReportName('')
      setPrivate(true)
      setRows([]);
      setReportUpdateName(reports.find((item) => {
        return item.id === id
      }).name)
      setUpdatePrivate(reports.find((item) => {
        return item.id === id
      }).is_private)
    }
  }

  const handleUpdateReport = () => {
    const fields = {
      fromDate,
      toDate,
      timezone,
      authorityId,
      airportCode,
      rangeValue,
      windspeed,
      windspeedAmount,
      windspeedCompare,
      precipitation,
      icao,
      callsign,
      aircraftClass,
      alert,
      amountUnits,
      alertAmount,
      alertRunwayPart,
      alertMaintain,
      runwayList,
      selectedRunways,
      columns,
      mlMaxDecel,
      mlMaxDecelAmount,
      mlMaxDecelCompare,
      mlMaxDecelDelta,
      mlMaxDecelDeltaAmount,
      mlMaxDecelDeltaCompare,
      mlCurveRMS,
      mlCurveRMSAmount,
      mlCurveRMSCompare,
    }

    const user = jwtDecode( localStorage.getItem('token') )
    const params = {
      reportid: currentReport,
      name: reportUpdateName,
      is_private: isUpdatePrivate,
      fields: JSON.stringify(fields),
    }
    console.log( params );

    axios.put(`${WEBAPI}/report`, params)
    .then((res) => {
      // @todo: add reports to the list
      setUpdateOpen(false)
      setReports(res.data.reports)
      setSnack(true, 'Report Updated Successfully!')
    })
  }

  const handleDeleteReport = () => {
    const user = jwtDecode( localStorage.getItem('token') )
    const params = {
      reportid: currentReport,
    }
    console.log( params );

    axios.delete(`${WEBAPI}/report`, {data: params})
    .then((res) => {
      console.log(res)
      setUpdateOpen(false)
      setReports(res.data.reports)
      setCurrentReport('0')
      setSnack(true, 'Report Deleted Successfully!')
    })
  }

  const _role = useSelector((state) => state.role.value)
  const _airportCode   =   useSelector((state) => state.airportCode.value);

  const RestrictAuthorities = () => {
    if ( _role !== "operator") {
      return authorities?.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
      })
    } else {
      return authorities?.filter((x) => {
        return x.airports.find((y) => {
          return y.code === _airportCode
        })
      }).map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
      })
    }
  };

  const dateRangeLables = ['Custom', 'Year to Date', 'Last Month', 'Current Month', 'Past Week', 'Past 24 Hours'];
  const dateRage  = [
    /* Custom */
    () => {
      return {from: fromDate, to: toDate}
    },
    /* Year to Date */
    () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return {from: new Date(year, 0, 1), to: new Date(year, month, day)}
    },
    /* Last Month */
    () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return {from: new Date(year, month - 1, 1), to: new Date(year, month, 0)}
    },
    /* Current Month */
    () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return {from: new Date(year, month, 1), to: new Date(year, month, day)}
    },
    /* Past Week */
    () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return {from: new Date(year, month, day - 7), to: new Date(year, month, day)}
    },
    /* Past 24 Hours */
    () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return {from: new Date(year, month, day - 1), to: new Date(year, month, day)}
    }
  ]

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        { _role !== "operator" && ( 
        <Grid container direction={'row'} justify="flex-start" alignItems="flex-end" className={classes.formControls}>
          <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }} >
            <InputLabel>Reports</InputLabel>
            <Select
              value={currentReport}
              onChange={(e) => handleReportChange(e)}
              id="reports"
              label="Reports"
              name="reports"
            >
              <MenuItem key='0' value='0'>Search...</MenuItem>
              {reports.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> ) }
        <Grid container direction={'row'} justify="flex-start" alignItems="flex-end" className={classes.formControls}>
            <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
              <InputLabel>Range</InputLabel>
              <Select
                value={rangeValue}
                onChange={(event) => {setRangeValue(event.target.value); setFromDate(dateRage[event.target.value]().from); setToDate(dateRage[event.target.value]().to)}}
                id="range"
                label="Range"
                name="range"
              >
                {dateRangeLables.map((item, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} size={"small"} style={{ width: 150 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="fromdate"
                  label="From Date"
                  value={fromDate}
                  autoOk={true}
                  onChange={(date) => {setFromDate(date); setRangeValue(0);} }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>

            <FormControl className={classes.formControl} size={"small"} style={{ width: 150 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="todate"
                  label="To Date"
                  value={toDate}
                  autoOk={true}
                  onChange={(date) => {setToDate(date); setRangeValue(0);} }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>


            <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ minWidth: 200 }} >
              <InputLabel>Authority</InputLabel>
                <Select
                  value={authorityId}
                  onChange={(e) => handleAuthorityIdChange(e.target.value)}
                  id="authorityid"
                  label="Authority"
                  name="authorityid"
                >
                  {RestrictAuthorities()}
                </Select>
                {/* <FormHelperText style={{display: (formik.touched.authorityid && Boolean(formik.errors.authorityid) ? 'block' : 'none' ) }} >Authority is required</FormHelperText> */}
              </FormControl>


            <FormControl variant="outlined" className={classes.formControl} size={"small"}>
              <InputLabel>Airport</InputLabel>
              <Select
                value={airportCode}
                onChange={(e) => handleAirportChange(e.target.value)}
                id="airport"
                label="Airport"
                name="airport"
              >
                {airports?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.code}>
                      {item.code}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* <FormControl variant="outlined" className={classes.formControl} size={"small"}>
              <InputLabel>Runway</InputLabel>
              <Select
                value={runway}
                onChange={(e) => setRunway(e.target.value)}
                id="runway"
                label="Runway"
                name="runway"
              >
                {runways?.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}

            <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <TextField
              size="small"
              style={{ width: 100 }}
              value={icao}
              onChange={(e) => setIcao(e.target.value)}
              variant="outlined"
              id="icao"
              label="ICAO"
              name="icao"
              autoComplete=""
            />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl} size={"small"}>
            <TextField
              size="small"
              style={{ width: 100 }}
              value={callsign}
              onChange={(e) => setCallsign(e.target.value)}
              variant="outlined"
              id="callsign"
              label="Callsign"
              name="callsign"
              autoComplete=""
            />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl} size={"small"} >
              <InputLabel>Class</InputLabel>
              <Select
                value={aircraftClass}
                onChange={(e) => setAircraftClass(e.target.value)}
                id="class"
                label="Class"
                name="class"
              >
                <MenuItem key='-1' value='-1'>ANY</MenuItem>
                <MenuItem key='0' value='0'>Unknown</MenuItem>
                <MenuItem key='1' value='1'>Light (&lt;15,500 lbs)</MenuItem>
                <MenuItem key='2' value='2'>Small (15,500 to 75,000 lbs)</MenuItem>
                <MenuItem key='3' value='3'>Large (75,000 to 300,000 lbs)</MenuItem>
                <MenuItem key='4' value='4'>High Vortex Large (e.g. B757)</MenuItem>
                <MenuItem key='5' value='5'>Heavy (&gt; 300,000 lbs)</MenuItem>
                <MenuItem key='6' value='6'>Highly Maneuverable (&gt; 5G acceleration)</MenuItem>
              </Select>
            </FormControl>

            <div>
            <FormHelperText style={{marginLeft: 10}}>Runways</FormHelperText>
            <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{minWidth: 300}}  >
              <MultiSelect
                options={runwayList}
                value={selectedRunways}
                onChange={setSelectedRunways}
                labelledBy="Runways"
                selectAllLabel="All Runways"
                overrideStrings={{"allItemsAreSelected": "All runways are selected."}}
              />
            </FormControl>
            </div>

        </Grid>

        <Grid container direction={'row'} justify="flex-start" alignItems="flex-start" className={classes.formControls}>
          <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <InputLabel>Alerts</InputLabel>
            <Select
              value={alert}
              onChange={(e) => handleAlertChange(e.target.value)}
              id="class"
              label="Alerts"
              name="class"
            >
              <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
              <MenuItem key='NO' value='NO'>No</MenuItem>
              <MenuItem key='YES' value='YES'>Yes</MenuItem>
              <MenuItem key='GS' value='GS'>Ground Speed</MenuItem>
              {
                root ? <MenuItem key='DCML' value='DCML'>Deceleration</MenuItem>
                : <MenuItem key='DC' value='DC'>Deceleration</MenuItem>
              }
              <MenuItem key='CL' value='CL'>Centerline Compliance</MenuItem>
            </Select>
          </FormControl>

          {
            alert === 'GS' || alert === 'DC' || alert === 'CL' ?
            <div id="alert_variables">
              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  label="Amount"
                  name="amount"
                  value={alertAmount}
                  onChange={(e) => setAlertAmount(e.target.value)}
                  endAdornment={<InputAdornment position="end">{amountUnits}</InputAdornment>}
                  labelWidth={70}
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }} >
                <InputLabel>Runway</InputLabel>
                <Select
                  value={alertRunwayPart}
                  onChange={(e) => setAlertRunwayPart(e.target.value)}
                  id="runwaypart"
                  label="Runway"
                  name="runwaypart"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='FIRST_THIRD' value='FIRST_THIRD'>First Third</MenuItem>
                  <MenuItem key='MIDDLE_THIRD' value='MIDDLE_THIRD'>Middle Third</MenuItem>
                  <MenuItem key='LAST_THIRD' value='LAST_THIRD'>Last Third</MenuItem>
                  <MenuItem key='FIRST_HALF' value='FIRST_HALF'>First Half</MenuItem>
                  <MenuItem key='SECOND_HALF ' value='SECOND_HALF'>Second Half</MenuItem>
                  <MenuItem key='LAST_500FT' value='LAST_500FT'>Last 500 ft</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                <InputLabel htmlFor="maintain">Maintain</InputLabel>
                <OutlinedInput
                  id="maintain"
                  // label="Amount"
                  name="maintain"
                  value={alertMaintain}
                  onChange={(e) => setAlertMaintain(e.target.value)}
                  endAdornment={<InputAdornment position="end">feet</InputAdornment>}
                  labelWidth={70}
                />
              </FormControl>
            </div>
            :
            null
          }

          <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <InputLabel>Windspeed</InputLabel>
            <Select
              value={windspeed}
              onChange={(e) => setWindspeed(e.target.value)}
              id="windspeed"
              label="Windspeed"
              name="windspeed"
            >
              <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
              <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
            </Select>
          </FormControl>

          {
            windspeed === 'CUSTOM' ?
            <div id="windspeed_variables">
              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                <InputLabel>Compare</InputLabel>
                <Select
                  value={windspeedCompare}
                  onChange={(e) => setWindspeedCompare(e.target.value)}
                  id="windspeed_compare"
                  label="Compare"
                  name="windspeed_compare"
                >
                  <MenuItem key='et' value='et'>Equal To</MenuItem>
                  <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                  <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                </Select>
              </FormControl>
              
              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  label="Amount"
                  name="amount"
                  value={windspeedAmount}
                  onChange={(e) => setWindspeedAmount(e.target.value)}
                  endAdornment={<InputAdornment position="end">knots</InputAdornment>}
                  labelWidth={70}
                />
              </FormControl>
            </div>
            : null
          }

          <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <InputLabel>Precipitation</InputLabel>
            <Select
              value={precipitation}
              onChange={(e) => setPrecipitation(e.target.value)}
              id="precipitation"
              label="Precipitation"
              name="precipitation"
            >
              <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
              <MenuItem key='clear' value='clear'>Clear</MenuItem>
              <MenuItem key='rain' value='rain'>Rain</MenuItem>
              <MenuItem key='snow' value='snow'>Snow</MenuItem>
              <MenuItem key='sleet' value='sleet'>Sleet</MenuItem>
              <MenuItem key='hail' value='hail'>Hail</MenuItem>
              <MenuItem key='cloud' value='cloud'>Cloud</MenuItem>
            </Select>
          </FormControl>

          {
            root ? 
            <>
              <FormControl variant="outlined" className={classes.formControl} size={"small"} >
                <InputLabel>Max Decel</InputLabel>
                <Select
                  value={maxDecel}
                  onChange={(e) => setMaxDecel(e.target.value)}
                  id="max_deceleration"
                  label="Max Decel"
                  name="max_deceleration"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                maxDecel === 'CUSTOM' ?
                <div id="max_decel_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={maxDecelCompare}
                      onChange={(e) => setMaxDecelCompare(e.target.value)}
                      id="max_decel_compare"
                      label="Compare"
                      name="max_decel_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={maxDecelAmount}
                      onChange={(e) => setMaxDecelAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">g</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }




              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{width: "230px"}}>
                <InputLabel>Min Decel from 100 to 70kts</InputLabel>
                <Select
                  value={minInRange}
                  onChange={(e) => setMinInRange(e.target.value)}
                  id="min_decel_in_range"
                  label="Min Decel from 100 to 70kts"
                  name="min_decel_in_range"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                minInRange === 'CUSTOM' ?
                <div id="min_decel_in_range_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={minInRangeCompare}
                      onChange={(e) => setMinInRangeCompare(e.target.value)}
                      id="min_decel_in_range_compare"
                      label="Compare"
                      name="min_decel_in_range_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={minInRangeAmount}
                      onChange={(e) => setMinInRangeAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">g</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }



              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{width: "230px"}}>
                <InputLabel>Avg Decel from 100 to 70kts</InputLabel>
                <Select
                  value={avgInRange}
                  onChange={(e) => setAvgInRange(e.target.value)}
                  id="avg_decel_in_range"
                  label="Avg Decel from 100 to 70kts"
                  name="avg_decel_in_range"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                avgInRange === 'CUSTOM' ?
                <div id="avg_decel_in_range_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={avgInRangeCompare}
                      onChange={(e) => setAvgInRangeCompare(e.target.value)}
                      id="avg_decel_in_range_compare"
                      label="Compare"
                      name="avg_decel_in_range_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={avgInRangeAmount}
                      onChange={(e) => setAvgInRangeAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">g</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }
            </>
            : null
          }

          {
            root ? 
            <>
              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                <InputLabel>ML Max Decel</InputLabel>
                <Select
                  value={mlMaxDecel}
                  onChange={(e) => setMlMaxDecel(e.target.value)}
                  id="ml_max_decel"
                  label="ML Max Decel"
                  name="ML max_decel"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                mlMaxDecel === 'CUSTOM' ?
                <div id="ml_max_decel_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={mlMaxDecelCompare}
                      onChange={(e) => setMlMaxDecelCompare(e.target.value)}
                      id="ml_max_decel_compare"
                      label="Compare"
                      name="ml_max_decel_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel htmlFor="ml_max_decel_amount">Amount</InputLabel>
                    <OutlinedInput
                      id="ml_max_decel_amount"
                      label="Amount"
                      name="ml_max_decel_amount"
                      value={mlMaxDecelAmount}
                      onChange={(e) => setMlMaxDecelAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">g</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }




              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                <InputLabel>ML Max Decel Delta</InputLabel>
                <Select
                  value={mlMaxDecelDelta}
                  onChange={(e) => setMlMaxDecelDelta(e.target.value)}
                  id="ml_max_decel_delta"
                  label="ML Max Decel Delta"
                  name="ml_max_decel_delta"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                mlMaxDecelDelta === 'CUSTOM' ?
                <div id="ml_max_decel_delta_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={mlMaxDecelDeltaCompare}
                      onChange={(e) => setMlMaxDecelDeltaCompare(e.target.value)}
                      id="ml_max_decel_delta_compare"
                      label="Compare"
                      name="ml_max_decel_delta_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={mlMaxDecelDeltaAmount}
                      onChange={(e) => setMlMaxDecelDeltaAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">g</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }



              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                <InputLabel>ML Curve RMS</InputLabel>
                <Select
                  value={mlCurveRMS}
                  onChange={(e) => setMlCurveRMS(e.target.value)}
                  id="ml_curve_rms"
                  label="ML Curve RMS"
                  name="ml_curve_rms"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                mlCurveRMS === 'CUSTOM' ?
                <div id="ml_curve_rms_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={mlCurveRMSCompare}
                      onChange={(e) => setMlCurveRMSCompare(e.target.value)}
                      id="ml_curve_rms_compare"
                      label="Compare"
                      name="ml_curve_rms_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={mlCurveRMSAmount}
                      onChange={(e) => setMlCurveRMSAmount(e.target.value)}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }


              <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 200 }}>
                <InputLabel>Runway Exit Distance</InputLabel>
                <Select
                  value={exitDist}
                  onChange={(e) => setExitDist(e.target.value)}
                  id="exit_dist"
                  label="Runway Exit Distance"
                  name="ml_cexit_disturve_rms"
                >
                  <MenuItem key='ANY' value='ANY'>ANY</MenuItem>
                  <MenuItem key='CUSTOM' value='CUSTOM'>CUSTOM</MenuItem>
                </Select>
              </FormControl>

              {
                exitDist === 'CUSTOM' ?
                <div id="exit_dist_variables">
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel>Compare</InputLabel>
                    <Select
                      value={exitDistCompare}
                      onChange={(e) => setExitDistCompare(e.target.value)}
                      id="exit_dist_compare"
                      label="Compare"
                      name="exit_dist_compare"
                    >
                      <MenuItem key='et' value='et'>Equal To</MenuItem>
                      <MenuItem key='gt' value='gt'>Greater Than</MenuItem>
                      <MenuItem key='lt' value='lt'>Less Than</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl variant="outlined" className={classes.formControl} size={"small"} style={{ width: 150 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      label="Amount"
                      name="amount"
                      value={exitDistAmount}
                      onChange={(e) => setExitDistAmount(e.target.value)}
                      endAdornment={<InputAdornment position="end">feet</InputAdornment>}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                
                : null
              }
            </>
            : null
          }

        </Grid>


        <Grid container direction={'row'} justify="flex-start" alignItems="flex-end" className={classes.formControls}>
          <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Search
            </Button>
          </FormControl>

          { _role !== "operator" && ( <FormControl variant="outlined" className={classes.formControl} size={"small"} >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => setSaveOpen(true)}
              disabled={_role === "sales"}
            >
              Save As Report
            </Button>
          </FormControl> ) }

          {
            currentReport !== '0' ?
            <>
              <FormControl variant="outlined" className={classes.formControl} size={"small"} >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => setUpdateOpen(true)}
                  disabled={_role === "sales"}
                >
                  Update Report
                </Button>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl} size={"small"} >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleDeleteReport}
                  disabled={_role === "sales"}
                >
                  Delete Report
                </Button>
              </FormControl>
            </>
            : null
          }
        </Grid>
      </Paper>

      {
        loading ?
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress color="secondary" />
        </Box>
        :
        // <LandingsList data={rows} pageName='search'></LandingsList>'
        <SearchLandingsList 
          data={rows} 
          pageName='search' 
          columns={columns} setColumns={setColumns}
          page={page} setPage={setPage} 
          order={order} setOrder={setOrder} 
          orderBy={orderBy} setOrderBy={setOrderBy} />
      }

      <Dialog open={isSaveOpen} onClose={() => setSaveOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Save Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To save this report, please enter a name below.
          </DialogContentText>
          <FormControl variant="outlined" style={{width: '100%'}}>
            <TextField
              autoFocus
              margin="dense"
              id="report_name"
              label="Report Name"
              type="text"
              fullWidth
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
          </FormControl>
          {/* <FormControl component="fieldset" style={{width: '100%'}}>
            <FormControlLabel control={<Checkbox checked={isPrivate} name="private" onChange={(e) => setPrivate(e.target.checked)} />} label="Private" />
          </FormControl> */}
            
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveReport()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={isUpdateOpen} onClose={() => setUpdateOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update this report, please enter a name below.
          </DialogContentText>
          <FormControl variant="outlined" style={{width: '100%'}}>
            <TextField
              autoFocus
              margin="dense"
              id="report_name"
              label="Report Name"
              type="text"
              fullWidth
              value={reportUpdateName}
              onChange={(e) => setReportUpdateName(e.target.value)}
            />
          </FormControl>
          {/* <FormControl component="fieldset" style={{width: '100%'}}>
            <FormControlLabel control={<Checkbox checked={isUpdatePrivate} name="private" onChange={(e) => setUpdatePrivate(e.target.checked)} />} label="Private" />
          </FormControl> */}
            
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleUpdateReport()} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackopen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert severity={snackseverity} onClose={() => setSnackOpen(false)}>
          {snackmessage}
        </Alert>
      </Snackbar>
    </div>
  );
}