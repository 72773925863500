import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
// import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
// import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from 'react-router-dom';
import * as axios from 'axios'
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
// import { useSelector, useDispatch } from 'react-redux'
// import { setLandingDate } from '../redux/landingDateSlice'
// import { setAirportCode } from '../redux/airportCodeSlice'
// import { format } from 'date-fns'
// import { Button } from '@material-ui/core';
import { alertTypes, runwayArea } from '../helpers/helper'
import LandingBanner from './LandingBanner';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import RestrictedMLAccess from '../lib/RestrictedMLAccess';


const WEBAPI = process.env.REACT_APP_WEB_API;

const ML_COMPARION = {
  'gt': '> ',
  'lt': '< ',
  'et': '= ',
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  { id: 'type', numeric: false, disablePadding: false, label: 'Type', isNeedAccess: false },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount', isNeedAccess: false },
  { id: 'runway_area', numeric: false, disablePadding: false, label: 'Runway Part', isNeedAccess: false },
  { id: 'maintain', numeric: true, disablePadding: false, label: 'Maintain', isNeedAccess: false },
  { id: 'ml_max_g', numeric: true, disablePadding: false, label: 'ML Max G', isNeedAccess: true },
  { id: 'ml_max_delta_g', numeric: true, disablePadding: false, label: 'ML Max Delta G', isNeedAccess: true },
  { id: 'ml_curve_rms', numeric: true, disablePadding: false, label: 'ML Curve RMS', isNeedAccess: true },

];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox className={classes.headerCheckbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all airports' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          headCell.isNeedAccess ? /** Need special access */
            <RestrictedMLAccess>
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </RestrictedMLAccess> : /** Does NOT Need special access */
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  iconButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  // const history = useHistory();

  // const handleDelete = (id) => {
  //   console.log('handleDelete', id);
  // }

  // const handleMapClick = (id) => {
  //   //console.log('handleEdit', id);
  //   history.push(`/landing/${id}`)
  // }

  // const handleExport = () => {
  //   let content = ""
  //   headCells.map((item) => {
  //     return content += item.label + ","
  //   })
  //   content += "\r\n"

  //   data.map((item) => {
  //     let row = ""
  //     for (let idx=0; idx<headCells.length; idx++)
  //     {
  //       // console.log(headCells[idx].id)

  //       if (headCells[idx].id == 'utmX') {
  //         const x = item.utm.coordinates[0]
  //         // console.log('X:', x)
  //         row += x + ","
  //       } else if (headCells[idx].id == 'utmY') {
  //         const y = item.utm.coordinates[1]
  //         // console.log('Y:', y)
  //         row += y + ","
  //       } else {
  //         row += item[headCells[idx].id] + ","
  //       }
  //     }

  //     row += "\r\n"

  //     return content += row
  //   })
    
  //   var link = document.createElement('a');
  //   link.download = callsign.trim() + '.csv';
  //   link.href = 'data:,' + content;
  //   link.click();
  // }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Landing Alerts
            </Typography>
          </Grid>

          {/* <Grid item>
            <Button variant="contained" startIcon={<GetAppOutlinedIcon />} color="primary" onClick={handleExport} >
              Export
            </Button>
          </Grid> */}

        </Grid>
      )}

      {/* {numSelected > 0 ? (
        <>
          <IconButton 
            title="Map"
            className={classes.iconButton}
            onClick={() => handleMapClick(id)}>
            <MapOutlinedIcon />
          </IconButton>

      </>
    ) : null} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  callsign: PropTypes.string.isRequired,
  landingrunway: PropTypes.string.isRequired,
  // data: PropTypes.arrayOf(PropTypes.array)
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(2)
  },
  paper: {
    // width: '100%',
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.info.main
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCheckbox: {
    display: 'none'
  },
  formControls: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },

  alerts: {
    margin: theme.spacing(2)
  }

}));

export default function LandingAlerts() {
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const { id } = useParams()

  const [airportName, setAirportName] = useState('')
  const [airportCode, setAirportCode] = useState('')
  const [landingDate, setLandingDate] = useState('')
  const [landingRunway, setLandingRunway] = useState('')
  const [icao, setIcao] = useState('')
  const [callsign, setCallsign] = useState('')
  const [init, setInit] = useState(false)
  const [prophidden, setHidden] = useState(false)
  const [alerts, setAlerts] = useState(0)
  const [pattern, setPattern] = useState('')

  const root = useSelector(state => state.root.value)
  const role = useSelector(state => state.role.value)

  useEffect(() => {
    // console.log('landingID: ', id)
    
    if (id !== undefined) {
      axios.get(`${WEBAPI}/landing/${id}`)
      .then((res) => {
          const d = res.data[0]

          if ( !(root || role === "admin") ) {
            if ( d.hidden === true ) {
              // redirect to landing page
              history.push('/landings')
            }
          }

          setAirportName(d.runway.airport.airportname)
          setAirportCode(d.runway.airport.code)
          setLandingDate(d.created)
          setLandingRunway(d.landingrunway)
          setIcao(d.icaoaddress)
          setCallsign(d.callsign)
          setHidden(d.hidden)
          setAlerts(d.landingalerts.length)
          setPattern(d.landing_pattern)

          // console.log('Data:', d)
          if (d.landingdata.length === 0) {
            setRows([])
          } else {
            // console.log(d)
            setRows(d.landingalerts)
          }
          
          setInit(true)
        })
      .catch((err) => {
        console.log(err);
      })
    }

  }, [id]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div >
        {
          init ?
          <>
          <div className={classes.root}>
            <LandingBanner 
              airportname={airportName} 
              airportcode={airportCode} 
              created={landingDate} 
              landingrunway={landingRunway} 
              icaoaddress={icao} 
              callsign={callsign} 
              id={id} 
              prophidden={prophidden} 
              alerts={alerts} 
              pattern={pattern}/>
          </div>

          <div className={classes.alerts}>
          <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} id={selected[0]} handleDelete={null} callsign={callsign} landingrunway={landingRunway} data={rows} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
  
                    if ( row.type !== 'DCML' ) {
                      return (
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell component="th" id={row.id} scope="row" padding="none">{row.code}</TableCell> */}
                          <TableCell align="left">{alertTypes.get(row.type)}</TableCell>
                          <TableCell align="right">{row.amount}</TableCell>
                          <TableCell align="left">{runwayArea.get(row.runway_area)}</TableCell>
                          <TableCell align="right">{row.maintain}</TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="right">-</TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <RestrictedMLAccess>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            {/* <TableCell component="th" id={row.id} scope="row" padding="none">{row.code}</TableCell> */}
                            <TableCell align="left">{alertTypes.get(row.type)}</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="left">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">{row.ml_max_g_compare === "no" ? "-":  ML_COMPARION[row.landinganalysis.ml_max_g_compare]+row.ml_max_g}</TableCell>
                            <TableCell align="right">{row.ml_max_delta_g_compare === "no" ? "-":  ML_COMPARION[row.ml_max_delta_g_compare]+row.ml_max_delta_g}</TableCell>
                            <TableCell align="right">{row.ml_curve_rms_compare  === "no" ? "-":  ML_COMPARION[row.ml_curve_rms_compare]+row.ml_curve_rms}</TableCell>
                          </TableRow>
                        </RestrictedMLAccess>
                      );
                    }
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
      </Paper>
      </div>
          </>
            :
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
              <CircularProgress color="secondary" />
            </Box>
        }
    </div>
  );
}
