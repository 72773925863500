import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max, path } from 'd3';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const HeightAGL = (props) => {
    const [decelerationDistanceData, setDecelerationDistanceData] = useState(props.data)
    const [touchdown, setTouchdown] = useState(props.touchdown)
    const [heightAGL, setHeightAGL] = useState(props.heightAGL)
    const [runwayLength, setRunwayLength] = useState(props.length)
    const [glideSlopeTouchdown, setGlideSlopeTouchdown] = useState(props.glideslope_touchdown)
    const [slope_ht, setSlope_ht] = useState(props.slope_ht)
    const [slope_dist, setSlope_dist] = useState(props.slope_distance)
    const [pastThreshold, setPastThreshold] = useState(props.pastThreshold)
    const svgRef = useRef()

    useEffect(() => {
        console.log('heightAGL', heightAGL)
        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });

            const maxTDP = Math.max(touchdown, glideSlopeTouchdown)
            const xMax = Math.min(maxTDP, runwayLength) * 2

            const xScale = scaleLinear()
                .domain([0, xMax])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = 0 
            const yMax = Math.max( slope_ht, heightAGL )

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));



            // Glide Slope Line
            const glideSlopeLine = g.selectAll('.glideslope-line').data([null])
            glideSlopeLine
                .enter().append("line")
                .attr("class", 'glideslope-line')
            .merge(glideSlopeLine)
                .attr("x1", 0)
                .attr("y1", yScale(slope_ht))
                .attr("x2", xScale(slope_dist)-1)
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Vertical Line
            const glideSlopeVLine = g.selectAll('.glideslope-vline').data([null])
            glideSlopeVLine
                .enter().append("line")
                .attr("class", 'glideslope-vline')
            .merge(glideSlopeVLine)
                .attr("x1", xScale(slope_dist))
                .attr("y1", 10)
                .attr("x2", xScale(slope_dist))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Label
            const glideSlopeTitle = g.selectAll('.glideslope-title').data([null])
            glideSlopeTitle
                .enter().append("text")
                .attr('class', 'glideslope-title')
            .merge(glideSlopeTitle)
                .attr('fill', '#368029')
                .attr("x", xScale(slope_dist) + 5)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .html("3&deg; Glide Slope TDP");



            // Touchdown Slope Line
            const touchdownSlopeLine = g.selectAll('.touchdownslope-line').data([null])
            touchdownSlopeLine
                .enter().append("line")
                .attr("class", 'touchdownslope-line')
            .merge(touchdownSlopeLine)
                .attr("x1", 0)
                .attr("y1", yScale(heightAGL))
                .attr("x2", xScale(touchdown)-1)
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#338DCD');            

            // Touchdown Vertical Line
            const touchdownLine = g.selectAll('.touchdown-vline').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-vline')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdown))
                .attr("y1", 30)
                .attr("x2", xScale(touchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#338DCD')
                .attr("x", xScale(touchdown) + 5)             
                .attr("y", 40)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");
         

            // 7s Past Touchdown Label
            const _7stouchdownTitle = g.selectAll('.touchdown-title7s').data([null])
            _7stouchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title7s')
            .merge(_7stouchdownTitle)
                .attr('fill', '#338DCD')
                .attr("x", xScale(pastThreshold) + 5)             
                .attr("y", innerHeight - 10)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated 7s past Threshold");

            const _7sPastThrespastThresholdDot = g.selectAll('.touchdown-dot7s').data([null])
            _7sPastThrespastThresholdDot
                .enter().append("circle")
                .attr('class', 'touchdown-dot7s')
            .merge(_7sPastThrespastThresholdDot)
                .attr('stroke','black')
                .attr('r','3')
                .attr("cx", xScale(pastThreshold))
                .attr("cy", innerHeight )
                .attr("fill", '#338DCD');
    

            // // plot the line
            // const lineGenerator = line()
            //     .x((value, index) => xScale(value.et))
            //     .y((value, index) => yScale(value.deceleration))
            //     .curve(curveBasis)

            // g.selectAll('.line')
            //     .data([decelerationTimeData])
            //     .join('path')
            //     .attr('class', 'line')
            //     .attr('d', value => lineGenerator(value))
            //     .attr('fill', 'none')
            //     .attr('stroke', 'black')
            //     .attr('stroke-width', '1')

            // // draw the points
            // g.selectAll('.dots')
            //     .data(decelerationTimeData)
            //     .join("circle")
            //     .attr('class', 'dots')
            //     .attr('stroke','black')
            //     .attr('r','2')
            //     .attr('fill','black')
            //     .attr('cx', (d) => xScale(d.et))
            //     .attr('cy', (d) => yScale(d.deceleration))

        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: window.innerWidth-20,
                height: 300,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Distance (ft)',
                yAxisLabel: 'Height AGL (ft)'
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    return ( 
        <svg ref={svgRef}></svg>
    );
}

export default HeightAGL;
