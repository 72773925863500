// "Our YEG High Wind Action Plan must be placed into effect when gusting / sustained winds exceed 60 km per hour
// On the dashboard if we could see an alert when winds are gusting / sustained at 50km and warning when exceeding 60km per hour."

import { IconButton, Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';

const styles = {
    windAlerts: {
        color: "white",
        fontSize: "12px",
        padding: "0 10px",
        borderRadius: "10px",
        width: "300px",
        display: "flex",
        justifyContent: "space-between",
        float: "right"
    }
}

export function AdewWindAlerts({weather}) {

    if (!weather) {
        return "";
    }

    const getBgColor = (windSpeedKm, windeSpeedGust) => {
        if (windSpeedKm >= 60 || windeSpeedGust >= 60) {
            return "red";
        } else if (windSpeedKm >= 50 || windeSpeedGust >= 50) {
            return "orange";
        } else {
            return "green";
        }
    }

    console.log("weather", weather);
    const windSpeedKt = weather.windspeed_knots;
    const windSpeedKm = Math.round(windSpeedKt * 1.852 * 100) / 100;

    const windSpeedGustKt = weather.windgust_knots;
    const windSpeedGustKm = Math.round(windSpeedGustKt * 1.852 * 100) / 100;

    return <div className="wind-alerts" style={{...{ background: getBgColor(windSpeedKm, windSpeedGustKm) }, ...styles.windAlerts}}>
        <span style={{display: "inline", marginTop: "12px"}}>Wind Speed: {windSpeedKm} km/hr<br />Wind Speed Gust: {windSpeedGustKm || 0} km/hr<br /></span>
        <IconButton>
            <Tooltip title={<span>This data is based on the most last METAR available. <br /> Red: Alert <br />Orange: Waring <br />Green: Normal</span>}>
                <HelpIcon />
            </Tooltip>
        </IconButton>
    </div>
}