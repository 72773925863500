import React, { useState, useEffect } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import * as axios from "axios";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tooltip from '@material-ui/core/Tooltip';
import { Badge, Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import { set } from "date-fns";

const WEBAPI = process.env.REACT_APP_WEB_API;
const PUBLIC_URL = process.env.PUBLIC_URL;

const iconStyle = {
  position: 'relative',
  top: '2px'
};

const kAlertStyle = {
  position: 'relative',
  top: '-5px',
  marginRight: '7px'
}

const useStyles = makeStyles((theme) => ({
  sources: {
    position: 'relative',
    top: '-5px',
  }
}));

const Weather = (props) => {
  
  const classes = useStyles();
  const [styles, setStyles] = useState(props.className);
  const theme = useTheme();
  const small_screens = useMediaQuery(theme.breakpoints.down("xs"));

  const [temperature, setTemperature] = useState("");
  const [dewpoint, setDewpoint] = useState("");
  const [pressure, setPressure] = useState("");
  const [windSpeed, setWindSpeed] = useState("");
  const [windDirection, setWindDirection] = useState("");
  const [windDirectionTitle, setWindDirectionTitle] = useState("");
  const [condition, setCondition] = useState("");
  const [airportCode, setAirportCode] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [sources, setSources] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentSource, setCurrentSource] = useState(null);

  useEffect(() => {
    if (props.code === "") return;

    setTemperature("");
    setDewpoint("");
    setPressure("");
    setWindSpeed("");
    setWindDirection("");
    setWindDirectionTitle("");
    setCondition("");
    setLoading(true);

    const code = props.code;
    console.log("code", code);
    let ts = props.ts;
    if (ts === undefined) ts = new Date().toISOString();

    var dataPath = '';
    if ( code === 'CYOW' && props.id ) {
      dataPath = '/vaisala';
    }
    if ( props.id ) {
      axios
        .get(`${WEBAPI}/weather2${dataPath}?code=${code}&ts=${ts}&landingid=${props.id}`)
        .then((res) => {
          console.log("metar", res.data);

          if ( !res.data ) {
            setTemperature(0);
            setDewpoint(0);
            setPressure(0);
            setWindSpeed(0);
            setWindDirection(0);
            setWindDirectionTitle(0 + "°");
            setCondition('clear');
            setCurrentSource(null);
            setLoading(false)

            return;
          }

          let data = res.data;
          let weatherSources = Object.keys(res.data).filter((src) => Object.keys(res.data[src]).length).sort();

          if (weatherSources.length > 0 && !weatherSources.includes('code')) {
            setSources(weatherSources);
            setWeatherData(data);
            
            const firstSource = weatherSources[0];
            setTemperature(res.data[firstSource].temperature);
            setDewpoint(res.data[firstSource].dewpoint);
            setPressure(res.data[firstSource].pressure);
            setWindSpeed(res.data[firstSource].windspeed_knots);
            setWindDirection(res.data[firstSource].wind_direction);
            setWindDirectionTitle(res.data[firstSource].wind_direction + "°");
            setCondition(res.data[firstSource].condition);
            setCurrentSource(firstSource);
            setLoading(false)
          } else {
            weatherSources = [res.data.source]
            setSources(weatherSources);

            data = {}
            data[res.data.source] = res.data;
            setWeatherData(data);

            setTemperature(res.data.temperature);
            setDewpoint(res.data.dewpoint);
            setPressure(res.data.pressure);
            setWindSpeed(res.data.windspeed_knots);
            setWindDirection(res.data.wind_direction);
            setWindDirectionTitle(res.data.wind_direction + "°");
            setCondition(res.data.condition);
            setCurrentSource(res.data.source);
            setLoading(false)
          }

        })
        .catch((err) => {
          console.log(err);
        });

      
    } else {
      /**
       * - fetch from vaisala data
       * - store current date + 5 minutes in local storage
       * - if current date + 5 minutes is greater than local storage date, fetch new data
       */
      const vaisalaData = JSON.parse(localStorage.getItem('vaisalaData'));

      var needNewData = false;
      if ( vaisalaData === null ) {
        needNewData = true;
      } else if (vaisalaData.date <= new Date().getTime()) {
        needNewData = true;
      }

      if ( needNewData || dataPath === "" ) {
        axios.get(`${WEBAPI}/weather2/currentweather?code=${code}&ts=${ts}`)
        .then((res) => {
          setTemperature(res.data.temperature);
          setDewpoint(res.data.dewpoint);
          setPressure(res.data.pressure);
          setWindSpeed(res.data.windspeed_knots);
          setWindDirection(res.data.wind_direction);
          setWindDirectionTitle(res.data.wind_direction + "°");
          setCondition(res.data.condition);
          setLoading(false)

          if ( 'source' in res.data && res.data.source !== 'metar' ) {
            const localData = {...res.data, date: new Date().getTime() + 300000};
            localStorage.setItem('vaisalaData', JSON.stringify(localData));
          }
        })
        .catch((err) => {
          console.log(err);
        });
      } else {
        setTemperature(vaisalaData.temperature);
          setDewpoint(vaisalaData.dewpoint);
          setPressure(vaisalaData.pressure);
          setWindSpeed(vaisalaData.windspeed_knots);
          setWindDirection(vaisalaData.wind_direction);
          setWindDirectionTitle(vaisalaData.wind_direction + "°");
          setCondition(vaisalaData.condition);
          setLoading(false)
      }
    }
    setAirportCode(code);
  }, [props.code, props.ts, props.id]);

  useEffect(() => {
    if (currentSource === null) return;

    console.log("currentSource", currentSource);
    console.log("weatherData", weatherData)

    setTemperature(weatherData[currentSource].temperature);
    setDewpoint(weatherData[currentSource].dewpoint);
    setPressure(weatherData[currentSource].pressure);
    setWindSpeed(weatherData[currentSource].windspeed_knots);
    setWindDirection(weatherData[currentSource].wind_direction);
    setWindDirectionTitle(weatherData[currentSource].wind_direction + "°");
    setCondition(weatherData[currentSource].condition);

    // set source for analysis page - airspeed graph
    if ( props.setSourceWeatherData ) {
      props.setSourceWeatherData(Object.assign({}, {
        windDirection: weatherData[currentSource].wind_direction,
        windSpeed: weatherData[currentSource].windspeed_knots,
        source: currentSource.toUpperCase()
      }));
    }
  }, [currentSource]);

  const is3kAlert = () => {
    if (temperature === "" || dewpoint === "") return false;
    return temperature <= 3 && (temperature - dewpoint) <= 3;
  };


  return airportCode === "" ? null : (
    <>
    <ButtonGroup size="small" color="primary" aria-label="small outlined button group" className={classes.sources}>
        {sources === null ? null : sources.map((src) => (
          <Button key={src} onClick={() => setCurrentSource(src)} {...(src === currentSource ? {color:"primary", variant: "contained"} : {})}>{src}</Button>
        ))}
      </ButtonGroup>&nbsp; &nbsp;&nbsp;&nbsp;
    <Typography color="textSecondary" className={styles} style={{display: "inline-block", marginBottom: "15px"}}>
      
      {
        loading && <span>
        <CircularProgress size={15} thickness={5} color="secondary" />  &nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      }

      {is3kAlert() ? <Tooltip title="3-Kelvin Spread Alert">
        <span style={kAlertStyle}>
          &nbsp;<Badge badgeContent="3k" color="error" overlap={"rectangular"}></Badge>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Tooltip>: false}

      <Tooltip title="Dew Point (Degrees Celsisus)">
        <span>
          <img src={PUBLIC_URL+"/weather/dew-icon.svg"} alt={"Dew Point"} style={iconStyle} />
          &nbsp;{dewpoint}&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Tooltip>
      
      <Tooltip title="Temperature (Degrees Celsisus)">
        <span>
          <i className="wi wi-thermometer"></i>
          &nbsp;{temperature}&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Tooltip>

      {pressure == null ? null : 
        <Tooltip title="Pressure (in. hg)">
          <span>
            <i className="wi wi-barometer" ></i>
            &nbsp;{pressure}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </Tooltip>
      }
      {windSpeed >= 0 && windSpeed < 1 && (
        <Tooltip title="Calm">
          <i className="wi wi-wind-beaufort-0"></i>
        </Tooltip>
      )}
      {windSpeed >= 1 && windSpeed < 4 && (
        <Tooltip title="Light air">
          <i className="wi wi-wind-beaufort-1"></i>
        </Tooltip>
      )}
      {windSpeed >= 4 && windSpeed < 7 && (
        <Tooltip title="Light Breeze">
          <i className="wi wi-wind-beaufort-2"></i>
        </Tooltip>
      )}
      {windSpeed >= 7 && windSpeed < 11 && (
        <Tooltip title="Gentle breeze">
          <i className="wi wi-wind-beaufort-3"></i>
        </Tooltip>
      )}
      {windSpeed >= 11 && windSpeed < 17 && (
        <Tooltip title="Moderate breeze">
          <i className="wi wi-wind-beaufort-4"></i>
        </Tooltip>
      )}
      {windSpeed >= 17 && windSpeed < 22 && (
        <Tooltip title="Fresh breeze">
          <i className="wi wi-wind-beaufort-5"></i>
        </Tooltip>
      )}
      {windSpeed >= 22 && windSpeed < 28 && (
        <Tooltip title="Strong breeze">
          <i className="wi wi-wind-beaufort-6"></i>
        </Tooltip>
      )}
      {windSpeed >= 28 && windSpeed < 34 && (
        <Tooltip title="Near gale">
          <i className="wi wi-wind-beaufort-7"></i>
        </Tooltip>
      )}
      {windSpeed >= 34 && windSpeed < 41 && (
        <Tooltip title="Gale">
          <i className="wi wi-wind-beaufort-8"></i>
        </Tooltip>
      )}
      {windSpeed >= 41 && windSpeed < 48 && (
        <Tooltip title="Strong gale">
          <i className="wi wi-wind-beaufort-9"></i>
        </Tooltip>
      )}
      {windSpeed >= 48 && windSpeed < 56 && (
        <Tooltip title="Storm">
          <i className="wi wi-wind-beaufort-10"></i>
        </Tooltip>
      )}
      {windSpeed >= 56 && windSpeed < 64 && (
        <Tooltip title="Violent storm">
          <i className="wi wi-wind-beaufort-11"></i>
        </Tooltip>
      )}
      {windSpeed >= 64 && windSpeed < 72 && (
        <Tooltip title="Hurricane">
          <i className="wi wi-wind-beaufort-12"></i>
        </Tooltip>
      )}
      &nbsp;

      <Tooltip title={windDirectionTitle} >
        <i className={`wi wi-wind from-${windDirection}-deg`}></i>
      </Tooltip>
      &nbsp;
      
      <Tooltip title="Wind Speed (knots)">
        <span>
        {windSpeed}
        </span>
      </Tooltip>
      &nbsp;&nbsp;&nbsp;&nbsp;
      
      {condition === "rain" && <Tooltip title="Rain"><i className="wi wi-rain"></i></Tooltip>}
      {condition === "snow" && <Tooltip title="Snow"><i className="wi wi-snow"></i></Tooltip>}
      {condition === "hail" && <Tooltip title="Hail"><i className="wi wi-hail"></i></Tooltip>}
      {condition === "sleet" && <Tooltip title="Sleet"><i className="wi wi-sleet"></i></Tooltip>}
      {condition === "cloud" && <Tooltip title="Cloudy"><i className="wi wi-cloudy"></i></Tooltip>}
      {condition === "clear" && <Tooltip title="Clear"><i className="wi wi-day-sunny"></i></Tooltip>}
      &nbsp;&nbsp;

    </Typography>
    </>
  );
};

export default Weather;
