import jwt from 'jwt-decode'

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  console.log('register sw.')

  if (/*process.env.NODE_ENV === 'production' &&*/ 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      // console.log('window.addEventListener')

      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // console.log('isLocalhost')
        
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          // console.log(
          //   'This web app is being served cache-first by a service ' +
          //     'worker. To learn more, visit https://cra.link/PWA'
          // );
        });
      } else {
        // console.log('!isLocalhost')

        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });

    RegisterForPushNotifications()
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              // console.log(
              //   'New content is available and will be used when all ' +
              //     'tabs for this page are closed. See https://cra.link/PWA.'
              // );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              // console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // console.log('checkValidServiceWorker', swUrl)

  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, { headers: { 'Service-Worker': 'script' },})
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');

      // console.log('inside fetch', response.status, contentType)

      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        // console.log('No service worker found')

        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        // console.log('service worker found')
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

// function urlBase64ToUint8Array(base64String) {
//   const padding = "=".repeat((4 - base64String.length % 4) % 4);
//   const base64 = (base64String + padding)
//     .replace(/\-/g, "+")
//     .replace(/_/g, "/");

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

let interval = null

const RegisterForPushNotifications = async () => {
  // console.log('RegisterForPushNotifications')

  const token = localStorage.getItem('token')
  // console.log('Token', token)

  if (token === null) {
    // console.log('No Token Found. Skipping PushNotification Registration')
    
    if (interval === null) {
      interval = setInterval(() => {
        RegisterForPushNotifications()
      }, 2000);
    }
    
    return
  } else {
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  const decodedToken = jwt(token)
  // console.log(decodedToken)
  // console.log(decodedToken.userid)


  const publicVapidKey = "BD_abRyhymxf3q-ADyhL_tN8j6rbkYSUpAKf5xlcJ199tWxc7PiVxQHlA1OucIkC47DRswqdjajsuXL4wGvQ0CA";
  const WEBAPI = process.env.REACT_APP_WEB_API;

  // const subscription = await registration.pushManager.subscribe({
  //   userVisibleOnly: true,
  //   applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
  // });

  // await fetch(`${WEBAPI}/pushnotify/subscribe`, {
  //   method: "POST",
  //   body: JSON.stringify(subscription),
  //   headers: {
  //     "content-type": "application/json"
  //   }
  // });

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        console.log('Push manager unavailable.')
        return
      }

      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          console.log('No subscription detected, make a request.')
          registration.pushManager.subscribe({ applicationServerKey: publicVapidKey, userVisibleOnly: true, })
          .then( async function(subscription) {
            console.log('New subscription added.', subscription)

            const msg = { subscription: subscription, userid: decodedToken.userid }

            await fetch(`${WEBAPI}/pushnotify/subscribe`, {
              method: "POST",
              body: JSON.stringify(msg), //JSON.stringify(subscription),
              headers: {
                "content-type": "application/json"
              }
            });
          })
          .catch(function(e) {
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          console.log('Existed subscription detected.')
          // sendSubscription(existedSubscription)
        }
      })
    })
    .catch(function(e) {
      console.error('An error ocurred during Service Worker registration.', e)
    })
  }


}

