import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function RunwaySelect({runways, runway, colors, setRunway}) {
    return <FormControl style={{width: '150px'}}  variant="outlined" size={"small"}>
        <InputLabel>Select Runway</InputLabel>
        <Select 
            label="select-runway" 
            id="select-runway"
            value={runway}
            onChange={(e) => setRunway(parseInt(e.target.value))}
        >
            {runways.map((runway, index) => {
                return <MenuItem value={index} key={index}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <FiberManualRecord style={{color: colors[index]}} /> */}
                        <div>{runway.runwayname}</div>
                    </div>
                </MenuItem>
            })}
        </Select>
    </FormControl>
}