import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, min, max, svg } from 'd3';
import AnalysisExport from '../components/AnalysisExport';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const DecelerationVsDistance = (props) => {
    // Filter turn around data
    let initialDistance = -1000;
    const turnAroundData = [];

     props.data.forEach((item, index) => {
        if (item.distance > initialDistance) {
            turnAroundData.push(item);
            initialDistance = item.distance;
        }
    })

    const [decelerationDistanceData, setDecelerationDistanceData] = useState(turnAroundData)
    const [touchdown, setTouchdown] = useState(props.touchdown)
    const [runwayLength, setRunwayLength] = useState(props.length)
    // const glideSlopePosition=1000
    const [glideSlopeTouchdown, setGlideSlopeTouchdown] = useState(props.glideslope_touchdown)
    const svgRef = useRef()

    useEffect(() => {
        svgRef.current.innerHTML = ""
        setDecelerationDistanceData(turnAroundData)
        setTouchdown(props.touchdown)
        setRunwayLength(props.length)
        setGlideSlopeTouchdown(props.glideslope_touchdown)

        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });

            const xScale = scaleLinear()
                .domain([0, runwayLength])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));

            const yMin = 0 // min(decelerationDistanceData.map((item) => item.deceleration))
            const yMax = 1 //max(decelerationDistanceData.map((item) => item.deceleration))

            const yScale = scaleLinear()
                .domain([yMin, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));


            const dryBandSize = 60
            const goodBandSize = 20
            const mediumBandSize = 5
            const poorBandSize = 10
            const nilBandSize = 5

            const dryBandHeight = innerHeight / 100 * dryBandSize
            const goodBandHeight = innerHeight / 100 * goodBandSize
            const mediumBandHeight = innerHeight / 100 * mediumBandSize
            const poorBandHeight = innerHeight / 100 * poorBandSize
            const nilBandHeight = innerHeight / 100 * nilBandSize

            // Grey Zone
            const greyzone = g.selectAll('.greyzone').data([null]);
            greyzone.enter()
                .append('rect')
                .merge(greyzone)
                .attr('class', 'greyzone')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', xScale(glideSlopeTouchdown))
                .attr('height', innerHeight)
                .attr('fill', '#A6A6A6')

            // Dry Zone
            const dryzone = g.selectAll('.dryzone').data([null]);
            dryzone.enter()
                .append('rect')
                .merge(dryzone)
                .attr('class', 'dryzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', 0)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', dryBandHeight)
                .attr('fill', '#458140')

            // Good Zone
            const goodzone = g.selectAll('.goodzone').data([null]);
            goodzone.enter().append('rect')
                .merge(goodzone)
                .attr('class', 'goodzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', dryBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', goodBandHeight)
                .attr('fill', '#80C373');
            
            // Medium Zone
            const mediumzone = g.selectAll('.mediumzone').data([null]);
            mediumzone.enter().append('rect')
                .merge(mediumzone)
                .attr('class', 'mediumzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', dryBandHeight + goodBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', mediumBandHeight)
                .attr('fill', '#FFFD3E');

            // Poor Zone
            const poorzone = g.selectAll('.poorzone').data([null]);
            poorzone.enter().append('rect')
                .merge(poorzone)
                .attr('class', 'poorzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', dryBandHeight + goodBandHeight + mediumBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', poorBandHeight)
                .attr('fill', '#EC8A4E');

            // Nil Zone
            const nilzone = g.selectAll('.nilzone').data([null]);
            nilzone.enter().append('rect')
                .merge(nilzone)
                .attr('class', 'nilzone')
                .attr('x', xScale(glideSlopeTouchdown))
                .attr('y', dryBandHeight + goodBandHeight + mediumBandHeight + poorBandHeight)
                .attr('width', innerWidth-xScale(glideSlopeTouchdown))
                .attr('height', nilBandHeight)
                .attr('fill', '#FC1F27');



            // Center Line
            // const centerLine = g.selectAll('.center-line').data([null])
            // centerLine
            //     .enter().append("line")
            //     .attr("class", 'center-line')
            // .merge(centerLine)
            //     .attr("x1", 10)
            //     .attr("y1", innerHeight / 100 * 50)
            //     .attr("x2", innerWidth - 10)
            //     .attr("y2", innerHeight / 100 * 50)
            //     .style("stroke-dasharray", ("30, 10"))
            //     .style("stroke", '#FFFFFF');            


            // Glide Slope Line
            const glideSlopeLine = g.selectAll('.glideslope-line').data([null])
            glideSlopeLine
                .enter().append("line")
                .attr("class", 'glideslope-line')
            .merge(glideSlopeLine)
                .attr("x1", xScale(glideSlopeTouchdown))
                .attr("y1", -20)
                .attr("x2", xScale(glideSlopeTouchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke", '#368029');            

            // Glide Slope Label
            const glideSlopeTitle = g.selectAll('.glideslope-title').data([null])
            glideSlopeTitle
                .enter().append("text")
                .attr('class', 'glideslope-title')
            .merge(glideSlopeTitle)
                .attr('fill', '#368029')
                .attr("x", xScale(glideSlopeTouchdown) + 10)             
                .attr("y", -10)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .html("3&deg; Glide Slope TDP");


            // Touchdown Line
            const touchdownLine = g.selectAll('.touchdown-line').data([null])
            touchdownLine
                .enter().append("line")
                .attr("class", 'touchdown-line')
            .merge(touchdownLine)
                .attr("x1", xScale(touchdown))
                .attr("y1", 0)
                .attr("x2", xScale(touchdown))
                .attr("y2", innerHeight)
                .style("stroke-dasharray", ("12, 3"))
                .style("stroke-width", 2)
                .style("stroke", '#338DCD');            

            // Touchdown Label
            const touchdownTitle = g.selectAll('.touchdown-title').data([null])
            touchdownTitle
                .enter().append("text")
                .attr('class', 'touchdown-title')
            .merge(touchdownTitle)
                .attr('fill', '#FFFFFF')
                .attr("x", xScale(touchdown) + 10)             
                .attr("y", 20)
                .attr("text-anchor", "start")  
                .style("font-size", "12px") 
                .text("Estimated Touchdown");


            // // Dry
            // const dryZoneTitle = g.selectAll('.dryzone-title').data([null])
            // dryZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'dryzone-title')
            // .merge(dryZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", 30)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "16px") 
            //     .text("Dry");

            // // Good
            // const goodZoneTitle = g.selectAll('.goodzone-title').data([null])
            // goodZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'goodzone-title')
            // .merge(goodZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", dryBandHeight + 20)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "16px") 
            //     .text("Good");

            // // Medium
            // const mediumZoneTitle = g.selectAll('.mediumzone-title').data([null])
            // mediumZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'mediumzone-title')
            // .merge(mediumZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", dryBandHeight + goodBandHeight + 10)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "16px") 
            //     .text("Medium");
    
            // // Poor
            // const poorZoneTitle = g.selectAll('.poorzone-title').data([null])
            // poorZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'poorzone-title')
            // .merge(poorZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", dryBandHeight + goodBandHeight + mediumBandHeight + 16)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "16px") 
            //     .text("Poor");
            
            // // Nil
            // const nilZoneTitle = g.selectAll('.nilzone-title').data([null])
            // nilZoneTitle
            //     .enter().append("text")
            //     .attr('class', 'nilzone-title')
            // .merge(nilZoneTitle)
            //     .attr('fill', chartTheme.titleFill)
            //     .attr("x", innerWidth - 50)             
            //     .attr("y", dryBandHeight + goodBandHeight + mediumBandHeight + poorBandHeight + 10)
            //     .attr("text-anchor", "end")  
            //     .style("font-size", "12px") 
            //     .text("Nil");
    

            // plot the line
            const lineGenerator = line()
                .x((value, index) => xScale(value.distance))
                .y((value, index) => yScale(value.deceleration))
                .curve(curveBasis)

            g.selectAll('.line')
                .data([decelerationDistanceData])
                .join('path')
                .attr('class', 'line')
                .attr('d', value => lineGenerator(value))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '1')

            // draw the points
            g.selectAll('.dots')
                .data(decelerationDistanceData)
                .join("circle")
                .attr('class', 'dots')
                .attr('stroke','black')
                .attr('r','2')
                .attr('fill','black')
                .attr('cx', (d) => xScale(d.distance))
                .attr('cy', (d) => yScale(d.deceleration))
                .append("svg:title")
                .text(function(d, i) { return `(${d.distance}, ${d.deceleration})` });

        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: props.width_auto ? (window.innerWidth / 2)-50 : window.innerWidth-20,
                height: props.height || 300,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Distance (ft)',
                yAxisLabel: 'Deceleration (g)'
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [props.data])

    return ( 
        <>
            { props.root && <AnalysisExport name={"Deceleration Vs. Distance"} data={decelerationDistanceData} />}
            <svg ref={svgRef}></svg>
        </>
    );
}

export default DecelerationVsDistance;
