import React, { useState, useEffect } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
// import { setAirportCode } from "../redux/airportCodeSlice";
import Paper from "@material-ui/core/Paper";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import AircraftType from "../lib/aircrafttype";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  activetext: {
    margin: theme.spacing(1),
  }
}));

const validationSchema = yup.object({
  authorityid: yup.string('Select Authority').required('Authority is required'),
  airportid: yup.string('Select Airport').required('Airport is required'),
  alerttype: yup.string('Select Alert Type').required('Alert Type is required'),
  amount: yup.string("Enter Amount").required("Amount is required"),
  runwaypart: yup.string("Select Runway Part").required("Runway part is required"),
  maintain: yup.string("Enter Maintain Amount").required("Enter 0 if not applicable"),
  mlMaxDecel: yup.string("Enter Max Decel").required("Max Decel is required"),
  mlMaxDecelDelta: yup.string("Enter Max Decel Delta").required("Max Decel Delta is required"),
  mlCurveRMS: yup.string("Enter Curve RMS").required("Curve RMS is required"),
});

export default function Alert() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [authorityId, setAuthorityId] = useState('')
  const [airports, setAirports] = useState([]);
  const [runways, setRunways] = useState([]);
  const [airportId, setAirportId] = useState('')
  const [authorities, setAuthorities] = useState([])
  const [alertType, setAlertType] = useState('')
  const [amount, setAmount] = useState('')
  const [amountUnits, setAmountUnits] = useState('')
  const [runwayPart, setRunwayPart] = useState('')
  const [maintain, setMaintain] = useState('')
  const [airportUsers, setAirportUsers] = useState([])
  const [mlMaxDecel, setMlMaxDecel] = useState(0)
  const [mlMaxDecelDelta, setMlMaxDecelDelta] = useState(0)
  const [mlCurveRMS, setMlCurveRMS] = useState(0)
  const [mlMaxDecelCompare, setMlMaxDecelCompare] = useState('lt')
  const [mlMaxDecelDeltaCompare, setMlMaxDecelDeltaCompare] = useState('lt')
  const [mlCurveRMSCompare, setMlCurveRMSCompare] = useState('lt')
  const [selectedUsers, setSelectedUsers] = useState([])
  const airportCode = useSelector((state) => state.airportCode.value);
  const root = useSelector((state) => state.root.value)


  const formik = useFormik({
    // validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      authorityid: authorityId,
      airportid: airportId,
      alerttype: alertType,
      amount: amount,
      maintain: maintain,
      runwaypart: runwayPart,
      activerunways: [],
      aircrafttypes: [],
      users: [],
      mlMaxDecel: mlMaxDecel,
      mlMaxDecelDelta: mlMaxDecelDelta,
      mlCurveRMS: mlCurveRMS,
      mlMaxDecelCompare: mlMaxDecelCompare,
      mlMaxDecelDeltaCompare: mlMaxDecelDeltaCompare,
      mlCurveRMSCompare: mlCurveRMSCompare,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values);
      // console.log("authorityId", id);
      // console.log(airports)

      const activeRunways = []
      runways.map((item, index) => {
        activeRunways.push({runwayId: item.id, active: formik.values.activerunways[index] ?? false})
        return item
      })

      let userIds = [];
      if ( formik.values.users.length > 0 ) {
        formik.values.users.map((item, index) => {
          if ( item === true ) {
            userIds.push(airportUsers[index].id)
          }
          return item
        })
      }

      const activeAircraftTypes = JSON.stringify( formik.values.aircrafttypes );

      console.log(activeRunways)
      console.log('activeAircraftTypes',activeAircraftTypes)
      
      if (id === undefined) { // New
        axios
          .post(`${WEBAPI}/alert`, {
            airportid: values.airportid,
            type: values.alerttype,
            amount: values.amount,
            runwaypart: values.runwaypart,
            maintain: values.maintain,
            activerunways: activeRunways,
            aircrafttypes: activeAircraftTypes,
            userids: userIds,
            mlMaxDecel: values.mlMaxDecel,
            mlMaxDecelDelta: values.mlMaxDecelDelta,
            mlCurveRMS: values.mlCurveRMS,
            mlMaxDecelCompare: values.mlMaxDecelCompare,
            mlMaxDecelDeltaCompare: values.mlMaxDecelDeltaCompare,
            mlCurveRMSCompare: values.mlCurveRMSCompare,
          })
          .then((res) => {
            history.push("/alerts");
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      } else { // Edit
        axios
          .put(`${WEBAPI}/alert`, {
            id: id,
            airportid: values.airportid,
            type: values.alerttype,
            amount: values.amount,
            runwaypart: values.runwaypart,
            maintain: values.maintain,
            activerunways: activeRunways,
            aircrafttypes: activeAircraftTypes,
            userids: userIds,
            mlMaxDecel: values.mlMaxDecel,
            mlMaxDecelDelta: values.mlMaxDecelDelta,
            mlCurveRMS: values.mlCurveRMS,
            mlMaxDecelCompare: values.mlMaxDecelCompare,
            mlMaxDecelDeltaCompare: values.mlMaxDecelDeltaCompare,
            mlCurveRMSCompare: values.mlCurveRMSCompare,
          })
          .then((res) => {
            history.push("/alerts");
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }

      // setSubmitting(false);
    }
  });
 
  useEffect(() => {
    if (location.state?.authorityId) {
      if ( authorityId !== location.state.authorityId) {
        setAuthorityId(location.state.authorityId)
      }
    }

    axios.get(`${WEBAPI}/authority`)
    .then((res) => {
      // console.log(res.data)
      setAuthorities(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [location.state?.authorityId]);

  useEffect(() => {
    if (authorityId === '') return

    // console.log(`id, authorityId: |${id}|${location.state.authorityId}|`);


    axios
    .get(`${WEBAPI}/authority/${authorityId}`)
    .then((res) => {
      // console.log('data', res.data);

      const airportArray = res.data.airports
      console.log('airportArray', airportArray)
      setAirports(airportArray);

      var _airportUsers = []
      if ( 'users' in res.data ) {
        _airportUsers = res.data.users;
        setAirportUsers(res.data.users)
      }

      if (id === undefined) {
        console.log('New Alert')

        const airport = airportArray.find((item, index) => {
          return item.code === airportCode
        })

        setAirportId(airport.id)

        formik.setFieldValue("airportid", airport.id, false);
        setRunways(airport.runways)
        const defaultState = airport.runways.map((item, index)=> {
          return false
        })
        formik.setFieldValue("activerunways", defaultState, false);
  
      } else {
        console.log('Edit Alert')
  
        axios.get(`${WEBAPI}/alert/${id}`)
        .then((res) => {
          console.log('Data: ', res.data)
          setAmount(res.data.amount)
          setAirportId(res.data.airportid)
          setAlertType(res.data.type)
          setRunwayPart(res.data.runway_area)
          setMaintain(res.data.maintain)
          setRunways(res.data.airport.runways)
          setSelectedUsers(res.data.userids)
          setMlMaxDecel(res.data.ml_max_g)
          setMlMaxDecelDelta(res.data.ml_max_delta_g)
          setMlCurveRMS(res.data.ml_curve_rms)
          setMlMaxDecelCompare(res.data.ml_max_g_compare)
          setMlMaxDecelDeltaCompare(res.data.ml_max_delta_g_compare)
          setMlCurveRMSCompare(res.data.ml_curve_rms_compare)
          
          // get the active status for each runway
          const activeRunwayState = res.data.airport.runways.map((item, index)=> {
            // console.log(item)
            const runwayAlert = res.data.runwayAlerts.find(element => element.runwayid === item.id);
            // console.log('airportUser', airportUser)

            return runwayAlert.active
          })

          const activeAircraftTypes = res.data.emitter_type === null ? [] : JSON.parse(res.data.emitter_type)

          console.log(activeAircraftTypes, 'activeAircraftTypes')
          formik.setFieldValue("activerunways", activeRunwayState, false);
          formik.setFieldValue("aircrafttypes", activeAircraftTypes, false);


          const userIds = res.data.userids.map((item, index) => {
            return item.id
          })

          if ( userIds.length > 0 ) {
            _airportUsers.forEach((item, index) => {
              if ( item.airportusers[0].role === 'sales' ) {
                return null;
              }
              if ( userIds.includes(item.id) ) {
                formik.setFieldValue(`users[${index}]`, true, false);
              } else {
                formik.setFieldValue(`users[${index}]`, false, false);
              }
            });
          }

          //formik.setFieldValue("users", [true, true, false], false);
        })
        .catch((err) => {
            console.log(err);
        })
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }, [authorityId, airportCode, id]);



  const handleAuthorityIdChange = (e) => {
    console.log('handleAuthorityIdChange', e)
    setAuthorityId(e.target.value)
    formik.setFieldValue("authorityid", e.target.value, false);
  }

  const handleAirportIdChange = (e) => {
    // console.log('handleAirportIdChange', e)
    setAirportId(e.target.value)
    formik.setFieldValue("airportid", e.target.value, false);

    const ap = airports.find((item) => {
      return item.id === e.target.value
    })

    console.log(ap)

    setRunways(ap.runways)

    const defaultState = ap.runways.map((item, index)=> {
      return false
    })
    formik.setFieldValue("activerunways", defaultState, false);


  }

  const handleAlertTypeChange = (e) => {
    console.log('handleAlertTypeChange', e.target.value)
    // setAlertType(e.target.value)
    formik.setFieldValue("alerttype", e.target.value, false);

    switch(e.target.value) {
      case "GS":
        setAmountUnits('Knots')
        break
      case "DCML":
        setAmountUnits('g')
        setAmount('0')
        setRunwayPart('FIRST_THIRD')
        setMaintain('0')
        setAlertType('DCML')
        break
      case "CL":
        setAmountUnits('feet')
        break
      default:
        break
      }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>

          <Grid container spacing={2}>

              <Grid container item xs={12} md={4}>
                <Typography component="h1" variant="h5">Alert</Typography>

                {
                  root ? 
                  <FormControl
                    disabled={authorityId !== ''}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    // className={classes.formControl}
                    size={"small"}
                    required
                    error={formik.touched.authorityid && Boolean(formik.errors.authorityid)}
                    >
                    <InputLabel>Authority</InputLabel>
                    <Select
                      value={formik.values.authorityid}
                      onChange={handleAuthorityIdChange}
                      id="authorityid"
                      label="Authority"
                      name="authorityid"
                    >
                      {authorities?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{display: (formik.touched.authorityid && Boolean(formik.errors.authorityid) ? 'block' : 'none' ) }} >Authority is required</FormHelperText>
                  </FormControl>
                  :
                  null
                }

                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  // className={classes.formControl}
                  size={"small"}
                  required
                  error={formik.touched.airportid && Boolean(formik.errors.airportid)}
                  >
                  <InputLabel>Airport</InputLabel>
                  <Select
                    value={formik.values.airportid}
                    onChange={handleAirportIdChange}
                    id="airportid"
                    label="Airport"
                    name="airportid"
                  >
                    {airports?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{display: (formik.touched.airportid && Boolean(formik.errors.airportid) ? 'block' : 'none' ) }} >Airport is required</FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  required
                  error={formik.touched.alerttype && Boolean(formik.errors.alerttype)}
                  >
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={formik.values.alerttype}
                    onChange={handleAlertTypeChange}
                    id="alerttype"
                    label="Type"
                    name="alerttype"
                  >
                    <MenuItem key='GS' value='GS'>Ground Speed</MenuItem>
                    {/* 
                      * Legacy Decleration Alert
                    <MenuItem key='DC' value='DC'>Deceleration</MenuItem> */}
                    {root ? <MenuItem key='DCML' value='DCML'>Deceleration</MenuItem> : null}
                    <MenuItem key='CL' value='CL'>Centre Line Deviation</MenuItem>
                  </Select>
                  <FormHelperText style={{display: (formik.touched.alerttype && Boolean(formik.errors.alerttype) ? 'block' : 'none' ) }} >Alert Type is required</FormHelperText>
                </FormControl>


              {formik.values.alerttype === 'DCML' ? 
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.touched.mlMaxDecelCompare && Boolean(formik.errors.mlMaxDecelCompare)}
                      >
                      <InputLabel>ML Max Decel</InputLabel>
                      <Select
                        value={formik.values.mlMaxDecelCompare}
                        onChange={formik.handleChange}
                        id="mlMaxDecelCompare"
                        label="ML Max Decel"
                        name="mlMaxDecelCompare"
                      >
                        <MenuItem key='0' value='lt'>Less Than</MenuItem>
                        <MenuItem key='1' value='et'>Equal To</MenuItem>
                        <MenuItem key='2' value='gt'>Greater Than</MenuItem>
                        <MenuItem key='3' value='no'>None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} style={(formik.values.mlMaxDecelCompare === 'no' ? {display: 'none'}: {})}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.touched.mlMaxDecel && Boolean(formik.errors.mlMaxDecel)}
                      >
                      <InputLabel htmlFor="amount">Amount</InputLabel>
                      <OutlinedInput
                        id="mlMaxDecel"
                        // label="Amount"
                        name="mlMaxDecel"
                        value={formik.values.mlMaxDecel}
                        onChange={formik.handleChange}
                        endAdornment={<InputAdornment position="end">g</InputAdornment>}
                        labelWidth={70}
                      />
                      <FormHelperText style={{display: (formik.touched.mlMaxDecel && Boolean(formik.errors.mlMaxDecel) ? 'block' : 'none' ) }} >Amount is required</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.values.mlMaxDecelDeltaCompare && Boolean(formik.errors.mlMaxDecelDeltaCompare)}
                      >
                      <InputLabel>ML Max Decel Deviation Compare</InputLabel>
                      <Select
                        value={formik.values.mlMaxDecelDeltaCompare}
                        onChange={formik.handleChange}
                        id="mlMaxDecelDeltaCompare"
                        label="ML Max Decel Deviation Compare"
                        name="mlMaxDecelDeltaCompare"
                      >
                        <MenuItem key='0' value='lt'>Less Than</MenuItem>
                        <MenuItem key='1' value='et'>Equal To</MenuItem>
                        <MenuItem key='2' value='gt'>Greater Than</MenuItem>
                        <MenuItem key='3' value='no'>None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} style={(formik.values.mlMaxDecelDeltaCompare === 'no' ? {display: 'none'}: {})}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.touched.mlMaxDecelDelta && Boolean(formik.errors.mlMaxDecelDelta)}
                      >
                      <InputLabel htmlFor="amount">Amount</InputLabel>
                      <OutlinedInput
                        id="mlMaxDecelDelta"
                        // label="Amount"
                        name="mlMaxDecelDelta"
                        value={formik.values.mlMaxDecelDelta}
                        onChange={formik.handleChange}
                        endAdornment={<InputAdornment position="end">g</InputAdornment>}
                        labelWidth={70}
                      />
                      <FormHelperText style={{display: (formik.touched.mlMaxDecelDelta && Boolean(formik.errors.mlMaxDecelDelta) ? 'block' : 'none' ) }} >Amount is required</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.touched.mlCurveRMSCompare && Boolean(formik.errors.mlCurveRMSCompare)}
                      >
                      <InputLabel>ML Curve RMS</InputLabel>
                      <Select
                        value={formik.values.mlCurveRMSCompare}
                        onChange={formik.handleChange}
                        id="mlCurveRMSCompare"
                        label="ML Curve RMS"
                        name="mlCurveRMSCompare"
                      >
                        <MenuItem key='0' value='lt'>Less Than</MenuItem>
                        <MenuItem key='1' value='et'>Equal To</MenuItem>
                        <MenuItem key='2' value='gt'>Greater Than</MenuItem>
                        <MenuItem key='3' value='no'>None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} style={(formik.values.mlCurveRMSCompare === 'no' ? {display: 'none'}: {})}>
                    <FormControl 
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size={"small"}
                      required
                      error={formik.touched.mlCurveRMS && Boolean(formik.errors.mlCurveRMS)}
                      >
                      <InputLabel htmlFor="amount">Amount</InputLabel>
                      <OutlinedInput
                        id="mlCurveRMS"
                        // label="Amount"
                        name="mlCurveRMS"
                        value={formik.values.mlCurveRMS}
                        onChange={formik.handleChange}
                        labelWidth={70}
                      />
                      <FormHelperText style={{display: (formik.touched.mlCurveRMS && Boolean(formik.errors.mlCurveRMS) ? 'block' : 'none' ) }} >Amount is required</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                </>

                : 

                <>

                <FormControl 
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  required
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  >
                  <InputLabel htmlFor="amount">Amount</InputLabel>
                  <OutlinedInput
                    id="amount"
                    // label="Amount"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    endAdornment={<InputAdornment position="end">{amountUnits}</InputAdornment>}
                    labelWidth={70}
                  />
                  <FormHelperText style={{display: (formik.touched.amount && Boolean(formik.errors.amount) ? 'block' : 'none' ) }} >Amount is required</FormHelperText>
                </FormControl>


                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  required
                  error={formik.touched.runwaypart && Boolean(formik.errors.runwaypart)}
                  >
                  <InputLabel>Runway Part</InputLabel>
                  <Select
                    value={formik.values.runwaypart}
                    onChange={formik.handleChange}
                    id="runwaypart"
                    label="Runway Part"
                    name="runwaypart"
                  >
                    <MenuItem key='FIRST_THIRD' value='FIRST_THIRD'>First Third</MenuItem>
                    <MenuItem key='MIDDLE_THIRD' value='MIDDLE_THIRD'>Middle Third</MenuItem>
                    <MenuItem key='LAST_THIRD' value='LAST_THIRD'>Last Third</MenuItem>
                    <MenuItem key='FIRST_HALF' value='FIRST_HALF'>First Half</MenuItem>
                    <MenuItem key='SECOND_HALF ' value='SECOND_HALF'>Second Half</MenuItem>
                    <MenuItem key='LAST_500FT' value='LAST_500FT'>Last 500 ft</MenuItem>
                  </Select>
                  <FormHelperText style={{display: (formik.touched.runwaypart && Boolean(formik.errors.runwaypart) ? 'block' : 'none' ) }} >Runway Part is required</FormHelperText>
                </FormControl>

                <FormControl 
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size={"small"}
                  error={formik.touched.maintain && Boolean(formik.errors.maintain)}
                  >
                  <InputLabel htmlFor="maintain">Maintain</InputLabel>
                  <OutlinedInput
                    id="maintain"
                    // label="Amount"
                    name="maintain"
                    value={formik.values.maintain}
                    onChange={formik.handleChange}
                    endAdornment={<InputAdornment position="end">feet</InputAdornment>}
                    labelWidth={70}
                  />
                  <FormHelperText>Enter 0 if not applicable</FormHelperText>
                </FormControl>
                </>
              }

              </Grid>

              <Grid container item xs={12} md={4} spacing={1}>
                <Grid item xs={8}  >
                  <Typography component="h1" variant="h5">Activate on runways</Typography>

                  { 
                    runways?.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={item.id}
                            className={classes.formControl}
                            control={
                              <Checkbox
                                checked={formik.values.activerunways[index] ?? false}
                                onChange={formik.handleChange}
                                name={`activerunways[${index}]`}
                                color="primary"
                              />
                            }
                            label={item.runwayname}
                          />
                        )
                      }
                    )
                  }
                </Grid>

                <Grid item xs={8}  >
                  <Typography component="h1" variant="h5">Activate for aircraft types</Typography>

                  { 
                    Object.keys(AircraftType).map((item, index) => {
                        return (
                          <>
                            <FormControlLabel
                              key={item}
                              className={classes.formControl}
                              control={
                                <Checkbox
                                  checked={formik.values.aircrafttypes[index] ?? false}
                                  onChange={formik.handleChange}
                                  name={`aircrafttypes[${index}]`}
                                  color="primary"
                                />
                              }
                              label={AircraftType[item]}
                            />
                            <br />
                          </>
                        )
                      }
                    )
                  }
                </Grid>

              </Grid>

              <Grid container item xs={12} md={4} spacing={1}>
                <Grid item xs={8}  >
                  <Typography component="h1" variant="h5">Users</Typography>


                  {
                    airportUsers?.map((item, index) => {
                      if ( item.airportusers[0].role === 'sales' ) {
                        return null;
                      }
                      return (
                        <>
                          <FormControlLabel
                            key={item.id}
                            className={classes.formControl}
                            control={
                              <Checkbox
                                checked={formik.values.users[index] ?? false}
                                onChange={formik.handleChange}
                                name={`users[${index}]`}
                                color="primary"
                              />
                            }
                            label={item.fname + ' ' + item.lname}
                          />  
                          <br />
                        </>
                      )
                    })
                  }
                </Grid>
              </Grid>

              <Grid container item xs={12} md={4}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                    className={classes.submit}
                  >
                    Save
                  </Button>

              </Grid>
          </Grid>

        </form>
      </Paper>
    </div>
  );
}
