import { createSlice } from '@reduxjs/toolkit'

export const firstNameSlice = createSlice({
  name: 'firstName',
  initialState: {
    value: '',
  },
  reducers: {
    setFirstName: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setFirstName } = firstNameSlice.actions
export default firstNameSlice.reducer
