import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import * as axios from "axios";
import jwt from 'jwt-decode'
// import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setRoot } from "../redux/rootSlice";
import { setAuthenticated } from "../redux/authenticatedSlice";
import { setAuthorityId } from "../redux/authoritySlice";
import { setFirstName } from "../redux/firstNameSlice";
import { setAirportCode } from "../redux/airportCodeSlice";
import { setRole } from "../redux/roleSlice";

const TEAM_EAGLE = process.env.REACT_APP_TEAM_EAGLE_AUTHORITY_NAME;
const WEBAPI = process.env.REACT_APP_WEB_API;
const DEFAULT_AIRPORT = process.env.REACT_APP_DEFAULT_AIRPORT;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  password: yup
    .string("Enter your new password")
    .test(
      "regex",
      "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
      val => {
        let regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        // console.log(regExp.test(val), regExp, val);
        return regExp.test(val);
      }),

  password2: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref('password'), null], 'Passwords must match')    
});

export default function LoginPasswordNew() {
  const classes = useStyles();
  const history = useHistory();
  // const [invalid, setInvalid] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setFormSubmitting(true)
      const email = sessionStorage.getItem('email')

      axios.post(`${WEBAPI}/auth/login`, { email: email, password: values.password, newpwd: true })
        .then((res) => {
          console.log(res)

          if (res.data.status === "INVALID_PWD") {
            // setInvalid(true)
          } else {
            console.log('Successfully logged in.', res.data);
            localStorage.setItem("token", res.data.token);

            const decodedToken = jwt(res.data.token)
            dispatch(setRoot(decodedToken.authorityName === TEAM_EAGLE))
            const role = decodedToken.airportRoles.length > 0 ? decodedToken.airportRoles[0].role : ''
            dispatch(setRole(role))
            dispatch(setAuthenticated(true))
            dispatch(setAuthorityId(decodedToken.authorityId))
            dispatch(setFirstName(decodedToken.fname))
            let airportCode = decodedToken.airportRoles.length === 1 && role !== 'none' ? decodedToken.airportRoles[0].airport.code : ''
            if (airportCode === '' && decodedToken.authorityName === TEAM_EAGLE) {
              airportCode = DEFAULT_AIRPORT
              dispatch(setAirportCode(airportCode))
            }
            
            history.push("/");
          }
        })
        .catch((err) => {
            console.log(err);
        })

      setSubmitting(false)
    },
  });

  // const handlePasswordChange = (e) => {
  //   setInvalid(false)
  //   formik.setFieldValue("password", e.target.value, false);
  // }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password
        </Typography>

        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="false"
        >
          <TextField
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoFocus
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password"
            }}
            />

          <TextField
            size="small"
            value={formik.values.password2}
            onChange={formik.handleChange}
            error={formik.touched.password2 && Boolean(formik.errors.password2)}
            helperText={formik.touched.password2 && formik.errors.password2}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Confirm New Password"
            type="password"
            id="password2"
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password"
            }}
            />
          {/* <FormHelperText style={{display: (invalid) ? 'block' : 'none' , color: 'red' }} >Your password does not match.</FormHelperText> */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.submit}
          >
            Log In
          </Button>
        </form>
      </div>
    </Container>
  );
}
