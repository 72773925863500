import React, { useState, useEffect } from "react";
import * as axios from 'axios'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));


function Logs() {
  const classes = useStyles();
  const [logFile, setLogFile] = useState([])
  const [logName, setLogName] = useState('avionix-server-udp-out')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    console.log('logName', logName)

    axios.get(`${WEBAPI}/log/${logName}`)
    .then((response) => {
      console.log('Data Returned: ', response)
      setLogFile(response.data)
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false)
    })
  }, [logName]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <div style={{padding:15}}>
        <ButtonGroup variant="contained" aria-label="outlined primary button group" color="primary">
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>avionix-server-udp-out</Button>
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>web-server-out</Button>
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>weather-server-out</Button>
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>avionix-server-udp-error</Button>
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>web-server-error</Button>
          <Button onClick={(e)=> setLogName(e.target.innerHTML)}>weather-server-error</Button>
        </ButtonGroup>
      </div>
      </Paper>

      {
        loading ?
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress color="secondary" />
        </Box>
        :
        <Paper className={classes.paper}>
        <div style={{padding:15}}>
        {
          logFile.map((row, index) => {
            return (<div key={index}>{row}</div>)
          })
        }
      </div>
      </Paper>
      }
    </div>

  );
}

export default Logs;
