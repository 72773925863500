import { useEffect, useRef, useState } from 'react'
import { labeledXAxis, labeledYAxis } from './helpers/labeledAxes';
import { chartTheme } from './helpers/chartTheme';
import { marginConvention } from './helpers/marginConvention'
import { select, scaleLinear, curveBasis, line, curveCardinal, extent, axisLeft, axisBottom, max } from 'd3';
import AnalysisExport from '../components/AnalysisExport';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const SpeedVsTime = (props) => {
    const [speedTimeData, setSpeedTimeData] = useState(props.data)
    const [speedTimeFunction, setSpeedTimeDataFunction] = useState(props.function)
    const svgRef = useRef()

    useEffect(() => {
        // console.log(speedTimeData.length)
        // if (speedTimeData.length === 0) return
        const totalElapsedTime = speedTimeData[speedTimeData.length-1].et

        function responsiveComponent(svg, props) {
            const { width, height, margin } = props;

            svg = svg.enter().append('svg')
                .merge(svg)
                .attr('width', width)
                .attr('height', height);

            const { g, innerWidth, innerHeight } = marginConvention(svg, { width, height, margin });

            const xScale = scaleLinear()
                .domain([0, totalElapsedTime])
                .range([0, innerWidth])
                .nice();

            labeledXAxis(g, Object.assign({}, props, {
                xScale,
                innerWidth,
                innerHeight
            }));


            // convert the speed values to an array
            const speedArray = speedTimeData.map((item)=>{
                return item.speed
            })

            const yMax = max(speedArray)
            // console.log('max y value:', yMax)
            const yScale = scaleLinear()
                .domain([0, yMax])
                .range([innerHeight, 0])
                .nice();

            labeledYAxis(g, Object.assign({}, props, {
                yScale,
                innerHeight
            }));
       
            // gridlines in x axis function
            function make_x_gridlines() {		
                return axisBottom(xScale)
                    .ticks()
            }

            // gridlines in y axis function
            function make_y_gridlines() {		
                return axisLeft(yScale)
                    .ticks()
            }

            // add the X gridlines
            g.selectAll('.xgrid').remove()
            g.append("g")
            .attr("class", "xgrid")
            .attr("transform", "translate(0," + innerHeight + ")")
            .call(make_x_gridlines()
                .tickSize(-innerHeight)
                .tickFormat("")
            )

            // add the Y gridlines
            g.selectAll('.ygrid').remove()
            g.append("g")			
            .attr("class", "ygrid")
            .call(make_y_gridlines()
                .tickSize(-innerWidth)
                .tickFormat("")
            )            

            // Title
            const mainTitle = g.selectAll('.main-title').data([null])
            mainTitle
                .enter().append("text")
                .attr('class', 'main-title')
            .merge(mainTitle)
                .attr('fill', chartTheme.titleFill)
                .attr("x", (innerWidth / 2))             
                .attr("y", margin.top + 45)
                .attr("text-anchor", "middle")  
                .style("font-size", "26px") 
                .text("Speed vs. Time");

            // Polynomial Function
            const functionTitle = g.selectAll('.function').data([null])
            functionTitle
                .enter().append("text")
                .attr('class', 'function')
            .merge(functionTitle)
                .attr('fill', 'steelblue')
                .attr("x", innerWidth - 10)             
                .attr("y", 450)
                .attr("text-anchor", "end")  
                .style("font-size", "10px") 
                .text(speedTimeFunction);

            // plot the line
            const lineGenerator = line()
                .x((value, index) => xScale(value.et))
                .y((value, index) => yScale(value.speed))
                .curve(curveBasis)

            g.selectAll('.line')
                .data([speedTimeData])
                .join('path')
                .attr('class', 'line')
                .attr('d', value => lineGenerator(value))
                .attr('fill', 'none')
                .attr('stroke', 'steelblue')
                .attr('stroke-width', '1')

            // draw the points
            g.selectAll('.dots')
                .data(speedTimeData)
                .join("circle")
                .attr('class', 'dots')
                .attr('stroke','steelblue')
                .attr('r','2')
                .attr('fill','steelblue')
                .attr('cx', (d) => xScale(d.et))
                .attr('cy', (d) => yScale(d.speed))


        }

        const RenderChart = () => {
            const svg = select(svgRef.current)

            responsiveComponent(svg, Object.assign({}, chartTheme, {
                width: window.innerWidth-20,
                height: 600,
                margin: { top: 20, bottom: 71, left: 100, right: 30 },
                xAxisLabel: 'Elapsed Time (s)',
                yAxisLabel: 'Speed (m/s)'
            }));
        }

        const debouncedHandleResize = debounce(function handleResize() {
            RenderChart()
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        RenderChart()

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    return ( 
        <>
            <AnalysisExport name={"Time vs. Speed"} data={speedTimeData} />
            <svg ref={svgRef}></svg>
        </>
    );
}

export default SpeedVsTime;
