import { createSlice } from '@reduxjs/toolkit'

export const rootSlice = createSlice({
  name: 'root',
  initialState: {
    value: false,
  },
  reducers: {
    setRoot: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setRoot } = rootSlice.actions
export default rootSlice.reducer
