import React, { useState, useEffect } from 'react';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
// import LocalAirportIcon from '@material-ui/icons/LocalAirport';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import { useHistory, useParams } from 'react-router-dom';
// import { FormikContext, useFormik } from 'formik';
// import * as yup from 'yup';
// import * as axios from 'axios'
// import Grid from "@material-ui/core/Grid";
// import AirportImage from '../charts/AirportImage'
// import Paper from '@material-ui/core/Paper';
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormHelperText from '@material-ui/core/FormHelperText';

// const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1)
  },
  image: {
    maxWidth: '250px'
  }
  // paper: {
  //   padding: theme.spacing(1),
  // },
}));

export default function AircraftThumbnail(props) {
  const classes = useStyles();
  const [thumbnailURL, setThumbnailURL] = useState('')

  useEffect(() => {
    setThumbnailURL(props.thumbnailURL)
  }, [props.thumbnailURL]);

  return (
    <div className={classes.root}>
      {
        thumbnailURL === '' || thumbnailURL === null ?
        null
        :
        <img src={thumbnailURL} className={classes.image} alt="Aircraft" style={props.style}></img>
      }
    </div>
  )
}