export const alertTypes = new Map();
alertTypes.set('GS', 'Ground speed')
alertTypes.set('DC', 'Deceleration')
alertTypes.set('DCML', 'Deceleration')
alertTypes.set('CL', 'Center Line')


export const runwayArea = new Map();
runwayArea.set('FIRST_THIRD', 'First Third')
runwayArea.set('MIDDLE_THIRD', 'Middle Third')
runwayArea.set('LAST_THIRD', 'Last Third')
runwayArea.set('FIRST_HALF', 'First Half')
runwayArea.set('SECOND_HALF', 'Second Half')
runwayArea.set('LAST_500FT', 'Last 500 feet')

export const altitudeTypes = new Map()
altitudeTypes.set(0, 'Barometric')
altitudeTypes.set(1, 'GNSS')

export const gpsStatusTypes = new Map()
gpsStatusTypes.set(0, 'GPS Not Present')
gpsStatusTypes.set(1, 'Not Locked')
gpsStatusTypes.set(2, '2D Fix')
gpsStatusTypes.set(3, '3D Fix')
gpsStatusTypes.set(4, 'DGPS Fix')

export const receiverStatusTypes = new Map()
receiverStatusTypes.set(0, 'Normal')
receiverStatusTypes.set(1, 'Communication Errors')
receiverStatusTypes.set(2, 'Not Transmitting')
