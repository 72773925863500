import { Button, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
        padding: window.innerWidth < 600 ? 0: theme.spacing(2)
    },
    section: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
}));


const convertTo2Digit = (value) => {
    return value < 10 ? `0${value}` : value;
}

export default function ReportDistributionNew (props) {
    const classes = useStyles();
    const rightNow = new Date();
    const authid    = useSelector((state) => state.authorityId.value);
    const role    = useSelector((state) => state.role.value);
    const history   = useHistory()
    
    const [updating, setUpdating]   =   React.useState(false);
    const [editReportId, setEditReportId]   =   React.useState(null);
    const [editReportName, setEditReportName]   =   React.useState(null);
    const [editScheduleId, setEditScheduleId]   =   React.useState(null);

    const [reports, setReports] = React.useState([]);
    const [report, setReport] = React.useState(0)
    const [name, setName] = React.useState('')
    const [frequency, setFrequency] = React.useState(null);
    const [hour, setHour] = React.useState(convertTo2Digit(rightNow.getHours()));
    const [minute, setMinute] = React.useState(convertTo2Digit(rightNow.getMinutes()));
    const [dayOfWeek, setDayOfWeek] = React.useState(rightNow.getDay());
    const [dayOfMonth, setDayOfMonth] = React.useState(rightNow.getDate());
    const [month, setMonth] = React.useState(rightNow.getMonth());
    const [recipients, setRecipients] = React.useState([]);


    useEffect(() => {
        if ( 'updating' in props ) {
            if ( props.updating === true ) {
                setUpdating(true);

                const schedule = props.report[0].schedules[0];
                const settings  =   JSON.parse( schedule.cron_settings );

                setName(schedule.name)
                setFrequency(settings.frequency)
                setHour(settings.hour)
                setMinute(settings.minute)
                setDayOfWeek(settings.dayOfWeek)
                setDayOfMonth(settings.dayOfMonth)
                setMonth(settings.month)
                setRecipients(schedule.recipients.split(","))
                setEditReportId(props.report[0].id)
                setEditReportName(props.report[0].name)
                setEditScheduleId(schedule.id)
            }
        } else {
            const user = jwtDecode(localStorage.getItem('token'));
            setRecipients([user.email]);

            const params = {
                user: user.userid,
                auth: authid
            }

            axios.get(`${WEBAPI}/report?user=${params.user}&auth=${params.auth}`)
            .then((res) => {
                console.log(res)
                setReports(res.data);
                if ( res.data.length > 0 ) {
                    setReport(res.data[0].id)
                }
            }).catch((err) => {
                console.log(err);
            })
        }


        
    }, []);


    const handleSubmit = () => {
        const params= {
            report,
            name,
            frequency,
            hour,
            minute,
            dayOfWeek,
            dayOfMonth,
            month,
            recipients: recipients.join(','),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        if ( recipients !== '' ) {
            axios.post(`${WEBAPI}/report/schedule`, params)
            .then((res) => {
                if ( res.data.success === true ) {
                    history.push('/reports')
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleUpdate = () => {
        const params= {
            schedule_id: editScheduleId,
            name,
            frequency,
            hour,
            minute,
            dayOfWeek,
            dayOfMonth,
            month,
            recipients: recipients.join(','),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        console.log(params)

        if ( recipients !== '' ) {
            axios.post(`${WEBAPI}/report/edit/${editReportId}`, params)
            .then((res) => {
                if ( res.data.success === true ) {
                    history.push('/reports')
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    return <div className={classes.root}>
        <div className={classes.paper}>
            <Typography variant="h5">
                {updating ? "Edit Scheduled Report" : "Add New Scheduled Report"}
            </Typography>

            {/**
             * Implement Stepper from MUI v4
             * 
             * Step 1: Fequency
             *  - Daily
             *  - Weekly
             *  - Monthly
             *  - Yearly
             *  - Time of Day
             *  - Day of Week
             *  - Day of Month
             *  - Day of Year
             * 
             * Step 2: Recipients
             * - Email
             * 
             * Step 3: Report
             * - Select Report
             */}

            <Typography variant="h6" className={classes.section}>Schedule Name</Typography>

            <FormControl>
                <TextField 
                    id="schedule_name"
                    label="Name"
                    type="text"
                    value={name}
                    style={{minWidth: '300px'}}
                    size={"medium"}
                    onChange={(e) => {setName(e.target.value)}}
                />
            </FormControl>

            {(!updating) ? <>
                <Typography variant="h6" className={classes.section}>Select Report</Typography>

                <FormControl>
                    <Select value={report} id="select_report" name="select_report" onChange={(e) => {setReport(e.target.value)}}>
                        {reports ? reports.map((report, index) => (
                            <MenuItem key={index} value={report.id}>{report.name}</MenuItem>
                        )): <MenuItem value={0}>No Reports Found</MenuItem>}
                    </Select>
                </FormControl>
            </> : <>
            <Typography variant="h6" className={classes.section}>Report</Typography>
                <FormControl>
                    <Select value={editReportName} id="select_report_disabled" name="select_report_disabled" disabled>
                        <MenuItem value={editReportName}>{editReportName}</MenuItem>
                    </Select>
                </FormControl>
            </>}

            <Typography variant="h6"  className={classes.section}>Select the Schedule</Typography>
                
            <FormControl>
                <FormLabel>Frequency</FormLabel>
                <RadioGroup name="frequency" value={frequency} onChange={(e) => {setFrequency(e.target.value)}}>
                    <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                    <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                </RadioGroup>

                {
                    /* Yearly Options */
                    frequency === 'yearly' &&
                        <FormControl className={classes.formControl}>
                            <FormLabel>Month of Year</FormLabel>
                            <Select value={month} id="month_of_year" name="month_of_year" onChange={(e) => setMonth(e.target.value)}>
                                <MenuItem value="1">January</MenuItem>
                                <MenuItem value="2">February</MenuItem>
                                <MenuItem value="3">March</MenuItem>
                                <MenuItem value="4">April</MenuItem>
                                <MenuItem value="5">May</MenuItem>
                                <MenuItem value="6">June</MenuItem>
                                <MenuItem value="7">July</MenuItem>
                                <MenuItem value="8">August</MenuItem>
                                <MenuItem value="9">September</MenuItem>
                                <MenuItem value="10">October</MenuItem>
                                <MenuItem value="11">November</MenuItem>
                                <MenuItem value="12">December</MenuItem>
                            </Select>
                        </FormControl>
                }

                {
                    /* Monthly Options */
                    (frequency === 'monthly' || frequency === 'yearly') &&
                        <FormControl className={classes.formControl}>
                            <FormLabel>Day of Month</FormLabel>
                            <Select value={dayOfMonth} id="day_of_month" onChange={(e) => setDayOfMonth(e.target.value)}>
                                {
                                    [...Array(31).keys()].map((day, index) => (
                                        <MenuItem key={index} value={day+1}>{day+1}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                }


                {
                    /* Weekly Options */
                    frequency === 'weekly' &&
                        <FormControl className={classes.formControl}>
                            <FormLabel>Day of Week</FormLabel>
                            <RadioGroup name="day_of_week" value={dayOfWeek} onChange={(e) => {setDayOfWeek(e.target.value)}}>
                                <FormControlLabel value="0" control={<Radio />} label="Sunday" />
                                <FormControlLabel value="1" control={<Radio />} label="Monday" />
                                <FormControlLabel value="2" control={<Radio />} label="Tuesday" />
                                <FormControlLabel value="3" control={<Radio />} label="Wednesday" />
                                <FormControlLabel value="4" control={<Radio />} label="Thursday" />
                                <FormControlLabel value="5" control={<Radio />} label="Friday" />
                                <FormControlLabel value="6" control={<Radio />} label="Saturday" />
                            </RadioGroup>
                        </FormControl>
                }


                {
                    /* Daily Options */
                    (frequency !== null ) && 
                        <FormControl className={classes.formControl}>
                            <FormLabel>Time of Day</FormLabel>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <TextField 
                                        id="time_hour"
                                        label="Hour"
                                        type="number"
                                        size="small"
                                        style={{width: '50px'}}
                                        value={hour}
                                        onChange={(e) => {setHour(e.target.value)}}
                                        InputProps={{ inputProps: { min: 0, max: 23 } }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        id="time_minute"
                                        label="Minute"
                                        type="number"
                                        size="small"
                                        style={{width: '50px'}}
                                        value={minute}
                                        onChange={(e) => {setMinute(e.target.value)}}
                                        InputProps={{ inputProps: { min: 0, max: 59 } }}
                                    />
                                </Grid>
                            </Grid>                                
                        </FormControl>
                }

                
                {
                    /* Recipients */
                    frequency !== null &&
                        <FormControl className={classes.formControl}>
                            <FormLabel>Recipients</FormLabel>
                            <TextField
                                id="recipients"
                                label="Email"
                                type="email"
                                size="small"
                                multiline={true}
                                style={{width: '300px'}}
                                value={recipients.join(', ')}
                                onChange={(e) => {setRecipients(e.target.value.replaceAll(" ", "").split(","))}}
                            />
                            <Typography variant="caption" display="block" gutterBottom>
                                Separate multiple emails with a comma
                            </Typography>
                        </FormControl>
                }

                {
                    /* Submit */
                    frequency !== null && recipients.length > 0 &&
                        <Button variant="contained" color="primary" onClick={updating ? handleUpdate : handleSubmit} className={classes.formControl} disabled={role === "sales"}>
                            {updating ? "Update" : "Submit"}
                        </Button>
                }
                
            </FormControl>
        </div>
    </div>;
}