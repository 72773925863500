import { useEffect } from "react"
import Testd3 from "./Testd3"
import { useTheme } from "@material-ui/core"

export default function MapPlot(props) {

    const { airportCode, satimgTopLeft, landing, alerts, polygon, runwayName, midpoint1, midpoint2, parentRef } = props

    useEffect(() => {
        console.log("MapPlot: useEffect")
    }, [props])


    if ( !airportCode || !satimgTopLeft || !landing || !polygon ) {
        return "No Data"
    }


    return (
        <div>
            <div>
                <div>
                    {/* <AirportImage code={airportCode} utm={satimgTopLeft} landing={landing} alerts={alerts} height="200" />  */}
                    <Testd3 parentRef={parentRef} airportcode={airportCode} AirportUTM={satimgTopLeft} coordinatesArray={landing} runwayNumber={runwayName} RunwayCorners={polygon} midpoint1={midpoint1} midpoint2={midpoint2} />
                </div>
            </div>
        </div>
    )
}