import { useSelector } from 'react-redux';
import packageJson from '../../package.json';
import { makeStyles } from '@material-ui/core';

const WEBAPI = process.env.REACT_APP_WEB_API;

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));

export default function Version() {
    const classes = useStyles();
    const root = useSelector((state) => state.root.value)

    if ( root !== undefined ) {
        if ( root !== true ) {
            return '';
        }
    }
    
    return (
        <div className={classes.root}>
            <h4>ADEW</h4>
            <p>ADEW Version {packageJson.version}</p>
        </div>
    )
}
